

import * as apiSvc from "api/components/cmmn/CmmnApiCallService"
import { useEffect, useState } from 'react';
import styled from "styled-components";

const RadioBtnWrap = styled.ul`
    display: flex;
    align-items: center;
    li {
        display: flex;
        align-items: center;
        &:first-child {
            margin-right: 20px;
        }
        label {
            font-size: 16px;
            color: #3b3b3b;
            font-weight: 400;
            margin-left: 10px;
        }
        input[type="radio"] {
            accent-color: red;
        }
    }
`;
const ErrorMsg = styled.p`
    margin-left: 1rem;
    font-size: 0.9rem;
    color: #ef2929;
`;

export default function CmmnCdRadio({
    cmmnCdId,
    defaultVal,
    tagName,
    onSelectChange,
    className,
    errorTxt
}) {

    const [radioValue, setRadioValue] = useState(defaultVal);
    const [cmmnCdList, setCmmnCdList] = useState([]);

    useEffect(() => {
        setRadioValue(defaultVal);
    }, [defaultVal]);

    useEffect(() => {
        apiSvc.GetCmmnCdList({ cmmnCdId })
            .then(res => {
                setCmmnCdList(res);
            })
            .catch(error => {
                console.log(error);
            });
    }, [defaultVal, cmmnCdId]);

    const handleRadioChange = (e) => {
        const value = e.target.value;
        setRadioValue(value);
        onSelectChange({ target: { name: tagName, value } });
    };

    return (
        <>
            <RadioBtnWrap className={className}>
                {cmmnCdList.map((cmmn) => (
                    <li key={cmmn.cmmnCd}>
                        <input
                            type="radio"
                            name={tagName}
                            value={cmmn.cmmnCd}
                            id={cmmn.cmmnCd}
                            checked={radioValue === cmmn.cmmnCd}
                            onChange={handleRadioChange}
                        />
                        <label htmlFor={cmmn.cmmnCd}>{cmmn.cmmnCdNm}</label>
                    </li>
                ))}
            </RadioBtnWrap>
            {errorTxt && <ErrorMsg>{errorTxt}</ErrorMsg>}
        </>
    );

}