import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import TitleLogo from "asset/photomag_web/img/icon/filled/insert-emoticon.svg";
import TextFields from "components/web/UI/TextFields";
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import { useTranslation } from "react-i18next";
import CmmnCheckBox from "components/web/cmmn/CmmnCheckBox";
import { callApi } from "util/HttpService";
import { alert, confirm } from "components/web/cmmn/CmmnConfirm";

const DashBoardWrap = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 0 0;
    .dashboard-box {
        background-color: #fff;
        border-radius: 16px;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
    }
    .round-filled-small-btn {
        background-color: ${({ theme }) => theme.colors.red} !important;
        &:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
    input[type="checkbox"] {
        accent-color: #EF2929;
    }
    @media (max-width: 1600px) {
        .th, .tb {
            font-size: 14px !important;
        }
    }
    @media (min-width: 1601px) {
        .th, .tb {
            font-size: 16px !important;
        }
    }
`;
const InputOptionWrap = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .wrap {
        display: flex;
        align-items: center;
        &:first-child {
            margin-bottom: 20px;
        }
        li {
            display: flex;
            align-items: center;
            h3 {
                font-size: 16px;
                color: #3b3b3b;
                font-weight: 500;
                min-width: 100px;
            }
            label {
                font-size: 16px;
                color: #3b3b3b;
                font-weight: 400;
                margin-left: 10px;
            }
            select {
                min-width: 250px;
            }
        }
        .tel {
            h3 {
                min-width: 146px;
            }
        }
    }
    & > ul {
        width: fit-content;
    }
    .btn {
        max-width: 150px;
        &:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
`;
const TableTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    h2 {
        font-size: 18px;
        color: #3b3b3b;
        font-weight: 500;
    }
    .table-title-btn-wrap {
        display: flex;
        align-items: center;
        .btn {
            width: 60px !important;
            &:first-child {
                margin-right: 10px;
            }
        }
    }
`;
const TableWrap = styled.div`
    h2 {
        font-size: 18px;
        color: #3b3b3b;
        font-weight: 500;
        margin-bottom: 15px;
    }
    .round-filled-gray-btn:hover {
        color: var(--bs-btn-hover-color) !important;
    }
    .round-filled-gray-btn {
        font-size: 14px !important;
    }
    .thead {
        position: sticky;
        top: 0;
        z-index: 10;
    }
    .tbody:hover .tb {
        background-color: #fafafa !important;
    }
    .tbody:active .tb, .tbody:focus .tb {
        background-color: #f5f5f5 !important;
    }
    .tb {
        padding: 15px 0px !important;         
    }
    .jp {
        font-size: 12px !important;
    }
`;

export default function PstsMgt() {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const defaultSearchData = {
        blbdCd: [],
        pstsTitl: "",
    };
    const [searchData, setSearchData] = useState(defaultSearchData);
    const [pstsList, setPstsList] = useState([]);
    const [selectedPstsSqno, setSelectedPstsSqno] = useState([]);

    const getPstsList = () => {
        const params = {...searchData, blbdCd: searchData.blbdCd.join(",")};
        callApi("GET", params, "/web/api/svc-mgt/psts/list")
        .then(res => {
            // console.log(res);
            setPstsList(res)
        })
        .catch(err => {
            console.log(err);
        })
    };

    useEffect(() => {
        getPstsList();
    }, []);

    const handleFormDataChange = (event) => {
        const { name, value } = event.target;
        setSearchData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleCheckBoxChange = (event) => {
        const { value } = event.target;
        setSearchData((prevData) => ({
            ...prevData,
            blbdCd: value
        }));
    };

    const handleCmmnBtnClick = (btnNm) => {
        switch (btnNm) {
            case "cmmnSearchBtn":
                    getPstsList();
                    setSelectedPstsSqno([]);
                break;
            case "cmmnInsertBtn":
                navigate(`/svc-mgt/psts-mgt/0`);
                break;
            case "cmmnDeleteBtn":
                const pstsSqnoList = selectedPstsSqno.join(",");
                console.log(pstsSqnoList);
                if (pstsSqnoList.length > 0) {
                    confirm("", t("alert.deleteConfirm"), () => {
                        callApi("GET", { pstsSqnoList: pstsSqnoList }, "/web/api/svc-mgt/psts/delete")
                        .then(res => {
                            alert("", t("alert.delete"), () => { getPstsList() });
                        })
                        .catch(err => {
                            alert("", t("alert.deleteError"), () => { });
                            console.log(err);
                        });
                    })
                } else {
                    alert("", t("alert.deleteNumCheck"), () => { return });
                    console.log("No branches selected for deletion.");
                }
                break;
            default: break;
        }
    };

    const onCheckedElement = useCallback((checked, pstsSqno) => {
        if (checked) {
            setSelectedPstsSqno(prevData => [...prevData, pstsSqno]);
        } else {
            setSelectedPstsSqno(prevData => prevData.filter((id) => id !== pstsSqno));
        }
    }, []);

    const onRowDoubleClicked = (pstsSqno) => {
        navigate(`/svc-mgt/psts-mgt/${pstsSqno}`)
    }

    return (
        <div className="padding-box">
            <div className="title-wrap">
                <div className="title space-between">
                    <div className="dp-flex align-cen">
                        <img src={TitleLogo} alt="공지사항관리" />
                        <h2>공지사항 관리</h2>
                    </div>
                    <p>Home {">"} {t("leftMenu.svcMng")} {">"} 공지사항 관리</p>
                </div>
            </div>
            <DashBoardWrap>
                <div className="dashboard-box p-30">
                    <InputOptionWrap>
                        <ul>
                            <ul className="wrap">
                                <li className="mr-50">
                                    <h3>{t("pstsMgtPage.division")}</h3>
                                    <CmmnCheckBox
                                        cmmnCdId={16}
                                        defaultOptTxt={t("brchMgtPage.entire")}
                                        defaultVal={searchData.blbdCd}
                                        tagName="blbdCd"
                                        className="wrap"
                                        onSelectChange={handleCheckBoxChange}
                                        filterCmmnCd={["EVNT", "STGD"]}
                                    />
                                </li>
                            </ul>
                            <ul className="wrap">
                                <li>
                                    <h3>공지제목</h3>
                                    <TextFields
                                        type="text"
                                        value={searchData.pstsTitl}
                                        name="pstsTitl"
                                        onChangeValue={handleFormDataChange}
                                    />
                                </li>
                            </ul>
                        </ul>
                        <ButtonUtil className="btn btn-danger round-filled-small-btn small-btn"
                            value={t("common.searchLable")}
                            onClick={() => { handleCmmnBtnClick("cmmnSearchBtn") }}
                        />
                    </InputOptionWrap>
                </div>
                <div className="dashboard-box">
                    <TableTitle>
                        <h2>{t("brchMgtPage.total")} {pstsList.length}{t("dashboardPage.occur")}</h2>
                        <div className="table-title-btn-wrap">
                            <ButtonUtil className="btn btn-outline-danger round-solid-small-red-btn small-btn"
                                value={t("common.regLable")}
                                onClick={() => { handleCmmnBtnClick("cmmnInsertBtn") }}
                            />
                            <ButtonUtil className="btn btn-danger round-filled-small-btn small-btn mr-5"
                                value={t("common.delLable")}
                                onClick={() => { handleCmmnBtnClick("cmmnDeleteBtn") }}
                            />
                        </div>
                    </TableTitle>
                    <div className="p-30">
                        <TableWrap>
                            <div className="table scroll-wrap h-10">
                                <div className="thead">
                                    <div className="th w-05"></div>
                                    <div className="th w-05">{t("pstsMgtPage.index")}</div>
                                    <div className="th w-15">{t("pstsMgtPage.division")}</div>
                                    <div className="th w-3">공지제목</div>
                                    <div className="th w-1">{t("pstsMgtPage.regUserNm")}</div>
                                    <div className="th w-25">{t("pstsMgtPage.regDttm")}</div>
                                    <div className="th w-1">조회수</div>
                                </div>
                                <div className="tbody-wrap">
                                    {pstsList.map((data, idx) => (
                                        <div className="tbody"
                                            key={data.pstsSqno}
                                            onClick={() => onCheckedElement(!selectedPstsSqno.includes(data.pstsSqno), data.pstsSqno)}
                                            onDoubleClick={() => { onRowDoubleClicked(data.pstsSqno) }}
                                        >
                                            <div className="tb w-05">
                                                <input type="checkbox"
                                                    name="pstsSqno"
                                                    value={data.pstsSqno}
                                                    onChange={(e) => onCheckedElement(e.target.checked, data.pstsSqno)}
                                                    checked={selectedPstsSqno.includes(data.pstsSqno) ? true : false}
                                                />
                                            </div>
                                            <div className="tb w-05">{idx + 1}</div>
                                            <div className="tb w-15">{data.blbdCdNm}</div>
                                            <div className="tb w-3">{data.pstsTitl}</div>
                                            <div className="tb w-1">{data.regUserNm}</div>
                                            <div className="tb w-25">{data.convRegDttm}</div>
                                            <div className="tb w-1">{data.iqryCnt}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </TableWrap>
                    </div>
                </div>
            </DashBoardWrap>
        </div>
    )

}