import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const TimeBox = styled.div`
  .data-text {
    text-align: center;
    font-size: 16px;
    color: #3b3b3b;
    font-weight: bold;
    margin: 15px 0;
  }
`;

export default function MainTimer() {
  const { t } = useTranslation();
  const [timer, setTimer] = useState("");

  useEffect(() => {
    const updateTimer = () => {
      const now = new Date();
      const formattedTimer = formatDateString(now);
      setTimer((prevTimer) => (prevTimer !== formattedTimer ? formattedTimer : prevTimer));
    };

    updateTimer(); // 초기화
    const intervalId = setInterval(updateTimer, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [t]);

  const formatDateString = (date) => {
    const week = ["SUN", "MON", "TUE", "WED", "TUR", "FRI", "SAT"];
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const dayOfWeek = week[date.getDay()];
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${t("dashboardPage.nowTime")} : ${year}-${month}-${day}(${dayOfWeek}) ${hours}:${minutes}:${seconds}`;
  };

  return (
    <TimeBox>
      <h3 className="data-text">{timer}</h3>
    </TimeBox>
  );
}
