'use client'

import styled from "styled-components"
import titleLogo from "asset/mobile_web/img/icon/filled/monetization-on_b.svg";
import DynamicFeed from "asset/mobile_web/img/icon/filled/dynamic-feed_b.svg";
import PhotoLibrary from "asset/mobile_web/img/icon/filled/photo-library_b.svg";
import excelLogo from "asset/photomag_web/img/icon/insert-chart-outlined.svg"
import { useEffect, useRef } from "react";
import { useSpring, animated } from 'react-spring';
import ButtonUtil from "../UI/ButtonUtil";
import { useTranslation } from "react-i18next";

const MenuTitleWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.white1};
    width: 100%;
    height: ${props => props.$isMobile ? "56.8px" : "72px"};
    h3 {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.dark2};
        font-weight: 500;
        margin-right: ${props => props.$isMobile ? "0px" : "10px"};
    }
    h5 {
        font-size: 14px;
        color: ${({ theme }) => theme.colors.gray6};
        font-weight: 400;
    }
    .round-filled-small-green-btn:hover {
        color: var(--bs-btn-hover-color) !important;
        background-color: var(--bs-btn-hover-bg) !important;
        border-color: var(--bs-btn-hover-border-color) !important;
    }
`;
const AlignSet = styled.div`
    display: flex;
    align-items: center;
`
const ContentWrap = styled.div`
    padding: 20px;
`;
const WebContentWrap = styled.div`
    padding: 20px;
	.sub-title-wrap {
		h3 {
			font-size: 16px;
			color: #3b3b3b;
			font-weight: 500;
			margin-left: 5px;
		}
		h4 {
			font-size: 14px;
			color: #3b3b3b;
			font-weight: 500;
			margin-left: 5px;
		}
	}
	.box-wrap {
		li {
			background-color: #fff;
			box-shadow: 0 0 5px rgba(0,0,0,.1);
			padding: 30px;
			border-radius: 16px;
			width: 24%;
			h2 {
				font-size: 36px;
				color: #EF2929;
				font-weight: bold;
				margin-top: 15px;
			}
		}
	}
	.box-wrap2 {
		display: flex;
		align-items: center;
		justify-content: space-between;
		li {
			width: 32.5%;
		}
	}
`;
const SubBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
        font-size: 28px;
        color: ${({ theme }) => theme.colors.red};
        font-weight: bold;
    }
`;
const SubBoxWrap = styled.ul`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    li {
        background-color: #fff;
        box-shadow: 0 0 5px rgba(0,0,0,.1);
        padding: 20px;
        border-radius: 16px;
        width: 48%;
        h2 {
            font-size: 20px;
            color: ${({ theme }) => theme.colors.dark2};
            font-weight: bold;
            margin-top: 15px;
        }
    }
`;
const SubTitleWrap = styled.div`
    display: flex;
    align-items: center;
    h3 {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.dark2};
        font-weight: 500;
        margin-left: 5px;
    }
    h4 {
        font-size: 14px;
        color: ${({ theme }) => theme.colors.dark2};
        font-weight: 500;
        margin-left: 5px;
    }
`;

const Animated = ({ value }) => {
    const prevValue = useRef(undefined); // 초기값을 undefined로 설정

    useEffect(() => {
        if (value !== undefined) {
            prevValue.current = value;
        }
    }, [value]);

    const props = useSpring({
        from: { number: prevValue.current || 0 },
        to: { number: value || 0 },
        config: { duration: 1000 }
    });

    const formatNumber = (n) => {
        return new Intl.NumberFormat("ko-KR", {}).format(n);
    };

    return (
        <animated.span>{props.number.to ? props.number.to(n => formatNumber(n.toFixed(0))) : formatNumber(value)}</animated.span>
    );
};

export default function CmmnMenuTitleWrap(
    {
        title,
        searchData,
        dataInfo,
        className,
        isMobile,
        onCmmnBtnClick,
        crdInfo,
    }
) {
    const { t } = useTranslation();
    return (
        <div className={`dashboard-box ${className}`}>
            <MenuTitleWrap $isMobile={isMobile}>
                {isMobile ?
                    <>
                        <h3>{title}</h3>
                        {(searchData.startDate !== "" && searchData.endDate !== "") ? (
                            <h5>{t("saleHistPage.searchPeriod")} : {searchData.startDate} ~ {searchData.endDate}</h5>
                        ) : (
                            <h5>{t("saleHistPage.searchPeriod")} : {t("saleHistPage.entire")}</h5>
                        )}
                    </>
                    :
                    <>
                        <AlignSet>
                            <h3>{title}</h3>
                            {(searchData.startDate !== "" && searchData.endDate !== "") ? (
                                <h5>{t("saleHistPage.searchPeriod")} : {searchData.startDate} ~ {searchData.endDate}</h5>
                            ) : (
                                <h5>{t("saleHistPage.searchPeriod")} : {t("saleHistPage.entire")}</h5>
                            )}
                        </AlignSet>
                        <ButtonUtil className="btn btn-success round-filled-small-green-btn excel-btn small-btn"
                            onClick={() => onCmmnBtnClick("cmmnExcelDownloadBtn")}>
                            <img src={excelLogo} alt="excel_logo" />
                            {t("saleHistPage.excelDownload")}
                        </ButtonUtil>
                    </>
                }
            </MenuTitleWrap>
            {isMobile ?
                <ContentWrap>
                    <SubBox>
                        <SubTitleWrap>
                            <img src={titleLogo} alt={t("saleHistPage.sale")} />
                            <h3>{t("saleHistPage.sale")}</h3>
                        </SubTitleWrap>
                        <h2>{crdInfo && crdInfo.natnCd === "KR" ? "₩" : "¥"} <Animated value={dataInfo.totStlmAmt} /></h2>
                    </SubBox>
                    <SubBoxWrap>
                        <li>
                            <SubTitleWrap>
                                <img src={DynamicFeed} alt={t("saleHistPage.numberOfPay")} />
                                <h4>{t("saleHistPage.numberOfPay")}</h4>
                            </SubTitleWrap>
                            <h2><Animated value={dataInfo.totStlmCnt} />{t("dashboardPage.occur")}</h2>
                        </li>
                        <li>
                            <SubTitleWrap>
                                <img src={PhotoLibrary} alt={t("saleHistPage.numberOfPrint")} />
                                <h4>{t("saleHistPage.numberOfPrint")}</h4>
                            </SubTitleWrap>
                            <h2><Animated value={dataInfo.totPhtQty} />{t("dashboardPage.page")}</h2>
                        </li>
                    </SubBoxWrap>
                </ContentWrap>
                :
                <WebContentWrap>
                    <ul className="betweenSet box-wrap box-wrap2">
                        <li>
                            <div className="alignset sub-title-wrap">
                                <img src={titleLogo} alt={t("saleHistPage.sale")} />
                                <h4>{t("saleHistPage.sale")}</h4>
                            </div>
                            <h2>{crdInfo && crdInfo.natnCd === "KR" ? "₩" : "¥"} <Animated value={dataInfo.totStlmAmt} /></h2>
                        </li>
                        <li>
                            <div className="alignset sub-title-wrap">
                                <img src={DynamicFeed} alt={t("saleHistPage.numberOfPay")} />
                                <h4>{t("saleHistPage.numberOfPay")}</h4>
                            </div>
                            <h2><Animated value={dataInfo.totStlmCnt} />{t("dashboardPage.occur")}</h2>
                        </li>
                        <li>
                            <div className="alignset sub-title-wrap">
                                <img src={PhotoLibrary} alt={t("saleHistPage.numberOfPrint")} />
                                <h4>{t("saleHistPage.numberOfPrint")}</h4>
                            </div>
                            <h2><Animated value={dataInfo.totPhtQty} />{t("dashboardPage.page")}</h2>
                        </li>
                    </ul>
                </WebContentWrap>
            }
        </div>
    );
}