import { BarController, CategoryScale, Chart as ChartJS, Legend, LineController, LineElement, LinearScale, PointElement, Title, Tooltip } from "chart.js";
import React, { useMemo } from "react";
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import moment from "moment";

ChartJS.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, BarController, Title, Tooltip, Legend);

const ChartBox = styled.div`
    width: 100%;
    height: 250px;
    // padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

function EqmtAccuHourlyChart({ salesData, dateArray, searchData, crdInfo }) {
    const {t} = useTranslation();
    const now = moment();
    const today = now.clone().startOf('day').format("YYYY-MM-DD");
    const isToday = today === searchData.startDate;

    const currentHour = new Date().getHours();
    const filteredSalesData = salesData.map(data => data.slice(0, currentHour + 1));

    const chartData = useMemo(() => {
            return {
                labels: dateArray.map(data => `${data}${t("dashboardPage.hour")}`),
                datasets: [
                    {
                        type: "bar",
                        label: t("saleHistPage.todayAccuSales"),
                        data: isToday ? filteredSalesData[2] : salesData[2],
                        backgroundColor: "#FF7F00",
                    },
                    {
                        type: "bar",
                        label: `${searchData.division === "HST" ? t("saleHistPage.yesterdayAccuSales") : t("saleHistPage.lastWeekAccuSales")}`,
                        data: salesData[3],
                        backgroundColor: "#006DB2",
                    }
                ],
            };
    }, [filteredSalesData, salesData, dateArray, t, isToday, searchData]);

    const options = {
        responsive: true,
        maintainAspectRatio: false, //부모의 크기에 맞춤
        scales: {
            x: {
                ticks: {
                    font: {
                        size: 10
                    },
                    minRotation: 90,
                    padding: 5
                }
            },
        },
        plugins: {
            legend: {
                display: true, // 범례
                position: 'bottom',
                labels: {
                    font: {
                        size: 11,
                    },
                    boxWidth: 6,
                    boxHeight: 6
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        label += `${crdInfo && crdInfo.natnCd === "KR" ? "₩" : "¥"} ${context.raw.toLocaleString()}`;
                        return label;
                    }
                }
            }
        },
    };

    return (
        <ChartBox>
            <Bar options={options} data={chartData} />
        </ChartBox>
    )

}
export default EqmtAccuHourlyChart;
