import React, { createContext, useContext, useEffect, useState } from 'react';
import { callApi } from 'util/HttpService';

const BrchContext = createContext();

export const useBrch = () => {
    return useContext(BrchContext);
}

export const BrchProvider = ({ children }) => {
    const defaultSearchData = {
        brchId: '',
        brchClsfCd: [],
        brchCrgrTelNo: '',
        brchCrgrNm: '',
        frncId: '',
    };
    const POLLING_INTERVAL = 10000;
    const [brchId, setBrchId] = useState(localStorage.getItem("changeBrchId"));
    const [crdInfo, setCrdInfo] = useState(null);
    const [crdStat, setCrdStat] = useState([]);
    const [brchList, setBrchList] = useState([]);
    const [searchData, setSearchData] = useState(defaultSearchData);
    const [stat, setStat] = useState(false);

    // iqryYn 변경용
    const [iqryYnStat, setIqryYnStat] = useState(false);

    const changeBrchId = (newBrchId, reload = true) => {
        localStorage.setItem('changeBrchId', newBrchId);
        setBrchId(newBrchId);
        if (reload) {
            window.location.reload();
        }
    };
    /* 보유크레딧 조회 통합적으로 관리 */
    /* 크레딧 정보 조회 */
    const getBrchCrdInfo = () => {
        callApi("GET", { brchId: brchId }, "/web/api/svc-mgt/brch/crd-info")
            .then(res => {
                setCrdInfo(res);
            })
            .catch(error => {
                console.log(error);
            })
    }
    /* 크레딧 현황 조회 */
    const getCrdStat = () => {
        callApi("GET", { brchId: brchId }, "/web/api/dsh-brd/get-crd-stat")
            .then(res => {
                const crdStatValueArr = [res.possCrdCnt, res.tmnUseCrdCnt];
                setCrdStat(crdStatValueArr);
            })
            .catch(error => {
                console.error(error);
            });
    };
    /* 가맹점 관리 조회 */
    const getBrchAllList = () => {
        const brchClsfCd = localStorage.getItem("brchClsfCd");
        const locBrchId = localStorage.getItem("brchId");
        let params;
        switch (brchClsfCd) {
            case "KSC":
                params = { ...searchData, brchClsfCd: searchData.brchClsfCd.join(",") };
                break;
            case "FCC":
                params = { ...searchData, frncId: locBrchId };
                break;
            case "JMC":
                params = { ...searchData, brchId: brchId };
                break;
            default: break;
        }
        callApi("GET", params, "/web/api/svc-mgt/brch/list")
            .then(res => {
                setBrchList(res);
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        if (brchId) {
            getBrchCrdInfo();
            getBrchAllList();
        }
    }, []);

    useEffect(() => {
        if (brchId) {
            getCrdStat();
        }
    }, [stat]);

    // useEffect(() => {
    //     const interval = setInterval(getCrdStat, POLLING_INTERVAL);
    //     return () => clearInterval(interval);
    // }, []);

    return (
        <BrchContext.Provider value=
            {{
                brchId,
                changeBrchId,
                crdInfo,
                getBrchCrdInfo,
                brchList,
                getBrchAllList,
                searchData,
                setSearchData,
                crdStat,
                getCrdStat,
                stat,
                setStat,
                POLLING_INTERVAL,
                iqryYnStat,
                setIqryYnStat,
            }}>
            {children}
        </BrchContext.Provider>
    );
}