import styled from "styled-components";
import TitleLogo from "asset/photomag_web/img/icon/filled/insert-emoticon.svg"
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { callApi } from "util/HttpService";
import TextFields from "components/web/UI/TextFields";
import ClassicEditor from "ckeditor5/src/App.jsx";
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import { getFormattedValue } from "util/CmmnUtils";

const DashBoardWrap = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 0 0;
    .dashboard-box {
        background-color: #fff;
        border-radius: 16px;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
        .p-30 {
            padding: 30px;
        }
        .table-wrap {
            h2 {
                font-size: 18px;
                color: #3b3b3b;
                font-weight: 500;
                margin-bottom: 15px;
            }
        }
    }
    @media (max-width: 1200px) {
        .table-wrap{
            overflow: scroll;
        }
    }
    .ck-sticky-panel__content {
        border: 1px solid #fff !important;
    }
    .ck-editor__editable_inline,
    .ck-editor__editable {
        border: none !important;
        box-shadow: none !important;
        pointer-events: none !important;
    }
`;
const InputTable = styled.div`
    margin-bottom: 20px;
    border-top: 2px solid #EF2929;
    .input-table-th-small{
        max-width: 138px;
        min-width: 138px;
    }
    .input-table-wrap{
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
    .h-2row {
        min-height: 145px !important;
    }
    .h-15row{
        min-height: 110px !important;
    }
`;
const InputTableHead = styled.div`
    background-color: #F3F3F3;
    padding: 20px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 78px;
    max-width: 208px;
    min-width: 208px;
    h3 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
    }
    &.editor {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 78px;
        flex: 0 1 auto;
    }
`;
const InputTableBody = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;
    h4 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 400;
    }
    .form-select {
        max-width: 250px;
    }
    input[type="text"] {
        max-width: 250px;
        width: 250px;
    }
    &:nth-child(2) .btn {
        margin-left: 10px;
    }
    @media (max-width: 1600px) {
        &.editorBody {
            width: 879px !important;
        }
    }
    @media (min-width: 1601px) {
        &.editorBody {
            width: 1193px !important;
        }
    }
    .round-filled-small-btn {
        width: 0%;
        height: 35px;
        margin: 0 !important;
        &:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
`;
const BtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
        width: 150px;
        max-width: 150px;
        &:hover {
            color: var(--bs-btn-hover-color);
            background-color: var(--bs-btn-hover-bg);
            border-color: var(--bs-btn-hover-border-color);
        }
    }
    .round-filled-small-btn {
        background-color: ${({ theme }) => theme.colors.red} !important;
    }
`;

export default function IqryMgtDtl() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { iqrySqno } = useParams();
    const [iqryInfo, setIqryInfo] = useState({});
    const [isDownload, setIsDownload] = useState(false);

    useEffect(() => {
        callApi("GET", null, `/web/api/svc-mgt/iqry/detail?iqrySqno=${iqrySqno}`)
            .then(res => {
                // console.log(res);
                setIqryInfo(res);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    const handleFormDataChange = (event) => {
        const { name, value } = event.target;
        setIqryInfo((prevData) => ({
            ...prevData,
            [name]: value,
        }))
    };

    const handleEditorDataChange = (data) => {
        setIqryInfo((prevData) => ({
            ...prevData,
            iqryCn: data,
        }));
    };

    const fileDownloadClick = async (fileName, dirName) => {
        const res = await callApi("GET", null, `/web/api/svc-mgt/iqry/download?fileName=${fileName}&dirName=${dirName}`);

        if (iqryInfo && res) {
            const response = await fetch(res);
            console.log("response :", response);

            if (!response.ok) {
                throw new Error("파일을 불러오는데 실패했습니다.");
            }
            const blob = await response.blob();
            const fileUrl = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            // 다운로드 후 링크 제거
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(fileUrl);
            setIsDownload(true);
        } else {
            console.log("다운로드 실패");
            setIsDownload(false);
        }
    };

    return (
        <div className="padding-box">
            <div className="title-wrap">
                <div className="title space-between">
                    <div className="dp-flex align-cen">
                        <img src={TitleLogo} alt="문의정보" />
                        <h2>문의정보</h2>
                    </div>
                    <p>Home {">"} {t("leftMenu.svcMng")} {">"} 문의정보</p>
                </div>
            </div>
            <DashBoardWrap>
                <div className="dashboard-box">
                    <div className="p-30">
                        <div className="table-wrap">
                            {iqryInfo.iqryTypeCd === "AFIQ" && <h2>제휴문의정보</h2>}
                            {iqryInfo.iqryTypeCd === "FUIQ" && <h2>창업문의정보</h2>}
                            {iqryInfo.iqryTypeCd === "GFIQ" && <h2>해외창업문의정보</h2>}
                            <InputTable>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>성명</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <TextFields
                                            type="text"
                                            value={iqryInfo?.iqrrNm || ""}
                                            name="iqrrNm"
                                            onChangeValue={handleFormDataChange}
                                            disabled={true}
                                            className="readonly"
                                        />
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>연락처</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <TextFields
                                            type="text"
                                            value={getFormattedValue(iqryInfo?.iqrrTelNo) || ""}
                                            name="iqrrTelNo"
                                            onChangeValue={handleFormDataChange}
                                            disabled={true}
                                            className="readonly"
                                        />
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>이메일</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <TextFields
                                            type="text"
                                            value={iqryInfo?.iqrrEmalAddr || ""}
                                            name="iqrrEmalAddr"
                                            onChangeValue={handleFormDataChange}
                                            disabled={true}
                                            className="readonly"
                                        />
                                    </InputTableBody>
                                </div>
                                {iqryInfo && iqryInfo.coNm !== null && iqryInfo.coNm !== undefined &&
                                    <div className="input-table-wrap">
                                        <InputTableHead>
                                            <h3>기업명</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <TextFields
                                                type="text"
                                                value={iqryInfo?.coNm || ""}
                                                name="coNm"
                                                onChangeValue={handleFormDataChange}
                                                disabled={true}
                                                className="readonly"
                                            />
                                        </InputTableBody>
                                    </div>
                                }
                                {iqryInfo && iqryInfo.founZoneNm !== null && iqryInfo.founZoneNm !== undefined &&
                                    <div className="input-table-wrap">
                                        <InputTableHead>
                                            <h3>창업지역</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <TextFields
                                                type="text"
                                                value={iqryInfo?.founZoneNm || ""}
                                                name="founZoneNm"
                                                onChangeValue={handleFormDataChange}
                                                disabled={true}
                                                className="readonly"
                                            />
                                        </InputTableBody>
                                    </div>
                                }
                                <div className="input-table-wrap" style={{ background: "#f3f3f3" }}>
                                    <InputTableHead>
                                        <h3>문의내용</h3>
                                    </InputTableHead>
                                    <InputTableBody className="editorBody" style={{ background: "#fff" }}>
                                        <div style={{ width: "100%" }}>
                                            <ClassicEditor
                                                name="iqryCn"
                                                value={iqryInfo?.iqryCn || ""}
                                                onChangeValue={handleEditorDataChange}
                                                disabled={true}
                                            />
                                        </div>
                                    </InputTableBody>
                                </div>
                                {iqryInfo && iqryInfo.upldFileNm && iqryInfo.upldPathAddr && iqryInfo.upldPyscFileNm &&
                                    <div className="input-table-wrap">
                                        <InputTableHead>
                                            <h3>첨부파일</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <ButtonUtil className="btn btn-danger round-filled-small-btn"
                                                value={"다운로드"}
                                                onClick={() => { fileDownloadClick(iqryInfo.upldFileNm, iqryInfo.upldPathAddr) }}
                                            />
                                        </InputTableBody>
                                    </div>
                                }
                                <div className="col-2">
                                    <div className="input-table-wrap w-50">
                                        <InputTableHead>
                                            <h3>{t("userMgtPage.regDttm")}</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <h4>{iqryInfo?.convRegDttm || ""}</h4>
                                        </InputTableBody>
                                    </div>
                                    <div className="input-table-wrap w-50">
                                        <InputTableHead>
                                            <h3>{t("userMgtPage.chngDttm")}</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <h4>{iqryInfo?.convChngDttm || ""}</h4>
                                        </InputTableBody>
                                    </div>
                                </div>
                            </InputTable>
                        </div>
                        <BtnWrap>
                            <ButtonUtil className="btn btn-outline-light round-solid-medium-btn"
                                value={t("common.listLable")}
                                onClick={() => { navigate("/svc-mgt/iqry-mgt") }}
                            />
                        </BtnWrap>
                    </div>
                </div>
            </DashBoardWrap>
        </div>
    )
}