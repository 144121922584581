'use client'

import { alert, confirm } from "components/web/cmmn/CmmnConfirm";
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import styled from "styled-components"
import { callApi } from "util/HttpService";
import TitleLogo from "asset/photomag_web/img/icon/filled/insert-emoticon.svg";
import { useTranslation } from "react-i18next";
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import CmmnCheckBox from "components/web/cmmn/CmmnCheckBox";
import TextFields from "components/web/UI/TextFields";
import BrchTelNoSelectBox from "components/web/cmmn/BrchTelNoSelectBox";
import CmmnCrdHistModal from "components/web/cmmn/CmmnCrdHistModal";
import CmmnCrdChargeModal from "components/web/cmmn/CmmnCrdChargeModal";
import { useBrch } from "components/web/auth/BrchProvider";
import { getFormattedValue } from "util/CmmnUtils";

const DashBoardWrap = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 0 0;
    .dashboard-box {
        background-color: #fff;
        border-radius: 16px;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
    }
    .round-filled-small-btn {
        background-color: ${({ theme }) => theme.colors.red} !important;
        &:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
    input[type="checkbox"] {
        accent-color: #EF2929;
    }
    @media (max-width: 1600px) {
        .th, .tb {
            font-size: 14px !important;
        }
    }
    @media (min-width: 1601px) {
        .th, .tb {
            font-size: 16px !important;
        }
    }
`;
const InputOptionWrap = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .wrap {
        display: flex;
        align-items: center;
        &:first-child {
            margin-bottom: 20px;
        }
        li {
            display: flex;
            align-items: center;
            h3 {
                font-size: 16px;
                color: #3b3b3b;
                font-weight: 500;
                min-width: 100px;
            }
            label {
                font-size: 16px;
                color: #3b3b3b;
                font-weight: 400;
                margin-left: 10px;
            }
            select {
                min-width: 250px;
            }
        }
        .tel {
            h3 {
                min-width: 146px;
            }
        }
    }
    & > ul {
        width: fit-content;
    }
    .btn {
        max-width: 150px;
        &:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
`;
const TableTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    h2 {
        font-size: 18px;
        color: #3b3b3b;
        font-weight: 500;
    }
    .table-title-btn-wrap {
        display: flex;
        align-items: center;
        .btn {
            width: 60px !important;
            &:first-child {
                margin-right: 10px;
            }
        }
    }
`;
const TableWrap = styled.div`
    h2 {
        font-size: 18px;
        color: #3b3b3b;
        font-weight: 500;
        margin-bottom: 15px;
    }
    .round-filled-gray-btn:hover {
        color: var(--bs-btn-hover-color) !important;
    }
    .round-filled-gray-btn {
        font-size: 14px !important;
    }
    .thead {
        position: sticky;
        top: 0;
        z-index: 10;
    }
    .tbody:hover .tb {
        background-color: #fafafa !important;
    }
    .tbody:active .tb, .tbody:focus .tb {
        background-color: #f5f5f5 !important;
    }
    .tb {
        padding: 15px 0px !important;         
    }
    .jp {
        font-size: 12px !important;
    }
`;
export default function BrchMgt() {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const isJapanese = i18n.language === 'jp';
    const { brchId, getBrchAllList, brchList, searchData, setSearchData, getBrchCrdInfo, stat, setStat } = useBrch();
    const locBrchId = localStorage.getItem("brchId");
    const brchClsfCd = localStorage.getItem("brchClsfCd");

    const [selectedBrchIds, setSelectedBrchIds] = useState([]);
    const [chargeModalOpen, setChargeModalOpen] = useState(false);
    const [chargeModalData, setChargeModalData] = useState(null);

    const [historyModalOpen, setHistoryModalOpen] = useState(false);
    const [historyModalData, setHistoryModalData] = useState(null);
    const [modalTitle, setModalTitle] = useState("");

    const openChargeModal = (data) => {
        setChargeModalOpen(true);
        setChargeModalData(data);
    };
    const openHistoryModal = (data) => {
        setHistoryModalOpen(true);
        setHistoryModalData(data);
    };

    useEffect(() => {
        getBrchAllList();
    }, [brchId]);

    const handleCheckBoxChange = (event) => {
        const { name, value } = event.target;
        setSearchData((prevData) => ({
            ...prevData,
            brchClsfCd: value
        }));
        // console.log(`${name}: `, value);
    }

    const handleFormDataChange = (event) => {
        const { name, value } = event.target;
        setSearchData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const onCheckedElement = useCallback((checked, brchId) => {
        if (checked) {
            setSelectedBrchIds(prevData => [...prevData, brchId]);
        } else {
            setSelectedBrchIds(prevData => prevData.filter((id) => id !== brchId));
        }
    }, []);

    const handleCmmnBtnClick = (btnNm) => {
        switch (btnNm) {
            case "cmmnSearchBtn":
                getBrchAllList();
                setSelectedBrchIds([]);
                break;
            case "cmmnInsertBtn":
                navigate(`/svc-mgt/brch-mgt/0`);
                break;
            case "cmmnDeleteBtn":
                const brchIdList = selectedBrchIds.join(',');
                if (selectedBrchIds.length > 0) {
                    confirm("", t("alert.deleteConfirm"), () => {
                        callApi("GET", { brchId: brchIdList }, "/web/api/svc-mgt/brch/delete")
                        .then(res => {
                            alert("", t("alert.delete"), () => { getBrchAllList() });
                        })
                        .catch(error => {
                            console.log(error);
                        });
                    })
                } else {
                    alert("", t("alert.deleteNumCheck"), () => { return });
                    console.log("No branches selected for deletion.");
                }
                break;
            default: break;
        }
    }

    const onRowDoubleClicked = (brchId) => {
        navigate(`/svc-mgt/brch-mgt/${brchId}`)
    };

    return (
        <div className="padding-box">
            <div className="title-wrap">
                <div className="title space-between">
                    <div className="dp-flex align-cen">
                        <img src={TitleLogo} alt={t("leftMenu.brchMgt")} />
                        <h2>{t("leftMenu.brchMgt")}</h2>
                    </div>
                    <p>Home {">"} {t("leftMenu.svcMng")} {">"} {t("leftMenu.brchMgt")}</p>
                </div>
            </div>
            <DashBoardWrap>
                <div className="dashboard-box p-30">
                    <InputOptionWrap>
                        <ul>
                            {brchClsfCd === "KSC" &&
                                <ul className="wrap">
                                    <li>
                                        <h3>{t("brchMgtPage.division")}</h3>
                                        <CmmnCheckBox
                                            cmmnCdId={1}
                                            defaultOptTxt={t("brchMgtPage.entire")}
                                            defaultVal={searchData.brchClsfCd}
                                            tagName="brchClsfCd"
                                            className="wrap"
                                            onSelectChange={handleCheckBoxChange}
                                        />
                                    </li>
                                </ul>
                            }
                            <ul className="wrap">
                                <li className="mr-50">
                                    <h3>{t("brchMgtPage.crgrNm")}</h3>
                                    <TextFields
                                        type="text"
                                        onChangeValue={handleFormDataChange}
                                        value={searchData.brchCrgrNm}
                                        name="brchCrgrNm"
                                    />
                                </li>
                                <li className="tel">
                                    <h3>{t("brchMgtPage.crgrTelNo")}</h3>
                                    <BrchTelNoSelectBox
                                        defaultVal={searchData.brchCrgrTelNo}
                                        defaultOptTxt={t("brchMgtPage.entire")}
                                        tagName="brchCrgrTelNo"
                                        onSelectChange={handleFormDataChange}
                                        brchId={brchClsfCd !== "KSC" ? locBrchId : ""}
                                    />
                                </li>
                            </ul>
                        </ul>
                        <ButtonUtil className="btn btn-danger round-filled-small-btn small-btn"
                            value={t("common.searchLable")}
                            onClick={() => { handleCmmnBtnClick("cmmnSearchBtn") }}
                        />
                    </InputOptionWrap>
                </div>
                <div className="dashboard-box">
                    <TableTitle>
                        <h2>{t("brchMgtPage.total")} {brchList.length}{t("dashboardPage.occur")}</h2>
                        {brchClsfCd === "KSC" &&
                            <div className="table-title-btn-wrap">
                                <ButtonUtil className="btn btn-outline-danger round-solid-small-red-btn small-btn"
                                    value={t("common.regLable")}
                                    onClick={() => { handleCmmnBtnClick("cmmnInsertBtn") }}
                                />
                                <ButtonUtil className="btn btn-danger round-filled-small-btn small-btn mr-5"
                                    value={t("common.delLable")}
                                    onClick={() => { handleCmmnBtnClick("cmmnDeleteBtn") }}
                                />
                            </div>
                        }
                    </TableTitle>
                    <div className="p-30">
                        <TableWrap>
                            <div className="table scroll-wrap h-10">
                                <div className="thead">
                                    <div className="th w-05"></div>
                                    <div className="th w-05">{t("brchMgtPage.index")}</div>
                                    <div className="th w-15">{t("brchMgtPage.brch")}</div>
                                    <div className="th w-1">{t("brchMgtPage.division")}</div>
                                    <div className="th w-1">{t("brchMgtPage.crgrNm")}</div>
                                    <div className="th w-1">{t("brchMgtPage.crgrTelNo")}</div>
                                    <div className="th w-15">{t("brchMgtPage.plfmFee")}</div>
                                    <div className="th w-1">{t("brchMgtPage.creditHeld")}</div>
                                    <div className="th w-1">{t("brchMgtPage.credit")}</div>
                                    <div className="th w-1">{t("brchMgtPage.crdCrgrHst")}</div>
                                </div>
                                <div className="tbody-wrap">
                                    {brchList.map((data, idx) => (
                                        <div className="tbody"
                                            key={data.brchId}
                                            onClick={() => onCheckedElement(!selectedBrchIds.includes(data.brchId), data.brchId)}
                                            onDoubleClick={() => { onRowDoubleClicked(data.brchId) }}
                                        >
                                            <div className="tb w-05">
                                                <input type="checkbox"
                                                    name="brchId"
                                                    value={data.brchId}
                                                    onChange={(e) => onCheckedElement(e.target.checked, data.brchId)}
                                                    checked={selectedBrchIds.includes(data.brchId) ? true : false}
                                                />
                                            </div>
                                            <div className="tb w-05">{idx + 1}</div>
                                            <div className="tb w-15">{data.brchNm}</div>
                                            <div className="tb w-1">{data.cmmnCdNm}</div>
                                            <div className="tb w-1">{data.brchCrgrNm}</div>
                                            <div className="tb w-1">{getFormattedValue(data.brchCrgrTelNo)}</div>
                                            <div className="tb w-15">{data.plfmFee ? (data.natnCd === "KR" ? `₩${data.plfmFee}` : `¥${data.plfmFee}`) : '-'}</div>
                                            <div className="tb w-1">{data && data.possCrdCnt !== undefined ? data.possCrdCnt.toLocaleString() : "-"}</div>
                                            <div className="tb w-1">
                                                {(data.plfmFee > 0 && data.brchClsfCd === "JMC") ?
                                                    <ButtonUtil className="btn btn-outline-danger round-solid-small-red-btn small-btn"
                                                        value={t("brchMgtPage.charge")}
                                                        onClick={() => { openChargeModal(data); }} 
                                                        disabled={false} />
                                                    :
                                                    "-"
                                                }
                                            </div>
                                            <div className="tb w-1">
                                                {(data.possCrdCnt > 0 && data.brchClsfCd === "JMC") ?
                                                    <ButtonUtil className="btn btn-danger round-filled-gray-btn small-btn max-content"
                                                        value={t("brchMgtPage.view")}
                                                        onClick={() => { openHistoryModal(data); setModalTitle(`${t("brchMgtPage.crdCrgrHst")}`); }} />
                                                    :
                                                    "-"
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </TableWrap>
                    </div>
                </div>
            </DashBoardWrap>
            <CmmnCrdHistModal
                show={historyModalOpen}
                data={historyModalData}
                onHide={() => setHistoryModalOpen(false)}
                title={modalTitle}
            />
            <CmmnCrdChargeModal
                show={chargeModalOpen}
                data={chargeModalData}
                onHide={() => setChargeModalOpen(false)}
                getBrchCrdInfo={getBrchCrdInfo}
                getBrchAllList={getBrchAllList}
                t={t}
                stat={stat}
                setStat={setStat}
            />
        </div>
    )
}