'use client'

import styled from "styled-components";
import navLogo from "asset/photomag_web/img/logo_white.svg"
import LeftMenu from "./LeftMenu2";
import { useNavigate } from "react-router-dom";

const SideNavWrap = styled.nav`
    height: 100vh;
    overflow-y: auto;
    background-color: #161611;
    padding: 0 20px;
    width: 18%;
    box-shadow: 0 4px 10px rgba(0,0,0,.2);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 98;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 40px;
    @media (max-width: 1500px) {
        width: 20%;
    }
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background-color: rgba(0,0,0,0);
        border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(255,255,255,1);
        background-clip: padding-box;
        border-radius: 10px; 
        border: 3px solid transparent;
    }
`;
const SideNavHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    .logo img {
        width: 130px;
    }
`;

function Gnb() {

    const navigate = useNavigate();

    return (
        <SideNavWrap>
            <div>
                <SideNavHeader>
                    <div className="logo">
                        <img 
                            src={navLogo} 
                            alt="nav_logo" 
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/dsh-brd/dsh-brd")}
                        />
                    </div>
                </SideNavHeader>
                <LeftMenu />
            </div>
        </SideNavWrap>
    )


}
export default Gnb;