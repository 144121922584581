import styled from "styled-components";
import TextFields from "../UI/TextFields";
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import { useEffect, useState } from "react";
import SelectBox from "../UI/SelectBox";
import { callApi } from "util/HttpService";
import { alert, modalConfirm } from "./CmmnConfirm";

const TableWrap = styled.div`  
    @media (max-width: 1200px) {
        overflow: scroll;
    }
    h2 {
        font-size: 18px;
        color: #3b3b3b;
        font-weight: 500;
        margin-bottom: 15px;
    } 
`;

const InputTable = styled.div`
    // margin-bottom: 20px;
    border-top: 2px solid #EF2929;
    .input-table-th-small{
        max-width: 151px;
        min-width: 151px;
    }
    .input-table-wrap{
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
`;
const InputTableHead = styled.div`
    background-color: #F3F3F3;
    padding: 20px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80px;
    max-width: 221px;
    min-width: 151px;
    h3 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
    }
`;
const InputTableBody = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;
    h4 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 400;
    }
    h5 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        margin-left: 10px;
    }
    .form-select {
        max-width: 250px;
    }
    input[type="text"] {
        max-width: 250px;
        width: 250px;
    }
`;
const BtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translate(-51%);
    .btn {
        width: 150px;
        max-width: 150px;
        &:first-child {
            margin-right: 10px;
        }
        &:nth-child(2) {
            margin-right: 10px;
            &:hover {
                color: var(--bs-btn-hover-color);
                background-color: var(--bs-btn-hover-bg);
                border-color: var(--bs-btn-hover-border-color);
            }
        }
        &:first-child:hover,
        &:last-child:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
    .round-filled-small-btn {
        background-color: ${({ theme }) => theme.colors.red} !important;
    }
`;

export default function SystemConfig({
    t,
    brchClsfCd,
    systemModeArray,
    data,
    kiskInfo,
    onHide,
    getKiskInfo,
}) {
    const userSqno = localStorage.getItem("userSqno");
    const [systemSettings, setSystemSettings] = useState({});
    useEffect(() => {
        if (kiskInfo && kiskInfo.sysStupCn) {
            setSystemSettings(kiskInfo.sysStupCn);
        }
    }, [kiskInfo]);

    // useEffect(() => {
    //     console.log(systemSettings);
    // }, [systemSettings]);

    const handleChange = (e, key) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setSystemSettings(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    // PC 종료 시간
    function generateTimeOptions() {
        const options = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 10) {
                const formattedHour = String(hour).padStart(2, '0');
                const formattedMinute = String(minute).padStart(2, '0');
                options.push({
                    key: `${formattedHour}:${formattedMinute}`,
                    value: `${formattedHour}:${formattedMinute}`,
                });
            }
        }
        return options;
    };
    const pcShutdownTimeArray = generateTimeOptions();
    // 서비스 기본 언어
    const defaultLangArray = [
        { key: "한국어", value: "kr" },
        { key: "日本語", value: "jp" },
        { key: "English", value: "en" },
        { key: "简体中文", value: "cn" },
        { key: "แบบไทย", value: "th" },
        { key: "Tiếng Việt", value: "vi" },
        { key: "melayu", value: "ms" },
        { key: "Español", value: "es" },
        { key: "Français", value: "fr" },
        { key: "bahasa Indonesia", value: "id" },
    ];
    // Iot 보드 포트, 결제기 포트
    const comPortsArray = Array.from({ length: 256 }, (_, i) => ({
        key: `COM${i + 1}`,
        value: `COM${i + 1}`,
    }));
    // 동전투입기 선택
    const coinAcceptorArray = [
        { key: "JY", value: "JY" },
        { key: "VN", value: "VN" },
    ];
    // 프린트 출력 설정
    const printCopiesModeArray = [
        { key: t("systemSetting.optnProdPrintCopiesMode"), value: "prod" },
        { key: t("systemSetting.optnDevPrintCopiesMode"), value: "dev" },
    ];

    const handleCmmnBtnClick = async (btnNm) => {
        switch (btnNm) {
            case "cmmnInsertBtn":
                try {
                    const commonParams = { brchId: data.brchId, eqmtId: data.eqmtId, eqmtNo: data.eqmtNo, delYn: 'N' };

                    // systemSettings
                    const systemParams = { ...commonParams, sysStupCn: systemSettings };
                    await callApi("POST", systemParams, "/web/api/dvc-mgt/edm/kisk-insert");
                    console.log("전송된 systemParams : ", systemParams);

                    alert("", t("alert.save"), () => { getKiskInfo() });

                } catch (error) {
                    console.log(error);
                    alert("", t("alert.saveError"), () => { return });
                }
                break;
            case "cmmnResetBtn":
                try {
                    modalConfirm("", t("alert.resetConfirm"), () => {
                        setSystemSettings(kiskInfo.sysStupCn);
                        alert("", t("alert.initSuccessAlert"), () => { });
                    })
                } catch (error) {
                    console.log(error);
                    alert("", t("alert.initFailAlert"), () => { });
                }
                break;
            default: break;
        };
    };

    return (
        <>
            <div className="p-30">
                <TableWrap>
                    <h2>{t("systemSetting.title")}</h2>
                    <InputTable>
                        {(brchClsfCd === "KSC" || [4, 5, 11, 12].includes(parseInt(userSqno))) && (
                            <div className="input-table-wrap">
                                <InputTableHead>
                                    <h3>{t("systemSetting.lblSystemMode")}</h3>
                                </InputTableHead>
                                <InputTableBody>
                                    <SelectBox
                                        defaultOptTxt={t("saleHistPage.entire")}
                                        dataArray={systemModeArray}
                                        onSelectChange={(e) => handleChange(e, 'systemMode')}
                                        tagName="systemMode"
                                        defaultVal={systemSettings?.systemMode || 'S1'}
                                    />
                                </InputTableBody>
                            </div>
                        )}
                        <div className="input-table-wrap">
                            <InputTableHead>
                                <h3>{t("systemSetting.lblPcShutDown")}</h3>
                            </InputTableHead>
                            <InputTableBody>
                                <SelectBox
                                    defaultOptTxt={t("saleHistPage.entire")}
                                    dataArray={pcShutdownTimeArray}
                                    onSelectChange={(e) => handleChange(e, 'pcShutdownTime')}
                                    tagName="pcShutdownTime"
                                    defaultVal={systemSettings?.pcShutdownTime || '17:00'}
                                />
                            </InputTableBody>
                        </div>
                        {brchClsfCd === "KSC" &&
                            <div className="input-table-wrap">
                                <InputTableHead>
                                    <h3>{t("systemSetting.lblEqmtNo")}</h3>
                                </InputTableHead>
                                <InputTableBody>
                                    <TextFields
                                        type="text"
                                        name="eqmtNo"
                                        onChangeValue={(e) => handleChange(e, 'eqmtNo')}
                                        value={systemSettings?.eqmtNo || ""}
                                    />
                                </InputTableBody>
                            </div>
                        }
                        {(brchClsfCd === "KSC" || [4, 5, 11, 12].includes(parseInt(userSqno))) && (
                            <div className="input-table-wrap">
                                <InputTableHead>
                                    <h3>{t("systemSetting.lblServiceDefaultLang")}</h3>
                                </InputTableHead>
                                <InputTableBody>
                                    <SelectBox
                                        defaultOptTxt={t("saleHistPage.entire")}
                                        dataArray={defaultLangArray}
                                        onSelectChange={(e) => handleChange(e, 'defaultLang')}
                                        tagName="defaultLang"
                                        defaultVal={systemSettings?.defaultLang || 'jp'}
                                    />
                                </InputTableBody>
                            </div>
                        )}
                        {brchClsfCd === "KSC" &&
                            <div className="input-table-wrap">
                                <InputTableHead>
                                    <h3>{t("systemSetting.lblIoTBoardComPort")}</h3>
                                </InputTableHead>
                                <InputTableBody>
                                    <SelectBox
                                        defaultOptTxt={t("saleHistPage.entire")}
                                        dataArray={comPortsArray}
                                        onSelectChange={(e) => handleChange(e, 'iotComPort')}
                                        tagName="iotComPort"
                                        defaultVal={systemSettings.iotComPort || 'COM2'}
                                    />
                                </InputTableBody>
                            </div>
                        }
                        {brchClsfCd === "KSC" &&
                            <div className="input-table-wrap">
                                <InputTableHead>
                                    <h3>{t("systemSetting.lblCoinAcceptorComPort")}</h3>
                                </InputTableHead>
                                <InputTableBody>
                                    <SelectBox
                                        defaultOptTxt={t("saleHistPage.entire")}
                                        dataArray={comPortsArray}
                                        onSelectChange={(e) => handleChange(e, 'coinAcceptorComPort')}
                                        tagName="coinAcceptorComPort"
                                        defaultVal={systemSettings.coinAcceptorComPort || 'COM2'}
                                    />
                                </InputTableBody>
                            </div>
                        }
                        {brchClsfCd === "KSC" &&
                            <div className="input-table-wrap">
                                <InputTableHead>
                                    <h3>{t("systemSetting.lblCoinAcceptorSelect")}</h3>
                                </InputTableHead>
                                <InputTableBody>
                                    <SelectBox
                                        defaultOptTxt={t("saleHistPage.entire")}
                                        dataArray={coinAcceptorArray}
                                        onSelectChange={(e) => handleChange(e, 'coinAcceptor')}
                                        tagName="coinAcceptor"
                                        defaultVal={systemSettings.coinAcceptor || 'JY'}
                                    />
                                </InputTableBody>
                            </div>
                        }
                        {(brchClsfCd === "KSC" || [4, 5, 11, 12].includes(parseInt(userSqno))) && (
                            <div className="input-table-wrap">
                                <InputTableHead>
                                    <h3>{t("systemSetting.lblPrintCopiesModeSelect")}</h3>
                                </InputTableHead>
                                <InputTableBody>
                                    <SelectBox
                                        defaultOptTxt={t("saleHistPage.entire")}
                                        dataArray={printCopiesModeArray}
                                        onSelectChange={(e) => handleChange(e, 'printCopiesMode')}
                                        tagName="printCopiesMode"
                                        defaultVal={systemSettings.printCopiesMode || 'prod'}
                                    />
                                </InputTableBody>
                            </div>
                        )}
                    </InputTable>
                </TableWrap>
            </div>
            <BtnWrap>
                <ButtonUtil className="btn btn-danger round-filled-small-btn"
                    value={t("common.lblInitBtn")}
                    onClick={() => { handleCmmnBtnClick("cmmnResetBtn") }}
                />
                <ButtonUtil className="btn btn-outline-light round-solid-medium-btn"
                    value={t("common.cancelLable")}
                    onClick={() => { onHide() }}
                />
                <ButtonUtil className="btn btn-danger round-filled-small-btn"
                    value={t("common.saveLable")}
                    onClick={() => { handleCmmnBtnClick("cmmnInsertBtn") }}
                />
            </BtnWrap>
        </>
    )

}