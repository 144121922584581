'use client'

import { alert, confirm } from "components/web/cmmn/CmmnConfirm";
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { callApi } from "util/HttpService";
import { useTranslation } from "react-i18next";
import titleLogo from "asset/photomag_web/img/icon/filled/insert-emoticon.svg"
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import TextFields from "components/web/UI/TextFields";
import { getFormattedValue } from "util/CmmnUtils";

const DashBoardWrap = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 0 0;
    .dashboard-box {
        background-color: #fff;
        border-radius: 16px;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
    }
    .p-30 {
        padding: 30px;
    }
    @media (max-width: 1200px) {
        .table-wrap{
            overflow: scroll;
        }
        #table1{
            width: 1000px;
        }
        .input-option-wrap button{
            width: 100px !important;
        }
    }
    @media (max-width: 1600px) {
        .th, .tb {
            font-size: 15px !important;
        }
    }
    @media (min-width: 1601px) {
        .th, .tb {
            font-size: 16px !important;
        }
    }
    .btn {
        &:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
    .round-filled-small-btn {
        background-color: ${({ theme }) => theme.colors.red} !important;
    }
    input[type="checkbox"] {
        accent-color: #EF2929;
    }
`;
const InputOptionWrap = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .wrap {
        display: flex;
        align-items: center;
        &:first-child {
            margin-bottom: 20px;
        }
        li {
            display: flex;
            align-items: center;
            &:first-child {
                margin-right: 50px;
            }
            h3 {
                font-size: 16px;
                color: #3b3b3b;
                font-weight: 500;
                min-width: 100px;
            }
        }
    }
    & > ul {
        width: fit-content;
    }
    .btn {
        max-width: 150px;
    }
`;
const TableTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    h2 {
        font-size: 18px;
        color: #3b3b3b;
        font-weight: 500;
    }
    .table-title-btn-wrap {
        display: flex;
        align-items: center;
        .btn {
            width: 60px !important;
            &:first-child {
                margin-right: 10px;
            }
        }
        .mr-5{
            margin-right: 5px;
        }
    }
`;
const TableWrap = styled.div`
    padding: 30px;
    h2 {
        font-size: 18px;
        color: #3b3b3b;
        font-weight: 500;
        margin-bottom: 15px;
    }
    .use-text {
        color: #4BD907 !important;
        font-size: 16px;
        font-weight: 500;
        text-decoration: underline;
    }
    .unuse-text {
        color: #6c757d !important;
        font-size: 16px;
        font-weight: 500;
        text-decoration: underline;
    }
    .tbody:hover .tb {
        background-color: #fafafa !important;
    }
    .tbody:active .tb, .tbody:focus .tb {
        background-color: #f5f5f5 !important;
    }
    .th {
        padding: 15px 0px !important;
    }
    .tb {
        padding: 15px 5px !important;           
    }
`;
export default function UserMgt() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const locBrchId = localStorage.getItem("brchId");
    const brchClsfCd = localStorage.getItem("brchClsfCd");
    const userSqno = localStorage.getItem("userSqno");

    const defaultSearchData = {
        userId: '',
        userNm: '',
        userTelNo: '',
        userEmalAddr: '',
        userSqno: '',
    };

    const [searchData, setSearchData] = useState(defaultSearchData);
    const [userList, setUserList] = useState([]);
    const [selectedUserSqno, setSelectedUserSqno] = useState([]);

    const getUserList = () => {
        let params;
        switch (brchClsfCd) {
            case "KSC":
                params = { ...searchData };
                break;
            case "FCC":
                params = { ...searchData, brchId: locBrchId };
                break;
            case "JMC":
                params = { ...searchData, brchId: locBrchId, brchClsfCd: "JMC" };
                break;
            default: break;
        }
        callApi("GET", params, "/web/api/svc-mgt/user/list")
            .then(res => {
                // console.log(res);
                setUserList(res);
            })
            .catch(error => {
                console.log(error);
            })
    };

    useEffect(() => {
        getUserList();
    }, []);

    const handleSearchDataChange = (event) => {
        const { name, value } = event.target;
        let formattedValue = value;
        if (name === "userTelNo") {
            formattedValue = value.replace(/[^0-9]/g, "");
        }
        setSearchData((prevData) => ({
            ...prevData,
            [name]: formattedValue
        }));
    };

    const onAllCheckBox = useCallback((checked) => {
        if (checked) {
            const checkedListArray = userList.map(data => data.userSqno);
            setSelectedUserSqno(checkedListArray);
        } else {
            setSelectedUserSqno([]);
        }
    }, [userList]);

    const onCheckedElement = useCallback((checked, userSqno) => {
        if (checked) {
            setSelectedUserSqno(prevData => [...prevData, userSqno]);
        } else {
            setSelectedUserSqno(prevData => prevData.filter((id) => id !== userSqno));
        }
    }, []);

    const handleCmmnBtnClick = (btnNm) => {
        switch (btnNm) {
            case "cmmnSearchBtn":
                getUserList();
                break;
            case "cmmnInsertBtn":
                navigate(`/svc-mgt/user-mgt/0`);
                break;
            case "cmmnDeleteBtn":
                const userSqnoList = selectedUserSqno.join(',');
                if (selectedUserSqno.length > 0) {
                    confirm("", t("alert.deleteConfirm"), () => {
                        callApi("GET", { userSqno: userSqnoList }, "/web/api/svc-mgt/user/delete")
                            .then(res => {
                                alert("", t("alert.delete"), () => { getUserList() });
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    })
                } else {
                    alert("", t("alert.deleteNumber"), () => { });
                    console.log("No branches selected for deletion.");
                    return;
                }
                break;
            default: break;
        }
    }

    const onRowDoubleClicked = (userSqno, brchIdProps) => {
        navigate(`/svc-mgt/user-mgt/${userSqno}`, { state: { brchIdProps } })
    }

    return (
        <div className="padding-box">
            <div className="title-wrap">
                <div className="title">
                    <div className="alignset">
                        <img src={titleLogo} alt={t("leftMenu.userMgt")} />
                        <h2>{t("leftMenu.userMgt")}</h2>
                    </div>
                    <p>Home {">"} {t("leftMenu.svcMng")} {">"} {t("leftMenu.userMgt")}</p>
                </div>
            </div>
            <DashBoardWrap>
                <div className="dashboard-box p-30">
                    <InputOptionWrap>
                        <ul>
                            <ul className="wrap">
                                <li>
                                    <h3>{t("userMgtPage.id")}</h3>
                                    <TextFields
                                        type="text"
                                        name="userId"
                                        value={searchData.userId}
                                        onChangeValue={handleSearchDataChange}
                                    />
                                </li>
                                <li>
                                    <h3>{t("userMgtPage.name")}</h3>
                                    <TextFields
                                        type="text"
                                        name="userNm"
                                        value={searchData.userNm}
                                        onChangeValue={handleSearchDataChange}
                                    />
                                </li>
                            </ul>
                            <ul className="wrap">
                                <li>
                                    <h3>{t("userMgtPage.tel")}</h3>
                                    <TextFields
                                        type="text"
                                        name="userTelNo"
                                        value={getFormattedValue(searchData.userTelNo)}
                                        onChangeValue={handleSearchDataChange}
                                        placeholder={`“-” ${t("userMgtPage.enterExcept")}`}
                                    />
                                </li>
                                <li>
                                    <h3>{t("userMgtPage.email")}</h3>
                                    <TextFields
                                        type="text"
                                        name="userEmalAddr"
                                        value={searchData.userEmalAddr}
                                        onChangeValue={handleSearchDataChange}
                                    />
                                </li>
                            </ul>
                        </ul>
                        <ButtonUtil className="btn btn-danger round-filled-small-btn small-btn"
                            value={t("common.searchLable")}
                            onClick={() => { handleCmmnBtnClick("cmmnSearchBtn") }}
                        />
                    </InputOptionWrap>
                </div>
                <div className="dashboard-box">
                    <TableTitle>
                        <h2>{t("userMgtPage.total")} {userList.length}{t("dashboardPage.occur")}</h2>
                        <div className="table-title-btn-wrap">
                            <ButtonUtil className="btn btn-outline-danger round-solid-small-red-btn small-btn"
                                value={t("common.regLable")}
                                onClick={() => { handleCmmnBtnClick("cmmnInsertBtn") }}
                            />
                            {brchClsfCd === "KSC" &&
                                <ButtonUtil className="btn btn-danger round-filled-small-btn small-btn mr-5"
                                    value={t("common.delLable")}
                                    onClick={() => { handleCmmnBtnClick("cmmnDeleteBtn") }}
                                />
                            }
                        </div>
                    </TableTitle>
                    <TableWrap>
                        <div className="table scroll-wrap h-10">
                            <div className="thead">
                                {/* <div className="th w-05">
                                        <input type="checkbox"
                                            onChange={(e) => { onAllCheckBox(e.target.checked) }}
                                            checked={
                                                selectedUserSqno.length === 0
                                                    ? false
                                                    : selectedUserSqno.length === userList.length
                                                        ? true
                                                        : false
                                            }
                                        />
                                    </div> */}
                                <div className="th w-05"></div>
                                <div className="th w-05">{t("userMgtPage.index")}</div>
                                <div className="th w-2">{t("userMgtPage.brch")}</div>
                                <div className="th w-1">{t("userMgtPage.id")}</div>
                                <div className="th w-15">{t("brchMgtPage.crgrNm")}</div>
                                <div className="th w-15">{t("userMgtPage.tel")}</div>
                                <div className="th w-25">{t("userMgtPage.email")}</div>
                                <div className="th w-1">{t("userMgtPage.useYn")}</div>
                            </div>
                            <div className="tbody-wrap">
                                {userList.map((data, idx) => (
                                    <div className="tbody"
                                        key={data.userSqno}
                                        onClick={() => onCheckedElement(!selectedUserSqno.includes(data.userSqno), data.userSqno)}
                                        onDoubleClick={() => { onRowDoubleClicked(data.userSqno, data.brchId) }}
                                    >
                                        <div className="tb w-05">
                                            <input type="checkbox"
                                                name="userSqno"
                                                value={data.userSqno}
                                                onChange={(e) => onCheckedElement(e.target.checked, data.userSqno)}
                                                checked={selectedUserSqno.includes(data.userSqno) ? true : false}
                                            />
                                        </div>
                                        <div className="tb w-05">{idx + 1}</div>
                                        <div className="tb w-2">{data.brchNm}</div>
                                        <div className="tb w-1">{data.userId}</div>
                                        <div className="tb w-15">{data.brchCrgrNm}</div>
                                        <div className="tb w-15">{getFormattedValue(data.userTelNo)}</div>
                                        <div className="tb w-25">{data.userEmalAddr}</div>
                                        <div className={`tb w-1 ${data.useYn === "Y" ? "use-text" : "unuse-text"}`}>{data.useYn === "Y" ? t("m_dashboardPage.creditUse") : t("userMgtPage.unused")}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </TableWrap>
                </div>
            </DashBoardWrap>
        </div>
    )
}