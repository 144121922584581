'use client'

import TextFields from "components/web/UI/TextFields";
import CmmnCdSelectBox from "components/web/cmmn/CmmnCdSelectBox";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { callApi } from "util/HttpService";
import DaumPostcode from "react-daum-postcode";
import Modal from "components/web/UI/Modal";
import TitleLogo from "asset/photomag_web/img/icon/filled/insert-emoticon.svg"
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { getFormattedValue } from "util/CmmnUtils";
import { alert } from "components/web/cmmn/CmmnConfirm";
import styled from "styled-components";
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import BrchNmSelectBox from "components/web/cmmn/BrchNmSelectBox";

const DashBoardWrap = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 0 0;
    .dashboard-box {
        background-color: #fff;
        border-radius: 16px;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
        .p-30 {
            padding: 30px;
        }
        .table-wrap {
            h2 {
                font-size: 18px;
                color: #3b3b3b;
                font-weight: 500;
                margin-bottom: 15px;
            }
        }
    }
    @media (max-width: 1200px) {
        .table-wrap{
            overflow: scroll;
        }
    }
`;
const InputTable = styled.div`
    margin-bottom: 20px;
    border-top: 2px solid #EF2929;
    .input-table-th-small{
        max-width: 138px;
        min-width: 138px;
    }
    .input-table-wrap{
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
    .h-2row {
        min-height: 145px !important;
    }
    .h-15row{
        min-height: 110px !important;
    }
`;
const InputTableHead = styled.div`
    background-color: #F3F3F3;
    padding: 20px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 78px;
    max-width: 208px;
    min-width: 208px;
    h3 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
    }
`;
const InputTableBody = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;
    h4 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 400;
    }
    .form-select {
        max-width: 250px;
    }
    input[type="text"] {
        max-width: 250px;
        width: 250px;
    }
    &:nth-child(2) .btn {
        margin-left: 10px;
    }
`;
const AddressWrap = styled.div`
    & > div:last-child {
        margin-top: 20px;
        input:first-child {
            margin-right: 15px;
        }
    }
`;
const CostWrap = styled.div`
    p {
        font-size: 14px;
        color: #3b3b3b;
        font-weight: 400;
        margin-top: 15px;
    }
    h5 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        margin-left: 10px;
    }
`;
const BtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
        width: 150px;
        max-width: 150px;
        &:first-child {
            margin-right: 10px;
            &:hover {
                color: var(--bs-btn-hover-color);
                background-color: var(--bs-btn-hover-bg);
                border-color: var(--bs-btn-hover-border-color);
            }
        }
        &:last-child:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
    .round-filled-small-btn {
        background-color: ${({ theme }) => theme.colors.red} !important;
    }
`;
export default function BrchMgtDetail() {

    const { t } = useTranslation();
    const props = useParams();
    const [brchId, setBrchId] = useState(props);
    const brchClsfCd = localStorage.getItem("brchClsfCd");
    const navigate = useNavigate();

    const defaultBrchInfo = {
        brchNm: "",
        brchClsfCd: "",
        natnCd: "",
        brchAddr: "",
        brchDtlAddr: "",
        brchCrgrNm: "",
        brchCrgrTelNo: "",
        plfmFee: "",
        brchZpcd: "",
        delYn: "N",
        frncId: "",
    }

    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [brchInfo, setBrchInfo] = useState(defaultBrchInfo);

    const validationSchema = Yup.object().shape({
        brchNm: Yup.string().required(t("alert.brchNmInput")),
        brchClsfCd: Yup.string().required(t("alert.companySelect")),
        natnCd: Yup.string().required(t("alert.nationSelect")),
        brchZpcd: Yup.string().required(t("alert.zipCodeSelect")),
        brchDtlAddr: Yup.string().required(t("alert.addrDtlInput")),
        brchCrgrNm: Yup.string().required(t("alert.crgrNmInput")),
        brchCrgrTelNo: Yup.string().required(t("alert.telSelect")),
        plfmFee: Yup.string().test(
            'is-required-if-JMC',
            t('alert.stFeePerCaseInput'),
            function (value) {
                if (brchInfo.brchClsfCd === 'JMC') {
                    return value != null && value.trim() !== '';
                }
                return true;
            }
        )
    });

    // useEffect(() => {
    //     console.log(errors);
    //     console.log(brchInfo);
    // }, [errors, brchInfo]);

    useEffect(() => {
        if (brchId !== 0) {
            callApi("GET", brchId, "/web/api/svc-mgt/brch/detail")
                .then(res => {
                    setBrchInfo(res);
                    setBrchInfo((prevData) => ({
                        ...prevData
                    }));
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }, [brchId]);

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;
        if (name === "brchCrgrTelNo") {
            formattedValue = value.replace(/[^0-9]/g, "");
            if (formattedValue.length > 11) {
                formattedValue = formattedValue.slice(0, 11);
            }
        }
        setBrchInfo((prevData) => ({
            ...prevData,
            delYn: 'N',
            [name]: formattedValue,
        }));

        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (formattedValue !== '') {
                delete newErrors[name];
            }
            return newErrors;
        });
    };

    const handleCmmnBtnClick = (btnNm) => {
        switch (btnNm) {
            case "cmmnUpdtBtn":
            case "cmmnInsertBtn":
                validationSchema.validate(brchInfo, { abortEarly: false })
                    .then(() => {
                        let reqCd = 'insert';
                        if (brchInfo.brchId > 0) {
                            reqCd = 'update';
                        }
                        setErrors({});
                        setSubmitted(true);
                        callApi("POST", brchInfo, `/web/api/svc-mgt/brch/${reqCd}`)
                            .then(res => {
                                if (res) {
                                    console.log(res);
                                    alert("", t("alert.save"), () => { navigate("/svc-mgt/brch-mgt") });
                                }
                            })
                            .catch(error => {
                                console.log(error);
                                alert("", t("alert.saveError"), () => { return; });
                            })
                    })
                    .catch((err) => {
                        const newErrors = {};
                        err.inner.forEach((error) => {
                            newErrors[error.path] = error.message;
                        });
                        setErrors(newErrors);
                    });

                break;
            case "cmmnListBtn":
                navigate("/svc-mgt/brch-mgt");
                break;
            default: break;
        }
    }

    const handlePostSearchComplete = (data) => {
        setBrchInfo(prevData => ({
            ...prevData,
            brchZpcd: data.zonecode,
            brchAddr: data.address,
            lgdrCd: data.bcode,
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            brchZpcd: ''
        }));
        setIsModalOpen(false);
    };

    return (
        <div className="padding-box">
            <div className="title-wrap">
                <div className="title space-between">
                    <div className="dp-flex align-cen">
                        <img src={TitleLogo} alt={t("leftMenu.brchMgt")} />
                        <h2>{t("leftMenu.brchMgt")}</h2>
                    </div>
                    <p>Home {">"} {t("leftMenu.svcMng")} {">"} {t("leftMenu.brchMgt")}</p>
                </div>
            </div>
            <DashBoardWrap>
                <div className="dashboard-box">
                    <div className="p-30">
                        <div className="table-wrap">
                            <h2>{props.brchId === "0" ?
                                `${t("brchMgtPage.brch")} ${t("common.regLable")}`
                                :
                                `${t("brchMgtPage.brch")} ${t("common.updtLable")}`
                            }
                            </h2>
                            <InputTable>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("brchMgtPage.brch")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <TextFields
                                            type="text"
                                            onChangeValue={handleFormDataChange}
                                            value={brchInfo?.brchNm || ""}
                                            name="brchNm"
                                            errorTxt={errors.brchNm}
                                            disabled={brchClsfCd !== "KSC"}
                                            className={brchClsfCd !== "KSC" ? "readonly" : ""}
                                        />
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("brchMgtPage.division")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <CmmnCdSelectBox
                                            cmmnCdId={1}
                                            defaultOptTxt={`${t("brchMgtPage.division")}${t("common.selectLable")}`}
                                            defaultVal={brchInfo.brchClsfCd}
                                            tagName={"brchClsfCd"}
                                            onSelectChange={handleFormDataChange}
                                            errorTxt={errors.brchClsfCd}
                                            disabled={brchClsfCd !== "KSC"}
                                        />
                                    </InputTableBody>
                                </div>
                                {(props.brchId === "0" && brchInfo.brchClsfCd === "JMC") && (
                                    <div className="input-table-wrap">
                                        <InputTableHead>
                                            <h3>{t("brchMgtPage.Franchise")}</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <BrchNmSelectBox
                                                defaultVal={brchInfo.frncId}
                                                tagName={"frncId"}
                                                defaultOptTxt={`${t("brchMgtPage.Franchise")}${t("common.selectLable")}`}
                                                onSelectChange={handleFormDataChange}
                                                brchClsfCd={"FCC"}
                                                disabled={brchClsfCd !== "KSC"}
                                            />
                                        </InputTableBody>
                                    </div>
                                )}
                                {(props.brchId !== "0" && brchInfo.brchClsfCd === "JMC") && (
                                    <div className="input-table-wrap">
                                        <InputTableHead>
                                            <h3>{t("brchMgtPage.Franchise")}</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <BrchNmSelectBox
                                                defaultVal={brchInfo.frncId}
                                                tagName={"frncId"}
                                                defaultOptTxt={`${t("brchMgtPage.Franchise")}${t("common.selectLable")}`}
                                                onSelectChange={handleFormDataChange}
                                                brchClsfCd={"FCC"}
                                                disabled={brchClsfCd !== "KSC"}
                                            />
                                        </InputTableBody>
                                    </div>
                                )}
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("brchMgtPage.nation")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <CmmnCdSelectBox
                                            cmmnCdId={14}
                                            defaultOptTxt={`${t("brchMgtPage.nation")}${t("common.selectLable")}`}
                                            defaultVal={brchInfo.natnCd}
                                            tagName={"natnCd"}
                                            onSelectChange={handleFormDataChange}
                                            errorTxt={errors.natnCd}
                                        />
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead className="h-2row">
                                        <h3>{t("brchMgtPage.addr")}</h3>
                                        <Modal
                                            show={isModalOpen}
                                            onHide={() => setIsModalOpen(false)}
                                            title={`${t("brchMgtPage.addr")} ${t("common.searchLable")}`}
                                        >
                                            <DaumPostcode onComplete={handlePostSearchComplete} />
                                        </Modal>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <AddressWrap>
                                            <div className="dp-flex align-cen">
                                                <TextFields
                                                    type="text"
                                                    onChangeValue={handleFormDataChange}
                                                    value={brchInfo?.brchZpcd || ""}
                                                    name="brchZpcd"
                                                />
                                                <ButtonUtil
                                                    className="btn btn-outline-danger round-solid-small-red-btn small-btn max-content"
                                                    value={t("brchMgtPage.postalCode")}
                                                    onClick={() => setIsModalOpen(true)}
                                                />
                                                {errors.brchZpcd && <p style={{ marginLeft: "1rem", fontSize: "0.9rem", color: "#ef2929" }}>{errors.brchZpcd}</p>}
                                            </div>
                                            <div className="dp-flex align-cen">
                                                <TextFields
                                                    type="text"
                                                    name="brchAddr"
                                                    value={brchInfo?.brchAddr || ""}
                                                    disabled={true}
                                                    onChangeValue={handleFormDataChange}
                                                    className="readonly"
                                                />
                                                <TextFields
                                                    type="text"
                                                    name="brchDtlAddr"
                                                    value={brchInfo?.brchDtlAddr || ""}
                                                    placeholder={t("brchMgtPage.addrDtl")}
                                                    onChangeValue={handleFormDataChange}
                                                    errorTxt={errors.brchDtlAddr}
                                                />
                                            </div>
                                        </AddressWrap>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("brchMgtPage.crgrNm")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <TextFields
                                            type="text"
                                            name="brchCrgrNm"
                                            onChangeValue={handleFormDataChange}
                                            value={brchInfo?.brchCrgrNm || ""}
                                            errorTxt={errors.brchCrgrNm}
                                        />
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("userMgtPage.tel")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <TextFields
                                            type="text"
                                            name="brchCrgrTelNo"
                                            onChangeValue={handleFormDataChange}
                                            value={getFormattedValue(brchInfo?.brchCrgrTelNo) || ""}
                                            placeholder={`“-” ${t("userMgtPage.enterExcept")}`}
                                            errorTxt={errors.brchCrgrTelNo}
                                        />
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead className="h-15row">
                                        <h3>{t("brchMgtPage.plfmFee")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <CostWrap>
                                            <div className="dp-flex align-cen">
                                                {brchInfo.natnCd == undefined || brchInfo.natnCd === "KR" || brchInfo.natnCd === "" ?
                                                    <>
                                                        <TextFields
                                                            type="text"
                                                            name="plfmFee"
                                                            onChangeValue={handleFormDataChange}
                                                            value={brchInfo?.plfmFee || ""}
                                                            disabled={brchClsfCd !== "KSC" || brchInfo.brchClsfCd !== "JMC"}
                                                            className={brchClsfCd !== "KSC" || brchInfo.brchClsfCd !== "JMC" ? "readonly" : ""}
                                                        />
                                                        <h5>{t("brchMgtPage.won")}</h5>
                                                    </>
                                                    :
                                                    <>
                                                        <TextFields
                                                            type="text"
                                                            name="plfmFee"
                                                            onChangeValue={handleFormDataChange}
                                                            value={brchInfo?.plfmFee || ""}
                                                            disabled={brchClsfCd !== "KSC" || brchInfo.brchClsfCd !== "JMC"}
                                                            className={brchClsfCd !== "KSC" || brchInfo.brchClsfCd !== "JMC" ? "readonly" : ""}
                                                        />
                                                        <h5>{t("brchMgtPage.jpy")}</h5>
                                                    </>
                                                }
                                                {errors.plfmFee && brchInfo.brchClsfCd === "JMC" && (
                                                    <p style={{ marginLeft: "1rem", fontSize: "0.9rem", color: "#ef2929", position: "relative", top: "-8px" }}>
                                                        {errors.plfmFee}
                                                    </p>
                                                )}
                                            </div>
                                            <p>※ {t("brchMgtPage.stFeePerCase")}</p>
                                        </CostWrap>
                                    </InputTableBody>
                                </div>
                                {props.brchId === "0" ?
                                    <></>
                                    :
                                    <>
                                        <div className="col-2">
                                            <div className="input-table-wrap w-50">
                                                <InputTableHead>
                                                    <h3>{t("userMgtPage.regUserNm")}</h3>
                                                </InputTableHead>
                                                <InputTableBody>
                                                    <h4>{brchInfo.regUserNm}</h4>
                                                </InputTableBody>
                                            </div>
                                            <div className="input-table-wrap w-50">
                                                <InputTableHead>
                                                    <h3>{t("userMgtPage.chngUserNm")}</h3>
                                                </InputTableHead>
                                                <InputTableBody>
                                                    <h4>{brchInfo.chngUserNm}</h4>
                                                </InputTableBody>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="input-table-wrap w-50">
                                                <InputTableHead>
                                                    <h3>{t("userMgtPage.regDttm")}</h3>
                                                </InputTableHead>
                                                <InputTableBody>
                                                    <h4>{brchInfo.convRegDttm}</h4>
                                                </InputTableBody>
                                            </div>
                                            <div className="input-table-wrap w-50">
                                                <InputTableHead>
                                                    <h3>{t("userMgtPage.chngDttm")}</h3>
                                                </InputTableHead>
                                                <InputTableBody>
                                                    <h4>{brchInfo.convChngDttm}</h4>
                                                </InputTableBody>
                                            </div>
                                        </div>
                                    </>}
                            </InputTable>
                        </div>
                        <BtnWrap>
                            <ButtonUtil className="btn btn-outline-light round-solid-medium-btn"
                                value={t("userMgtPage.userList")}
                                onClick={() => { handleCmmnBtnClick("cmmnListBtn") }}
                            />
                            <ButtonUtil className="btn btn-danger round-filled-small-btn"
                                value={t("userMgtPage.userSave")}
                                onClick={() => { handleCmmnBtnClick("cmmnInsertBtn") }}
                            />
                        </BtnWrap>
                    </div>
                </div>
            </DashBoardWrap>
        </div>
    )
}