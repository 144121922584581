import React, { useEffect, useState } from "react";
import FrameUploadForm from 'components/web/cmmn/FrameUploadForm';
import * as apiSvc from "api/components/cmmn/CmmnApiCallService"
import { Form } from "react-bootstrap"
import { callApi } from "util/HttpService";
import { alert } from "components/web/cmmn/CmmnConfirm";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useBrch } from "components/web/auth/BrchProvider";
import BrchNmSelectBox from "components/web/cmmn/BrchNmSelectBox"
import EqmtNoSelectBox from "components/web/cmmn/EqmtNoSelectBox";
import CmmnCdUploadModal from "components/web/cmmn/CmmnCdUploadModal";

const DashBoardWrap = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 0 0;
    .dashboard-box {
        background-color: #fff;
        border-radius: 16px;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
        .p-30 {
            padding: 30px;
        }
        .table-wrap {
            h2 {
                font-size: 18px;
                color: #3b3b3b;
                font-weight: 500;
                margin-bottom: 15px;
            }
        }
    }
    @media (max-width: 1200px) {
        .table-wrap{
            overflow: scroll;
        }
    }
`;
const InputTableHead = styled.div`
    background-color: #F3F3F3;
    padding: 15px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 85px;
    max-width: 205px;
    min-width: 205px;
    h3 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
    }
`;
const InputTableBody = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;
    h4 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 400;
    }
    .form-select {
        max-width: 250px;
    }
    input[type="text"], input[type="password"] {
        max-width: 650px;
        width: 650px;
    }
    &:nth-child(2) .btn {
        margin-left: 10px;
        &:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
    .round-filled-gray-btn {
        font-size: 14px;
        color: ${({ theme }) => theme.colors.gray6};
        border-radius: 80px;
        background-color: ${({ theme }) => theme.colors.gray5} !important;
        width: 50%;
        padding: 10px 6px;
        border-color: ${({ theme }) => theme.colors.gray5} !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left:5px;
    }
    .round-filled-gray-btn-modal {
        font-size: 14px;
        color: ${({ theme }) => theme.colors.gray6};
        border-radius: 80px;
        background-color: ${({ theme }) => theme.colors.gray5} !important;
        width: 50%;
        padding: 10px 6px;
        border-color: ${({ theme }) => theme.colors.gray5} !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left:20px;
    }
`;

const InputTable = styled.div`
    margin-bottom: 20px;
    border-top: 2px solid #EF2929;
    .input-table-wrap {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
`;

export default function FrameUpload() {
    const {t} = useTranslation();
    const { brchId } = useBrch();
    const brchClsfCd = localStorage.getItem("brchClsfCd");

    const date = new Date();
    const today = date.getFullYear() + String(date.getMonth() + 1).padStart(2, "0") + String(date.getDate()).padStart(2,"0") + String(date.getHours()).padStart(2,"0") + String(date.getMinutes()).padStart(2,"0") + String(date.getSeconds()).padStart(2,"0");
    const [prefix,setPrefix] = useState(today);

    const [colorFrameIcon] = useState(`${prefix}/frame-color-image/color`);
    const [specialFrameIcon] = useState(`${prefix}/frame-color-image/special`);
    const [colorFrame] = useState(`${prefix}/frame-image/print/color`);
    const [specialFrame] = useState(`${prefix}/frame-image/print/special`);

    //전송용
    const defaultSendData = {
        backgroundClsf:"",
        background:"",
        brchId:0,
        eqmtNo:"",
        dirName:"",
        prefix:"",
    }
    const [sendData, setSendData] = useState(defaultSendData);

    //전송용 선택
    const [totalBackgroundOption,setTotalBackgroundOption] = useState([]);
    //httpService 공통코드에서 가져오는 코드 구현 필요
    useEffect(() => {
        apiSvc.GetCmmnCdList({cmmnCdId:11})
        .then((res) => {
            setTotalBackgroundOption(res);
        })
        .catch((err) => {
            console.error(err);
        })
    },[]);

    // 엔터 프레임 임시 주석 처리
    // const backgroundClsfOption = [{"key":"color","value":"컬러"},{"key":"event","value":"이벤트"},{"key":"entertainment","value":"엔터"},];
    const backgroundClsfOption = [{"key":"color","value":"컬러"},{"key":"event","value":"이벤트"},];
    const [backgroundClsf, setBackgroundClsf] = useState("");

    //const backgroundColorOption = [{"key":"BLACK","value":"CBLACK"},{"key":"WHITE","value":"CWHITE"},{"key":"GRAY","value":"CGRAY"},{"key":"BLUE","value":"CBLUE"},{"key":"GREEN","value":"CGREEN"},{"key":"PURPLE","value":"CPURPLE"},{"key":"PURPLEMIX","value":"CPURPLEM"},{"key":"PURPLEOIL","value":"CPURPLEF"},{"key":"GREENMIX","value":"CGREENM"}];
    const backgroundColorOption = totalBackgroundOption.map((data) => data.cmmnCd.slice(0,1) === 'C' ? data:null).filter(data => data !== null);
    //const backgroundEventOption = [{"key":"MAGAZINE","value":"ECMAGZE"},{"key":"RAIN","value":"ESRAIN"},{"key":"ELEVATOR","value":"EHELVTR"},{"key":"SEASON","value":"ECSESN"},{"key":"TEEN1","value":"ECTEEN1"},{"key":"TEEN2","value":"ECTEEN2"},{"key":"LOCAL","value":"ECLOCAL"}];
    const backgroundEventOption = totalBackgroundOption.map((data) => data.cmmnCd.slice(0,1) === 'E' ? data:null).filter(data => data !== null);
    const [backgroundOption, setBackgroundOption] = useState("");
    const [background, setBackground] = useState("");

    const handleBackgroundClsfChange = (e) => {
        setBackgroundClsf(e.target.value);
        setSendData((prev) => ({
            ...prev,
            backgroundClsf:e.target.value
        }))
        
        if(e.target.value === 'color'){
            setBackgroundOption(backgroundColorOption);
        }else if(e.target.value === 'event'){
            setBackgroundOption(backgroundEventOption);
        }else{
            setBackgroundOption("");
        }
    };

    const handleBackgroundChange = (e) => {
        setBackground(e.target.value);
        setSendData((prev) => ({
            ...prev,
            background:e.target.value
        }))
    }

    //엔터프레임타입
    const enterFrmeTypeArr = [
        { value: "A", label: "A타입" },
        { value: "B", label: "B타입" },
        { value: "C", label: "C타입" },
    ];
    const [enterFrmeType,setEnterFrmeType] = useState('A');
    const handleEnterFrmeTypeChange = (e) => {
        console.log(e.target);
        setEnterFrmeType(e.target.value);
    }

    //가맹점
    const [sendBrchId, setSendBrchId] = useState(0);
    const handleBrchDataChange = (e) => {
        const { name, value } = e.target;
        setSendBrchId(value);
        if(value === "" || value === 0 || value === null){
            setSendData((prev) => ({
                ...prev,
                brchId:0
            }))
        } else {
            setSendData((prev) => ({
                ...prev,
                brchId:value
            }))
        }
    }
    //장치
    const [sendEqmtNo, setSendEqmtNo] = useState(null);
    const handleEqmtDataChange = (e) => {
        const { name, value } = e.target;
        setSendEqmtNo(value);
        setSendData((prev) => ({
            ...prev,
            eqmtNo:value
        }))
    }

    //모달
    const [cmmnCdModalOpen, setCmmnCdModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const openCmmnCdModal = () => {
        setCmmnCdModalOpen(true);
    }
    
    const testoutput = () => {
        console.log(sendData);
        console.log(backgroundColorOption);
    }
    
    return(
        <div className="padding-box">
            <div className="title-wrap">
                <div className="title space-between">
                    <div className="dp-flex align-cen">
                        <h2>{t("frameUpdtPage.frmeBgrdUpdt")}</h2>
                    </div>
                    <p>Home {">"} {t("frameUpdtPage.svcMng")} {">"} {t("frameUpdtPage.frmeBgrdUpdt")}</p>
                </div>
            </div>
            {/* <button onClick={testoutput}>출력</button> */}
            <DashBoardWrap>
                <div className="dashboard-box">
                    <div className="p-30">
                        <div className="table-wrap">
                            <InputTable>
                                <div className="col-2">
                                    <div className="input-table-wrap w-50">
                                        <InputTableHead>
                                            <h3>{t("frameUpdtPage.bgrdClsf")} *</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <Form.Select
                                                name={"backgroundClsf"}
                                                value={backgroundClsf}
                                                onChange={handleBackgroundClsfChange}
                                                className={""}
                                            >
                                                <option value="">선택</option>
                                                {backgroundClsfOption.map((data,idx) => (
                                                    <option key={data.key} value={data.key}>
                                                        {data.value}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </InputTableBody>
                                    </div>
                                        
                                    <div className="input-table-wrap w-50">
                                        <InputTableHead>
                                            <h3>{t("frameUpdtPage.bgrd")} *</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <Form.Select
                                                name={"background"}
                                                value={background}
                                                onChange={handleBackgroundChange}
                                                className={""}
                                            >
                                                <option value="">선택</option>
                                                {backgroundOption && backgroundOption.map((data,idx) => (
                                                    <option key={data.cmmnCd} value={data.cmmnCd}>
                                                        {data.cmmnCdNm}
                                                    </option>
                                                ))}
                                            </Form.Select>

                                            <button className="round-filled-gray-btn-modal" onClick={() => {openCmmnCdModal(); setModalTitle("프레임 배경유형 코드 추가")}}>코드 추가</button>
                                        </InputTableBody>

                                    </div>
                                </div>

                                {/* 엔터 유형 A,B,C 임시 주석처리 */}
                                {/* {
                                backgroundClsf==='entertainment'?
                                <div className="col-2">
                                    <div className="input-table-wrap w-100">
                                        <InputTableHead>
                                            <h3>엔터프레임 유형</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <CmmnRadioBox
                                                dataArray={enterFrmeTypeArr}
                                                defaultVal={enterFrmeType}
                                                tagName={"enterFrmeType"}
                                                onSelectChange={handleEnterFrmeTypeChange}
                                                className={"enter-frme-type"}
                                            />
                                        </InputTableBody>
                                    </div>
                                </div>
                                :
                                <></>
                                } */}

                                <div className="col-2">
                                    <div className="input-table-wrap w-50">
                                        <InputTableHead>
                                            <h3>{t("frameUpdtPage.brch")}</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <BrchNmSelectBox
                                                defaultVal={""}
                                                tagName={"brchId"}
                                                defaultOptTxt={"선택"}
                                                brchClsfCd={"JMC"}
                                                //frncId={27}
                                                onSelectChange={handleBrchDataChange}
                                                //disabled={brchClsfCd !== "KSC"}
                                            />
                                        </InputTableBody>
                                    </div>
                                        
                                    <div className="input-table-wrap w-50">
                                        <InputTableHead>
                                            <h3>{t("frameUpdtPage.eqmt")}</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <EqmtNoSelectBox
                                                defaultVal={""}
                                                tagName={"eqmtNo"}
                                                defaultOptTxt={"선택"}
                                                brchId={sendBrchId}
                                                onSelectChange={handleEqmtDataChange}
                                            />
                                        </InputTableBody>
                                    </div>
                                        
                                </div>

                                {/* 아이콘 */}
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.stdFrmeIcon")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"S_CF"} code={backgroundClsf==="color"?"CF":"SF"} type={background==="ECLOCAL"?"EX":"S"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrameIcon:specialFrameIcon} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.HighFrmeIcon")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"H_CF"} code={backgroundClsf==="color"?"CF":"SF"} type={background==="ECLOCAL"?"EX":"H"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrameIcon:specialFrameIcon} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.CmmnFrmeIcon")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"C_CF"} code={backgroundClsf==="color"?"CF":"SF"} type={background==="ECLOCAL"?"EX":"C"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrameIcon:specialFrameIcon} prefix={prefix}/>
                                    </InputTableBody>
                                </div>

                                {/* 프레임 */}
                                {/* 공통부분 */}
                                {/* 공통 기본 4컷 배경 */}
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.Cmmn4CutBgrd")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"C_BF1"} code={"BF1"} type={background==="ECLOCAL"?"EX":"C"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                {/* 공통 세로 4컷 배경 */}
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.CmmnVertical4CutBgrd")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"C_SF2"} code={"SF2"} type={background==="ECLOCAL"?"EX":"C"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                {/* 공통 가로 4컷 배경 */}
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.CmmnHorizontal4CutBgrd")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"C_SF4"} code={"SF4"} type={background==="ECLOCAL"?"EX":"C"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                {/* 공통 기본 5컷 배경 */}
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.CmmnHorizontal5CutBgrd")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"C_SF3"} code={"SF3"} type={background==="ECLOCAL"?"EX":"C"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                {/* 공통 세로 6컷 배경 */}
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.CmmnVertical6CutBgrd")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"C_SF5"} code={"SF5"} type={background==="ECLOCAL"?"EX":"C"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                    </InputTableBody>
                                </div>

                                {/* 스탠다드 전용 (Rain(ESRAIN))*/}
                                {background==='ESRAIN' ? 
                                    <>
                                    {/* 스탠다드 기본 4컷 배경 */}
                                    <div className="input-table-wrap">
                                        <InputTableHead>
                                            <h3>{t("frameUpdtPage.Std4CutBgrd")}</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <FrameUploadForm id={"S_BF1"} code={"BF1"} type={background==="ECLOCAL"?"EX":"S"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                        </InputTableBody>
                                    </div>
                                    {/* 스탠다드 세로 4컷 배경 */}
                                    <div className="input-table-wrap">
                                        <InputTableHead>
                                            <h3>{t("frameUpdtPage.StdVertical4CutBgrd")}</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <FrameUploadForm id={"S_SF2"} code={"SF2"} type={background==="ECLOCAL"?"EX":"S"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                        </InputTableBody>
                                    </div>
                                    {/* 스탠다드 가로 4컷 배경 */}
                                    <div className="input-table-wrap">
                                        <InputTableHead>
                                            <h3>{t("frameUpdtPage.StdHorizontal4CutBgrd")}</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <FrameUploadForm id={"S_SF4"} code={"SF4"} type={background==="ECLOCAL"?"EX":"S"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                        </InputTableBody>
                                    </div>
                                    {/* 스탠다드 세로 6컷 배경 */}
                                    <div className="input-table-wrap">
                                        <InputTableHead>
                                            <h3>{t("frameUpdtPage.StdVertical6CutBgrd")}</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <FrameUploadForm id={"S_SF5"} code={"SF5"} type={background==="ECLOCAL"?"EX":"S"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                        </InputTableBody>
                                    </div>
                                    </>
                                    :
                                    <></>
                                }

                                {/* 하이앵글 전용 (Elevator(EHELVTR)) */}
                                {background==='EHELVTR' ?
                                    <>
                                    {/* 하이앵글 기본 4컷 배경 */}
                                    <div className="input-table-wrap">
                                        <InputTableHead>
                                            <h3>{t("frameUpdtPage.High4CutBgrd")}</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <FrameUploadForm id={"H_BF1"} code={"BF1"} type={background==="ECLOCAL"?"EX":"H"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                        </InputTableBody>
                                    </div>
                                    {/* 하이앵글 가로 4컷 배경 */}
                                    <div className="input-table-wrap">
                                        <InputTableHead>
                                            <h3>{t("frameUpdtPage.HightHorizontal4CutBgrd")}</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <FrameUploadForm id={"H_SF4"} code={"SF4"} type={background==="ECLOCAL"?"EX":"H"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                        </InputTableBody>
                                    </div>
                                    {/* 하이앵글 가로 5컷 배경 */}
                                    <div className="input-table-wrap">
                                        <InputTableHead>
                                            <h3>{t("frameUpdtPage.HightHorizontal5CutBgrd")}</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <FrameUploadForm id={"H_SF3"} code={"SF3"} type={background==="ECLOCAL"?"EX":"H"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                        </InputTableBody>
                                    </div>
                                    {/* 하이앵글 세로 6컷 배경 */}
                                    <div className="input-table-wrap">
                                        <InputTableHead>
                                            <h3>{t("frameUpdtPage.HighVertical6CutBgrd")}</h3>
                                        </InputTableHead>
                                        <InputTableBody>
                                            <FrameUploadForm id={"H_SF5"} code={"SF5"} type={background==="ECLOCAL"?"EX":"H"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                        </InputTableBody>
                                    </div>
                                    
                                    </>
                                    :
                                    <></>
                                }


                                {/* 이전 양식 주석 처리
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.StdVertical4CutBgrd")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"SF2"} code={"SF2"} type={background==="ECLOCAL"?"EX":"S"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.StdHorizontal4CutBgrd")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"SF4"} code={"SF4"} type={background==="ECLOCAL"?"EX":"S"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.HighVertical4CutBgrd")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"SF1"} code={"SF1"} type={background==="ECLOCAL"?"EX":"H"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.HightHorizontal5CutBgrd")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"SF3"} code={"SF3"} type={background==="ECLOCAL"?"EX":"H"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.CmmnVertical6CutBgrd")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"SF5"} code={"SF5"} type={background==="ECLOCAL"?"EX":"C"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                 */}






                                {/* 엔터프레임 관련 주석처리 */}
                                {backgroundClsf==='entertainment' && (enterFrmeType==='A' || enterFrmeType==='B') ?
                                <>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("frameUpdtPage.Cmmn4CutBgrd")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"BF1"} code={"BF1"} type={background==="ECLOCAL"?"EX":"C"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{"Live 이미지1"}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"live1"} code={"프레임코드정보"} type={background==="ECLOCAL"?"EX":"C"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{"Live 이미지2"}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"live2"} code={"프레임코드정보"} type={background==="ECLOCAL"?"EX":"C"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{"Live 이미지3"}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"live3"} code={"프레임코드정보"} type={background==="ECLOCAL"?"EX":"C"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{"Live 이미지4"}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <FrameUploadForm id={"live4"} code={"프레임코드정보"} type={background==="ECLOCAL"?"EX":"C"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                    </InputTableBody>
                                </div>
                                </>
                                :
                                // <div className="input-table-wrap">
                                //     <InputTableHead>
                                //         <h3>{t("frameUpdtPage.Cmmn4CutBgrd")}</h3>
                                //     </InputTableHead>
                                //     <InputTableBody>
                                //         <FrameUploadForm id={"BF1"} code={"BF1"} type={background==="ECLOCAL"?"EX":"C"} sendData={sendData} dirName={backgroundClsf==='color'?colorFrame:specialFrame} prefix={prefix}/>
                                //     </InputTableBody>
                                // </div>
                                <></>
                                }
                            </InputTable>
                        </div>
                    </div>
                </div>

            </DashBoardWrap>

            <CmmnCdUploadModal
                show={cmmnCdModalOpen}
                onHide={() => {setCmmnCdModalOpen(false); window.location.replace("/svc-mgt/frame-upload")}}
                title={modalTitle}
                sendData={sendData}
            />
        </div>
    )
}