import styled from "styled-components";
import { Autocomplete, GoogleMap, MarkerF, OverlayView, useLoadScript } from '@react-google-maps/api';
import { useCallback, useEffect, useState } from "react";

const uiOption = {
    mapTypeControl: false,
    scaleControl: false,
    rotateControl: false,
    streetViewControl: false,
    styles: [
        {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }],
        }
    ]
};
const containerStyle = {
    width: '100%',
    height: '400px'
};
const ContextMenu = styled.div`
    display: inline-block;
    width: auto;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 10px;
    position: relative;
    border-radius: 5px;
    li {
        padding: 5px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        font-size: 0.8rem;
        white-space: nowrap;
        &:hover {
            background-color: #eee;
        }
        &:first-child {
            cursor: default;
        }
    }
`;
const SearchInputStyle = styled.div`
    input[type="text"] {
        box-sizing: border-box;
        border: 1px solid transparent;
        width: 100%;
        height: 40px;
        padding: 0 12px;
        border-radius: 6px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        font-size: 14px;
        outline: none;
        text-overflow: ellipses;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 28%);
    }
`;
// 렌더링 시 한번만 호출 해야 함. 함수 내부에 있을 시 여러번 호출되어 외부에서 고정된 상수로 정의
const libraries = ['places', 'geocoding'];

function GoogleMaps({ data, addrSearchTrigger, onChange, onSearchError }) {
    const googleApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    // 지도 로드
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: googleApiKey,
        libraries: libraries,
    });

    const defaultCenter = { lat: 37.5665, lng: 126.9780 };
    // photomag 오사카점: 34.66770968258979, 135.50138908110384

    const [mapCenter, setMapCenter] = useState(null); // 지도 화면의 중심
    const [marker, setMarker] = useState(null); // 마커 위치
    const [isInitial, setIsInitial] = useState(true);
    const [showContextMenu, setShowContextMenu] = useState(false); // 마커 메뉴 on/off
    const [autocomplete, setAutocomplete] = useState(null);
    const [searchInput, setSearchInput] = useState("");

    // useEffect(() => {
    //     console.log(data);
    // }, [data]);

    useEffect(() => {
        if (addrSearchTrigger && data && data.streAddr) {
            setSearchInput(data.streAddr);
            geocodeAddress(data.streAddr);
        }
    }, [addrSearchTrigger]);

    useEffect(() => {
        if (isInitial) {
            if (data?.streLocXcvl && data?.streLocYcvl) {
                const newCenter = { lat: data.streLocXcvl, lng: data.streLocYcvl };
                setMapCenter(newCenter);
                setMarker(newCenter);
                setIsInitial(false);
            } else {
                setMapCenter(defaultCenter);
            }
        }
    }, [data, isInitial]);

    const onLoad = (autocompleteInstance) => {
        setAutocomplete(autocompleteInstance);
    };

    // 자동완성으로 검색
    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                const newPosition = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                };
                setMapCenter(newPosition);
                setMarker(newPosition);
                onChange(newPosition.lat, newPosition.lng);
                setShowContextMenu(false);
            }
        } else {
            console.log("자동완성으로 검색하지 않음.");
        }
    };

    // 자동완성없이 엔터키나 버튼으로 검색
    const geocodeAddress = (address) => {
        if (isLoaded) {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ address }, (results, status) => {
                if (status === 'OK') {
                    const newPosition = {
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng()
                    };
                    setMapCenter(newPosition);
                    setMarker(newPosition);
                    onChange(newPosition.lat, newPosition.lng);
                    setShowContextMenu(false);
                } else if (status === "ZERO_RESULTS") {
                    console.log("검색결과가 없음. 다시 검색.");
                    onSearchError("streAddr", "검색된 결과가 없습니다. 다시 검색해주세요.");
                } else {
                    console.log('Geocode 실패: ' + status);
                }
            });
        }
    };

    // 엔터키로 검색
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            geocodeAddress(searchInput);
        }
    };

    // 지도 클릭 이벤트
    const handleMapClick = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        const newPosition = { lat, lng };
        setMarker(newPosition);  // 마커 위치 업데이트
        onChange(lat, lng);
        setShowContextMenu(false);
    };

    // 마커 드래그 종료 이벤트
    const handleMarkerDragEnd = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        const newPosition = { lat, lng };
        setMarker(newPosition);  // 마커 위치 업데이트
        onChange(lat, lng);
    };

    // 마커 우클릭 이벤트
    const handleMarkerRightClick = () => {
        setShowContextMenu(!showContextMenu);
    };

    // 마커의 각 메뉴 이벤트
    const handleMenuClick = (action, e) => {
        e.stopPropagation();
        if (action === "delete") {
            setMarker(null);
            setShowContextMenu(false);
            onChange(0, 0);
        }
    };

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading Maps...</div>;

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={18}
            onClick={handleMapClick}
            options={uiOption}
        >
            <Autocomplete
                onLoad={onLoad}
                onPlaceChanged={onPlaceChanged}
            >
                <SearchInputStyle>
                    <input
                        type="text"
                        placeholder="Search location"
                        name="streAddr"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                </SearchInputStyle>
            </Autocomplete>
            {marker &&
                <>
                    <MarkerF
                        position={marker}
                        onRightClick={handleMarkerRightClick}
                        draggable={true}
                        onDragStart={() => setShowContextMenu(false)}
                        onDragEnd={handleMarkerDragEnd}
                        onClick={() => setShowContextMenu(false)}
                    />
                    {showContextMenu && (
                        <OverlayView
                            position={marker}
                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        >
                            <ContextMenu>
                                <ul>
                                    <li onClick={(e) => e.stopPropagation()}>{marker.lat.toFixed(6)} {marker.lng.toFixed(6)}</li>
                                    <li onClick={(e) => handleMenuClick("delete", e)}>마커 삭제</li>
                                </ul>
                            </ContextMenu>
                        </OverlayView>
                    )}
                </>
            }
        </GoogleMap>
    );
}

export default GoogleMaps;