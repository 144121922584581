'use client'

import * as apiSvc from "api/components/cmmn/CmmnApiCallService"
import { useEffect, useState } from "react";
import styled from "styled-components"

const CheckBoxWrap = styled.ul`
    input[type="checkbox"] {
        position: relative;
        top: 1.2px;
        accent-color: #EF2929;
        width: 15px;
        height: 15px;
    }
    li {
        margin-right: 30px;
        &:last-child {
            margin: 0;
        }
    }
`;

export default function CmmnCheckBox(
    {
        className,
        tagName,
        onSelectChange,
        defaultVal,
        defaultOptTxt,
        cmmnCdId,
        filterCmmnCd,
    }
) {
    const [selectedVal, setSelectedVal] = useState(defaultVal);
    const [cmmnCdList, setCmmnCdList] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);

    useEffect(() => {
        apiSvc.GetCmmnCdList({ cmmnCdId })
            .then(res => {

                const filteredRes = filterCmmnCd && filterCmmnCd.length > 0
                    ? res.filter(data => !filterCmmnCd.includes(data.cmmnCd))
                    : res;

                setCmmnCdList(filteredRes);
                if (!defaultVal.length) {
                    setSelectedVal([]);
                } else {
                    const selectedCds = res.filter(data => defaultVal.includes(data.cmmnCd));
                    if (selectedCds.length) {
                        setSelectedVal(selectedCds.map(cd => cd.cmmnCd));
                    }
                }
                setIsAllSelected(defaultVal.length === res.length);
            })
            .catch(error => {
                console.log(error);
            });
    }, [defaultVal, cmmnCdId, filterCmmnCd]);

    const handleSelectChange = (e) => {
        const value = e.target.value;
        let newSelectedVal;

        if (value === '') {
            if (isAllSelected) {
                // 전체 해제
                newSelectedVal = [];
                setIsAllSelected(false);
            } else {
                // 전체 선택
                newSelectedVal = cmmnCdList.map(cmmn => cmmn.cmmnCd);
                setIsAllSelected(true);
            }
        } else {
            if (selectedVal.includes(value)) {
                newSelectedVal = selectedVal.filter(val => val !== value);
            } else {
                newSelectedVal = [...selectedVal, value];
            }
            setIsAllSelected(newSelectedVal.length === cmmnCdList.length);
        }

        setSelectedVal(newSelectedVal);
        const event = { target: { name: tagName, value: newSelectedVal } };
        onSelectChange(event);
    };

    return (
        <CheckBoxWrap className={className}>
            <li>
                <input
                    type="checkbox"
                    value=""
                    id={defaultOptTxt}
                    checked={isAllSelected}
                    onChange={handleSelectChange}
                />
                <label htmlFor={defaultOptTxt}>{defaultOptTxt}</label>
            </li>
            {cmmnCdList.map((cmmn) => (
                <li key={cmmn.cmmnCd}>
                    <input
                        type="checkbox"
                        value={cmmn.cmmnCd}
                        id={cmmn.cmmnCd}
                        checked={selectedVal.includes(cmmn.cmmnCd)}
                        onChange={handleSelectChange}
                    />
                    <label htmlFor={cmmn.cmmnCd}>{cmmn.cmmnCdNm}</label>
                </li>
            ))}
        </CheckBoxWrap>
    )
}