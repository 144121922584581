'use client'

import { alert } from './CmmnConfirm';
import TextFields from '../UI/TextFields';
import { useEffect, useState } from 'react';
import { callApi } from 'util/HttpService';
import { CloseButton, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import * as Yup from 'yup';
import ButtonUtil from 'components/mobile/UI/ButtonUtil';
import CmmnRadioBox from "components/mobile/cmmn/CmmnRadio";



const LargeModal = styled(Modal)`
    .modal-dialog {
        min-width: 580px ;
        width: 580px !important;    
        .modal-header {
            border-bottom: 1px solid rgba(0,0,0,.1);
            h2 {
                font-size: 18px;
                color: #3b3b3b;
                font-weight: 500;
            }
        }
        .modal-body {
            padding: 30px 0;
            h3 {
                font-size: 16px;
                color: #3b3b3b;
                text-align: center;
                line-height: 150%;
            }
            .pr-20 {
                padding-right: 20px;
            }
            .pl-20 {
                padding-left: 20px;
            }
            .input-table {
                margin-bottom: 20px;
                border-top: 2px solid #EF2929;
            }
        }
    }
    .round-solid-small-red-btn {
        margin-left:10px;
    }
    .round-filled-small-btn:hover {
        color: var(--bs-btn-hover-color) !important;
        background-color: var(--bs-btn-hover-bg) !important;
        border-color: var(--bs-btn-hover-border-color) !important;
    }
`;
const InputTableWrap = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,.1);
    .input-table-body{
        display: flex;
        align-items: flex-start;
        padding: 20px;
        width: 100%;
        h4 {
            font-size: 16px;
            color: #3b3b3b;
            font-weight: 400;
        }
    }
    .input-table-th {
        background-color: #F3F3F3;
        padding: 20px;
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 75px;
        h3 {
            font-size: 16px;
            color: #3b3b3b;
            font-weight: 500;
            line-height: 100%;
            text-align: center;
        }
    }
    .input-table-th-small{
        max-width: 138px;
        min-width: 138px;
        min-height: 95px;
    }
`;
const Table = styled.div`
    overflow-y: scroll;
    height: 500px;
    .tbody {
        display: flex;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid rgba(0,0,0,.1);
        .tb {
            background-color: #fff;
            font-size: 16px;
            text-align: center;
            font-weight: 400;
            color: #3b3b3b;
            padding: 15px 20px;
            height: 50px;
        }
        .tb-small {
            padding: 15px 20px;
            font-size: 14px;
        }
        .w-3{
            width: 30%;
        }
        .w-4{
            width: 40%;
        }
        .w-5{
            width: 50%;
        }
    }
    .thead {
        display: flex;
        align-items: center;
        width: 100%;
        position: sticky;
        top: 0;
        .th {
            background-color: #F3F3F3;
            font-size: 16px;
            text-align: center;
            font-weight: 500;
            color: #3b3b3b;
            padding: 15px 20px;
            height: 50px;
        }
        .th-small {
            padding: 15px 20px;
            font-size: 14px;
        }
        .w-3{
            width: 30%;
        }
        .w-4{
            width: 40%;
        }
        .w-5{
            width: 50%;
        }
    }
`;
const ModalBtn = styled.div`
    display: flex;
    gap: 5px;
    padding: 0 20px 20px 20px;
    padding-bottom: 0 !important;
`;
export default function CmmnCdUploadModal({show,onHide,data,title,sendData}) {
    console.log(sendData);
    
    const defaultCmmnCdInfo = {
        cmmnCdId: 11,
        cmmnCd: null,
        cmmnCdNm: null,
        cmmnCdExpl: null,
        sortSeq: 1000,
        useYn: 'Y',
        delYn: 'N',
        dupleChk: false,
    }
    const [cmmnCdInfo,setCmmnCdInfo] = useState(defaultCmmnCdInfo);
    const [cmmnCdCheck,setCmmnCdCheck] = useState(false);
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);

    // open시 reset 후 sendData에 따라 데이터 삽입
    useEffect(() => {
        setCmmnCdInfo(defaultCmmnCdInfo);
        setErrors('');    
        if(sendData.backgroundClsf==='color'){
            setCmmnCdInfo((prev) => ({
                ...prev,
                cmmnCd:'C',
                cmmnCdNm:'color-',
                cmmnCdExpl:'컬러-',
            }));
        } else if(sendData.backgroundClsf==='event'){
            setCmmnCdInfo((prev) => ({
                ...prev,
                cmmnCd:'E',
                cmmnCdNm:'event',
                cmmnCdExpl:'이벤트-',
            }));
        }
    },[show]);

    // form data 변경처리
    const handleFormDataChange = (event) => {
        const {name, value} = event.target;
        setCmmnCdInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        if(sendData.backgroundClsf==='color'){
            setCmmnCdInfo((prev) => ({
                ...prev,
                cmmnCdNm:'color'
            }));
        } else if(sendData.backgroundClsf==='event'){
            setCmmnCdInfo((prev) => ({
                ...prev,
                cmmnCdNm:'event',
            }));
        }
    },[sendData.backgroundClsf]);

    // cmmnCd변경시 중복체크 false
    useEffect(() => {
        setCmmnCdInfo((prev) => ({
            ...prev,
            dupleChk: false,
        }))
    },[cmmnCdInfo.cmmnCd])

    const checkDuplicate = async (cmmnCd) => {
        console.log(cmmnCd);
        setErrors('');    
        setCmmnCdCheck(true);
        await callApi("GET", {cmmnCd:cmmnCd}, "/web/api/svc-mgt/cmmn-cd/exists")
        .then(res => {
            console.log(res);
            //setCmmnCdCheck(true);
            if (res) {
                setCmmnCdInfo((prev) => ({
                    ...prev,
                    dupleChk: false,
                }));
                setErrors({cmmnCd:"중복된 코드입니다"});
            } else {
                setCmmnCdInfo((prev) => ({
                    ...prev,
                    dupleChk: true,
                }));
                setErrors({cmmnCd:"사용가능한 코드입니다"});
            }
            return res;
        })
    }

    // validation 설정
    const validationSchemaCdCheck = Yup.object().shape({
        cmmnCd: Yup.string().required("공통코드를 입력해주세요.")
                .test('checkDuplicate', '존재하는 코드입니다.', async (value) => {
                    if(!value) return true;
                    const isDuplicate = await checkDuplicate(value);
                    return !isDuplicate;
                }),
    })

    const validationSchema = Yup.object().shape({
        cmmnCdNm: Yup.string().required("공통코드명을 입력해주세요."),
        cmmnCdExpl: Yup.string().required("공통코드설명을 입력해주세요."),
        useYn: Yup.string().required("사용여부를 선택해주세요."),
        dupleChk: Yup.boolean().test('is-true','중복 확인 필요',(value) => value === true).required("중복확인필요"),
    });
    

    const handleCmmnBtnClick = async (btnNm) => {
        switch (btnNm) {
            case "cmmnInsertBtn":
                validationSchema.validate(cmmnCdInfo, {abortEarly:false})
                .then(() => {
                    callApi("POST", cmmnCdInfo, `/web/api/svc-mgt/cmmn-cd/insert`)
                        .then(response => {
                            if(response){
                                alert("","등록완료",() => {onHide();})
                            }
                        });
                })
                .catch((err) => {
                    const newErrors = {};
                    err.inner.forEach((error) => {
                        newErrors[error.path] = error.message;
                    });
                    setErrors(newErrors);
                })
                break;
            case "cmmnCdCheckBtn":
                try {
                    await validationSchemaCdCheck.validate(cmmnCdInfo, {abortEarly:false});
                    //checkDuplicate(cmmnCdInfo.cmmnCd);
                } catch (err) {
                    setErrors(err.message);
                } finally {
                    setCmmnCdCheck(false);
                }

                break;
            case "cmmnListBtn":
                onHide();
                break;
            case "cmmnResetBtn":
                break;
            default: break;
        }
    }


    return (
        <LargeModal show={show} onHide={onHide} centered>
            <Modal.Header>
                <h2>프레임배경유형 코드 추가</h2>
                <CloseButton onClick={onHide} />
            </Modal.Header>
            <Modal.Body>
                <div className="pr-20 pl-20">
                    <div className="input-table">
                        <InputTableWrap>
                            <div className="input-table-th input-table-th-small">
                                <h3>공통코드</h3>
                            </div>
                            <div className="input-table-body">
                                <div style={{display:"flex",flexDirection:'column'}}>
                                <TextFields
                                    type="text"
                                    onChangeValue={handleFormDataChange}
                                    value={cmmnCdInfo?.cmmnCd || ""}
                                    name="cmmnCd"
                                    //errorTxt={errors.cmmnCd}
                                />
                                {errors.dupleChk && <p style={{ color: 'red' ,margin:'5px'}}>{errors.dupleChk}</p>} 
                                {errors.cmmnCd && <p style={{ color: 'red' ,margin:'5px'}}>{errors.cmmnCd}</p>} 
                                </div>
                                <ButtonUtil className="btn btn-outline-danger round-solid-small-red-btn small-btn"
                                                    value={"중복확인"}
                                                    onClick={() => handleCmmnBtnClick("cmmnCdCheckBtn")}
                                />
                            </div>
                        </InputTableWrap>
                        <InputTableWrap>
                            <div className="input-table-th input-table-th-small">
                                <h3>공통코드명</h3>
                            </div>
                            <div className="input-table-body">
                                <TextFields
                                    type="text"
                                    onChangeValue={handleFormDataChange}
                                    value={cmmnCdInfo?.cmmnCdNm || ""}
                                    name="cmmnCdNm"
                                    errorTxt={errors.cmmnCdNm}
                                />
                            </div>
                        </InputTableWrap>
                        <InputTableWrap>
                            <div className="input-table-th input-table-th-small">
                                <h3>공통코드설명</h3>
                            </div>
                            <div className="input-table-body">
                                <TextFields
                                    type="text"
                                    onChangeValue={handleFormDataChange}
                                    value={cmmnCdInfo?.cmmnCdExpl || ""}
                                    name="cmmnCdExpl"
                                    errorTxt={errors.cmmnCdExpl}
                                />
                            </div>
                        </InputTableWrap>
                        
                    </div>

                </div>
                <ModalBtn>
                    <ButtonUtil className="btn btn-danger round-filled-small-btn small-btn"
                                value={"등록"}
                                onClick={() => {handleCmmnBtnClick("cmmnInsertBtn")}}
                    />
                    <ButtonUtil className="btn btn-danger round-filled-small-btn"
                        value={"닫기"}
                        onClick={onHide}
                    />
                </ModalBtn>
            </Modal.Body>
        </LargeModal>
    );
}