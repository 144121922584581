'use client'

import * as apiSvc from "api/components/cmmn/CmmnApiCallService"
import { useEffect, useState } from "react"
import { Form } from "react-bootstrap";
import styled from "styled-components";

const ErrorMsg = styled.p`
    margin-left: 1rem;
    font-size: 0.9rem;
    color: #ef2929;
`;
export default function BrchNmSelectBox(
    {
        defaultOptTxt
        , defaultVal
        , tagName
        , onSelectChange
        , className
        , disabled
        , errorTxt
        , brchClsfCd
        , frncId
        , brchId
    }
) {

    const [selectedVal, setSelectedVal] = useState(defaultOptTxt);
    const [brchAllList, setBrchAllList] = useState([]);

    useEffect(() => {
        apiSvc.GetBrchList({ brchClsfCd, frncId, brchId })
            .then(res => {
                setBrchAllList(res);
                let initialValue = '';
                if (brchId) {
                    initialValue = res[0]?.brchId || '';
                } else if (defaultVal) {
                    const selectedAthr = res.find(data => data.brchId == defaultVal);
                    initialValue = selectedAthr ? selectedAthr.brchId : '';
                }
    
                setSelectedVal(initialValue);
    
                // 초기값을 부모 컴포넌트로 전달
                if (initialValue) {
                    const event = { target: { name: tagName, value: initialValue } };
                    onSelectChange(event);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }, [defaultVal]);

    const handleSelectChange = (e) => {
        const selectedVal = e.target.value;
        setSelectedVal(selectedVal);
        const event = { target: { name: tagName, value: selectedVal } };
        onSelectChange(event);
    };


    return (
        <>
            <Form.Select
                name={tagName}
                value={selectedVal}
                onChange={handleSelectChange}
                disabled={disabled}
                className={className}
            >
                <option value="">{defaultOptTxt}</option>
                {brchAllList.map((brch) => (
                    <option key={brch.brchId} value={brch.brchId}>
                        {brch.brchNm}
                    </option>
                ))}
            </Form.Select>
            {errorTxt && <ErrorMsg>{errorTxt}</ErrorMsg>}
        </>
    );

}