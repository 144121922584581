import { useEffect, useState } from "react";
import TextFields from "../UI/TextFields";
import styled from "styled-components";

const FormWrap = styled.div`
    display: flex;
    align-items: center;
    label {
        font-size: 14px;
        color: #fff;
        border-radius: 80px;
        background-color: ${({ theme }) => theme.colors.red};
        width: 20%;
        min-width: fit-content;
        padding: 10px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
    }
    .round-filled-small-btn:hover {
        background-color: #C90000;
    }
`;
const ErrorMsg = styled.p`
    margin-left: 1rem;
    font-size: 0.9rem;
    color: #ef2929;
`;

export default function ImgUploadForm({ id, onFileInfoUpdate, data, t, errorTxt, name }) {
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        if (data) {
            if (name === 'mainStreImgFileNm' && data.mainStreImgFileNm) {
                setFileName(data.mainStreImgFileNm);
            } else if (name === 'imgFileNm' && data.imgFileNm) {
                setFileName(data.imgFileNm);
            }
        }
    }, [data, name]);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFileName(selectedFile.name);
            onFileInfoUpdate(selectedFile);
        } else {
            setFileName("");
            onFileInfoUpdate(null);
        }
    };

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <FormWrap>
                <TextFields
                    type="text"
                    value={fileName}
                    disabled={true}
                    placeholder='No file chosen'
                    className="readonly"
                    name={name}
                />
                <label htmlFor={id} className="btn btn-danger round-filled-small-btn">
                    {t("frameUpdtPage.fileChoose")}
                </label>
                <input
                    type="file"
                    id={id}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
            </FormWrap>
            {errorTxt && <ErrorMsg>{errorTxt}</ErrorMsg>}
        </div>
    )
}