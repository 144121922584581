import { CategoryScale, Chart as ChartJS, Legend, LineController, LineElement, LinearScale, PointElement, Title, Tooltip } from "chart.js";
import moment from "moment";
import { Line } from 'react-chartjs-2';
import styled from "styled-components";
import { useTranslation } from "react-i18next";

ChartJS.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend);

const ChartBox = styled.div`
    width: 100%;
    height: 250px;
    // padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

function EqmtByDailyChart({ salesData, dateArray, title, label, crdInfo }) {
    const {t} = useTranslation();
    let formattedDateArray;

    switch (title) {
        case t("saleHistPage.daily"):
            formattedDateArray = dateArray.map(date => moment(date, 'YYYY-MM-DD').format('MM-DD'));
        break;
        case t("saleHistPage.monthly"):
            formattedDateArray = dateArray.map(date => moment(date, 'YYYY-MM-DD').format('YY-MM'));
        break;
        default:break;
    }

    const chartData = {
        labels: formattedDateArray,
        datasets: [
            {
                label: `${label}`,
                data: salesData,
                fill: false,
                borderColor: '#006DB2',
                tension: 0.1,
                pointRadius: 5,
            },
        ],
    }

    // const emptyDataPlugin = {
    //     id: 'emptyDataPlugin',
    //     beforeDraw: (chart) => {
    //         const ctx = chart.ctx;
    //         const data = chart.data.datasets[0].data;

    //         if (data.every(point => point === 0)) {
    //             const width = chart.width;
    //             const height = chart.height;
    //             chart.clear();

    //             ctx.save();
    //             ctx.textAlign = 'center';
    //             ctx.textBaseline = 'middle';
    //             ctx.fillStyle = '#999';
    //             ctx.font = '20px Arial';
    //             ctx.fillText('No Data', width / 2, height / 2);
    //             ctx.restore();
    //         }
    //     }
    // };

    const options = {
        responsive: true,
        maintainAspectRatio: false, //부모의 크기에 맞춤
        scales: {
            x: {
                ticks: {
                    font: {
                        size: 10
                    },
                }
            },
            y: {
                beginAtZero: true // Y축이 0에서 시작하도록 설정
            }
        },
        plugins: {
            legend: {
                display: false, // 범례 숨김
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        label += `${crdInfo && crdInfo.natnCd === "KR" ? "₩" : "¥"} ${context.raw.toLocaleString()}`;
                        return label;
                    },
                },
            },
        },
    };

    return (
        <ChartBox>
            <Line options={options} data={chartData} /*plugins={[emptyDataPlugin]}*/ />
        </ChartBox>
    )
}
export default EqmtByDailyChart;
