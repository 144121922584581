import styled from "styled-components";
import TitleLogo from "asset/photomag_web/img/icon/filled/insert-emoticon.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import { alert, confirm } from "components/web/cmmn/CmmnConfirm";
import { callApi } from "util/HttpService";
import TextFields from "components/web/UI/TextFields";
import CmmnCheckBox from "components/web/cmmn/CmmnCheckBox";
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import { useEffect } from "react";
import { getFormattedValue } from "util/CmmnUtils";
import { useBrch } from "components/web/auth/BrchProvider";

const DashBoardWrap = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 0 0;
    .dashboard-box {
        background-color: #fff;
        border-radius: 16px;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
    }
    .round-filled-small-btn {
        background-color: ${({ theme }) => theme.colors.red} !important;
        &:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
    input[type="checkbox"] {
        accent-color: #EF2929;
    }
    @media (max-width: 1600px) {
        .th, .tb {
            font-size: 14px !important;
        }
    }
    @media (min-width: 1601px) {
        .th, .tb {
            font-size: 16px !important;
        }
    }
`;
const InputOptionWrap = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .wrap {
        display: flex;
        align-items: center;
        &:first-child {
            margin-bottom: 20px;
        }
        li {
            display: flex;
            align-items: center;
            h3 {
                font-size: 16px;
                color: #3b3b3b;
                font-weight: 500;
                min-width: 100px;
            }
            label {
                font-size: 16px;
                color: #3b3b3b;
                font-weight: 400;
                margin-left: 10px;
            }
            select {
                min-width: 250px;
            }
        }
        .tel {
            h3 {
                min-width: 146px;
            }
        }
    }
    & > ul {
        width: fit-content;
    }
    .btn {
        max-width: 150px;
        &:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
`;
const TableTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    h2 {
        font-size: 18px;
        color: #3b3b3b;
        font-weight: 500;
    }
    .table-title-btn-wrap {
        display: flex;
        align-items: center;
        .btn {
            width: 60px !important;
            &:first-child {
                margin-right: 10px;
            }
        }
    }
`;
const TableWrap = styled.div`
    h2 {
        font-size: 18px;
        color: #3b3b3b;
        font-weight: 500;
        margin-bottom: 15px;
    }
    .round-filled-gray-btn:hover {
        color: var(--bs-btn-hover-color) !important;
    }
    .round-filled-gray-btn {
        font-size: 14px !important;
    }
    .thead {
        position: sticky;
        top: 0;
        z-index: 10;
    }
    .tbody:hover .tb {
        background-color: #fafafa !important;
    }
    .tbody:active .tb, .tbody:focus .tb {
        background-color: #f5f5f5 !important;
    }
    .tb {
        padding: 15px 0px !important;         
    }
    .round-filled-small-btn {
        width: 0%;
        height: 30px;
        margin: 0 !important;
        &:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
    .download-btn {
        width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
            font-size: 14px;
            padding: 8px;
        }
    }
`;
const TbodyStyle = styled.div`
    .tb {
        color: ${props => props.$iqryYn === "Y" && "#A9A9A9 !important"};
    }
`;

export default function IqryMgt() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { iqryYnStat, setIqryYnStat } = useBrch();

    const defaultSearchData = {
        iqryTypeCd: [],
        iqrrNm: "",
    };

    const [searchData, setSearchData] = useState(defaultSearchData);
    const [iqryList, setIqryList] = useState([]);
    const [selectedIqrySqno, setSelectedIqrySqno] = useState([]);
    const [isDownload, setIsDownload] = useState(false);

    const getIqryList = () => {
        const params = { ...searchData, iqryTypeCd: searchData.iqryTypeCd.join(",") };
        callApi("GET", params, "/web/api/svc-mgt/iqry/list")
            .then(res => {
                // console.log(res);
                setIqryList(res);
            })
            .catch(err => {
                console.log(err);
            })
    };

    useEffect(() => {
        getIqryList();
    }, []);

    const handleFormDataChange = (event) => {
        const { name, value } = event.target;
        setSearchData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        console.log(name, value);

    };

    const handleCheckBoxChange = (event) => {
        const { value } = event.target;
        setSearchData((prevData) => ({
            ...prevData,
            iqryTypeCd: value
        }));
    };

    const handleCmmnBtnClick = (btnNm) => {
        switch (btnNm) {
            case "cmmnSearchBtn":
                getIqryList();
                setSelectedIqrySqno([]);
                break;
            case "cmmnDeleteBtn":
                const iqrySqnoList = selectedIqrySqno.join(",");
                // console.log(pstsSqnoList);
                if (iqrySqnoList.length > 0) {
                    confirm("", t("alert.deleteConfirm"), () => {
                        callApi("GET", { iqrySqnoList: iqrySqnoList }, "/web/api/svc-mgt/iqry/delete")
                            .then(res => {
                                alert("", t("alert.delete"), () => { getIqryList() });
                            })
                            .catch(err => {
                                alert("", t("alert.deleteError"), () => { });
                                console.log(err);
                            });
                    })
                } else {
                    alert("", t("alert.deleteNumCheck"), () => { return });
                    console.log("No branches selected for deletion.");
                }
                break;
            default: break;
        }
    };

    const onCheckedElement = useCallback((checked, iqrySqno) => {
        if (checked) {
            setSelectedIqrySqno(prevData => [...prevData, iqrySqno]);
        } else {
            setSelectedIqrySqno(prevData => prevData.filter((id) => id !== iqrySqno));
        }
    }, []);

    const onRowDoubleClicked = (data) => {
        if (data && data.iqryYn === "N") {
            callApi("POST", null, `/web/api/svc-mgt/iqry/yn-change?iqrySqno=${data.iqrySqno}`)
            .then(res => {
                setIqryYnStat(!iqryYnStat);
            })
            .catch(err => {
                console.log(err);
            })
        }
        navigate(`/svc-mgt/iqry-mgt/${data.iqrySqno}`)
    };

    const fileDownloadClick = async (fileName, dirName) => {
        const res = await callApi("GET", null, `/web/api/svc-mgt/iqry/download?fileName=${fileName}&dirName=${dirName}`);

        if (res) {
            const response = await fetch(res);
            console.log("response :", response);

            if (!response.ok) {
                throw new Error("파일을 불러오는데 실패했습니다.");
            }
            const blob = await response.blob();
            const fileUrl = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            // 다운로드 후 링크 제거
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(fileUrl);
            setIsDownload(true);
        } else {
            console.log("다운로드 실패");
            setIsDownload(false);
        }
    };

    return (
        <div className="padding-box">
            <div className="title-wrap">
                <div className="title space-between">
                    <div className="dp-flex align-cen">
                        <img src={TitleLogo} alt="문의정보" />
                        <h2>문의정보</h2>
                    </div>
                    <p>Home {">"} {t("leftMenu.svcMng")} {">"} 문의정보</p>
                </div>
            </div>
            <DashBoardWrap>
                <div className="dashboard-box p-30">
                    <InputOptionWrap>
                        <ul>
                            <ul className="wrap">
                                <li className="mr-50">
                                    <h3>구분</h3>
                                    <CmmnCheckBox
                                        cmmnCdId={18}
                                        defaultOptTxt={t("brchMgtPage.entire")}
                                        defaultVal={searchData.iqryTypeCd}
                                        tagName="iqryTypeCd"
                                        className="wrap"
                                        onSelectChange={handleCheckBoxChange}
                                    />
                                </li>
                            </ul>
                            <ul className="wrap">
                                <li>
                                    <h3>성명</h3>
                                    <TextFields
                                        type="text"
                                        value={searchData.iqrrNm}
                                        name="iqrrNm"
                                        onChangeValue={handleFormDataChange}
                                    />
                                </li>
                            </ul>
                        </ul>
                        <ButtonUtil className="btn btn-danger round-filled-small-btn small-btn"
                            value={t("common.searchLable")}
                            onClick={() => { handleCmmnBtnClick("cmmnSearchBtn") }}
                        />
                    </InputOptionWrap>
                </div>
                <div className="dashboard-box">
                    <TableTitle>
                        <h2>{t("brchMgtPage.total")} {iqryList.length}{t("dashboardPage.occur")}</h2>
                        <div className="table-title-btn-wrap">
                            <ButtonUtil className="btn btn-danger round-filled-small-btn small-btn mr-5"
                                value={t("common.delLable")}
                                onClick={() => { handleCmmnBtnClick("cmmnDeleteBtn") }}
                            />
                        </div>
                    </TableTitle>
                    <div className="p-30">
                        <TableWrap>
                            <div className="table scroll-wrap h-10">
                                <div className="thead">
                                    <div className="th w-05"></div>
                                    <div className="th w-05">{t("pstsMgtPage.index")}</div>
                                    <div className="th w-1">성명</div>
                                    <div className="th w-1">연락처</div>
                                    <div className="th w-2">이메일</div>
                                    <div className="th w-1">구분</div>
                                    <div className="th w-15">기업명</div>
                                    <div className="th w-1">첨부파일</div>
                                    <div className="th w-15">{t("pstsMgtPage.regDttm")}</div>
                                </div>
                                <div className="tbody-wrap">
                                    {iqryList.map((data, idx) => (
                                        <TbodyStyle className="tbody"
                                            key={data.iqrySqno}
                                            onClick={() => onCheckedElement(!selectedIqrySqno.includes(data.iqrySqno), data.iqrySqno)}
                                            onDoubleClick={() => { onRowDoubleClicked(data) }}
                                            $iqryYn={data.iqryYn}
                                        >
                                            <div className="tb w-05">
                                                <input type="checkbox"
                                                    name="iqrySqno"
                                                    value={data.iqrySqno}
                                                    onChange={(e) => onCheckedElement(e.target.checked, data.iqrySqno)}
                                                    checked={selectedIqrySqno.includes(data.iqrySqno) ? true : false}
                                                />
                                            </div>
                                            <div className="tb w-05">{idx + 1}</div>
                                            <div className="tb w-1">{data.iqrrNm}</div>
                                            <div className="tb w-1">{getFormattedValue(data.iqrrTelNo)}</div>
                                            <div className="tb w-2">{data.iqrrEmalAddr}</div>
                                            <div className="tb w-1">{data.iqryTypeCdNm}</div>
                                            <div className="tb w-15">{data.coNm !== null && data.coNm !== undefined ? data.coNm : "-"}</div>
                                            <div className="tb w-1">
                                                {data.upldFileNm ?
                                                    <div className="download-btn">
                                                        <ButtonUtil className="btn btn-danger round-filled-small-btn"
                                                            value={"다운로드"}
                                                            onClick={() => fileDownloadClick(data.upldFileNm, data.upldPathAddr)}
                                                        />
                                                    </div>
                                                    :
                                                    "-"
                                                }
                                            </div>
                                            <div className="tb w-15">{data.convRegDttm}</div>
                                        </TbodyStyle>
                                    ))}
                                </div>
                            </div>
                        </TableWrap>
                    </div>
                </div>
            </DashBoardWrap>
        </div>
    )
}