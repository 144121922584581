import React,{ useEffect, useRef, useState } from "react";
import styled from "styled-components"
import { library } from "@fortawesome/fontawesome-svg-core";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonUtil from "../UI/ButtonUtil";

import { callApi } from "util/HttpService";
import { alert } from "components/web/cmmn/CmmnConfirm";
import InputBox from "../UI/InputBox";
import PayPalButtonWrapper from 'components/common/PayPalButtonsWapper';

const ModalWrap = styled.div`
    background-color: #fff;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 6;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    // transition: opacity 0.6s, transform 0.6s, visibility 0.6s;
    // opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    // transform: ${({ isVisible }) => (isVisible ? 'translateY(0)' : 'translateY(100%)')};
    // visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;
const ModalHeader = styled.div`
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray7};
    h2 {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.dark2};
        font-weight: 500;
    }
    button {
        background-color: #fff;
        svg {
            font-size: 16px;
            color: ${({ theme }) => theme.colors.dark2};
        }
    }
`;
const ModalContent = styled.div`
    padding: 20px;
    h3 {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.dark2};
        font-weight: bold;
        width: 35%;
    }
    & > div {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:last-of-type {
            margin: 0;
        }
        & > div {
            display: flex;
            align-items: center;
        }
    }
    h4 {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.dark2};
        font-weight: 400;
        width: 70%;
    }
    h5 {
        font-size: 14px;
        color: ${({ theme }) => theme.colors.dark2};
        margin-left: 5px;
    }
    .input_box:last-of-type {
        margin: 0 !important;
    }
    .crd-chrg-warp{
        display : flex;
        flex-direction : column;
        justify-content: space-between;
        gap:10px;
        width:65vw

    }
    .crd-btn-warp{
        display : flex;
        width : 100%;
        justify-content: space-between;
        gap:10px;
    }
    .dp-flex {
        display: flex;
    }
    .align-cen{
        align-items: center;
    }
`;
const ModalBtnWrap = styled.div`
    padding: 0 20px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 48% !important;
    }
`;
const DropBackground = styled.div`
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,.4);
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
`;

export default function CmmnCreditModal({ 
    crdInfo, 
    setIsCrdModal, 
    t, 
    getBrchCrdInfo,
    stat,
    setStat,
    crdStat,
}) {
    console.log(crdStat);
    

    library.add(faXmark);
    const defaultBrchInfo = {
        brchId: crdInfo.brchId,
        flngCrdCnt: 0,
        stlmId:'',
        errorMsg:''
    }
    const [searchData, setSearchData] = useState(defaultBrchInfo);
    // const [isVisible, setIsVisible] = useState(false);
    const gnbRef = useRef(null);

    const [isPaymentHandled, setIsPaymentHandled] = useState(false);

    const handlePaymentSuccess = (stlmId) => {
        if (isPaymentHandled) return; // Prevent multiple calls
        setIsPaymentHandled(true); // Set flag to true after success
        console.log('handlePaymentSuccess stlmId : ',stlmId);
        setSearchData((prevData) => ({
            ...prevData,
            stlmId: stlmId,
        }));
        handleCmmnBtnClick("cmmnInsertBtn");

        // Reset flag if needed after processing
    };

    const handlePaymentFail = (errorMsg) =>{
        setSearchData((prevData) => ({
            ...prevData,
            errorMsg: errorMsg,
        }));
        handleCmmnBtnClick("cmmnPaymentFailHstInsertBtn");
    }

    // useEffect(() => {
    //     setIsVisible(true);
    // }, []);
    // const closeModal = () => {
    //     setIsVisible(false);
    // };
    // const handleTransitionEnd = () => {
    //     if (!isVisible) {
    //         setIsCrdModal(false);
    //     }
    // };

    // const handleFormDataChange = (e) => {
    //     const { name, value } = e.target;
    //     const formattedValue = value.replace(/[^0-9,]/g, ""); //숫자와 콤마만 허용
    //     const numericValue = formattedValue.replace(/,/g, ""); // 콤마 제거하고 숫자만 남기기
    //     const formattedNumber = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");// 세 자리마다 콤마 찍기
    //     setSearchData((prevData) => ({
    //         ...prevData,
    //         [name]: formattedNumber,
    //     }));
    // };

    const handleButtonClick = (incrementValue) => {
        setSearchData(prevData => ({
            ...prevData,
            flngCrdCnt: prevData.flngCrdCnt + incrementValue
        }));
    };

    const handleCmmnBtnClick = (btnNm) => {
        switch (btnNm) {
            case "cmmnInsertBtn":
                setSearchData(prevData => {
                    const updatedData = { 
                        ...prevData,
                        flngCrdCnt: prevData.flngCrdCnt // 또는 필요에 따라 다른 계산을 할 수 있습니다.
                        ,stlmId:prevData.stlmId
                    };
                    
                    // 상태 업데이트 후 최신 값으로 API 호출
                    console.log('insert flngCrdCnt : ', updatedData.flngCrdCnt);
                    console.log('handleCmmnBtnClick stlmId : ',updatedData.stlmId);
                    const multiplier = crdInfo.natnCd === "KR" ? 100 : crdInfo.natnCd === "JP" ? 10 : 1;
                    const apiBody = {
                        brchId:crdInfo.brchId
                        ,stlmAmt:(updatedData.flngCrdCnt*multiplier)
                        ,flngCrdCnt:updatedData.flngCrdCnt
                        ,rsltCd:'SUCCESS'
                        ,rsltMsgCn:''
                        ,stlmId:updatedData.stlmId
                    }
                    callApi("POST", apiBody, `/web/api/svc-mgt/brch/crd-insert`)
                        .then(res => {
                            alert("", t("m_alert.crdPmtCpt"), () => { 
                                setIsCrdModal(false); 
                                getBrchCrdInfo();
                                setStat(!stat);
                            });
                        })
                        .catch(error => {
                            console.log(error);
                            alert("", t("m_alert.crdPmtFail"), () => { });
                        });
                        return updatedData; // 상태 업데이트가 완료된 최신 데이터 반환
                });
                break;
            case "cmmnPaymentFailHstInsertBtn":
                setSearchData(prevData => {
                    const updatedData = { 
                        ...prevData,
                        flngCrdCnt: prevData.flngCrdCnt // 또는 필요에 따라 다른 계산을 할 수 있습니다.
                        ,stlmId:prevData.stlmId
                        ,errorMsg:prevData.errorMsg
                    };
                    
                    // 상태 업데이트 후 최신 값으로 API 호출
                    console.log('insert flngCrdCnt : ', updatedData.flngCrdCnt);
                    const multiplier = crdInfo.natnCd === "KR" ? 100 : crdInfo.natnCd === "JP" ? 10 : 1;
                    const apiBody = {
                        brchId:crdInfo.brchId
                        ,stlmAmt:(updatedData.flngCrdCnt*multiplier)
                        ,rsltCd:'FAIL'
                        ,rsltMsgCn:updatedData.errorMsg
                    }
                    //const formattedFlngCrdCnt = updatedData.flngCrdCnt.replace(/,/g, '');
                    callApi("POST", apiBody, `/web/api/svc-mgt/brch/crd-payment-fail-hst-insert`)
                        .then(res => {
                            alert("", t("m_alert.crdPmtFail")+` : ${updatedData.errorMsg} `, () => {                                 
                            });
                        })
                        .catch(error => {
                            console.log(error);
                            alert("", t("m_alert.crdPmtFail"), () => { });
                        });
                        return updatedData; // 상태 업데이트가 완료된 최신 데이터 반환
                });
                break;
            case "cmmnResetBtn":
                setSearchData(defaultBrchInfo);
                break;
            default: break;
        }
    }
    const handleClickOutside = (event) => {
        if (gnbRef.current && !gnbRef.current.contains(event.target)) {
            setIsCrdModal(false);
            // closeModal();
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const formatNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
        <>
            <ModalWrap ref={gnbRef} /* isVisible={isVisible} onTransitionEnd={handleTransitionEnd} */>
                <ModalHeader>
                    <h2>{t("m_gnb.creditCharge")}</h2>
                    <ButtonUtil className="close-btn bottom-modal-close-btn"
                        onClick={() => setIsCrdModal(false)}>
                        <FontAwesomeIcon icon={faXmark} className="close-i" color="black" />
                    </ButtonUtil>
                </ModalHeader>
                <ModalContent>
                    <div>
                        <h3>{t("m_deviceMgtPage.brch")}</h3>
                        <h4>{crdInfo.brchNm}({t("m_gnb.eqmtCount")} {crdInfo.eqmtCount}{t("m_gnb.unit")})</h4>
                    </div>
                    <div>
                        <h3>{t("m_gnb.creditHeld")}</h3>
                        <div>
                            <InputBox
                                type="text"
                                className="input_box"
                                name="possCrdCnt"
                                value={crdStat && crdStat[0] != null ? crdStat[0].toLocaleString() : "0"}
                                disabled={true}
                            />
                            <h5>Creidts</h5>
                        </div>
                    </div>
                    <div>
                        <h3>{t("m_gnb.chargeCredit")}</h3>
                        
                        <div className='crd-chrg-warp'>
                                    <div className='crd-btn-warp'>
                                        <ButtonUtil className="btn btn-outline-danger round-solid-small-red-btn small-btn max-content"
                                            value={"10"}
                                            onClick={() => { handleButtonClick(10) }}
                                        />
                                       
                                        <ButtonUtil className="btn btn-outline-danger round-solid-small-red-btn small-btn max-content"
                                            value={"10,000"}
                                            onClick={() => { handleButtonClick(10000) }}
                                        />
                                        <ButtonUtil className="btn btn-danger round-filled-small-btn small-btn mr-5"
                                            value={"Reset"}
                                            onClick={() => { handleCmmnBtnClick("cmmnResetBtn") }}
                                        />
                                    </div>
                                    <InputBox
                                        type="text"
                                        className="input_box readonly"
                                        name="flngCrdCnt"
                                        value={formatNumber(searchData.flngCrdCnt)}                                        
                                        disabled={true}
                                        //onChangeValue={handleFormDataChange}
                                    />
                            <h5>
                            Creidts{crdInfo && (() => {
                                const currencySymbol = crdInfo.natnCd === "KR" ? "₩" : crdInfo.natnCd === "JP" ? "¥" : "";
                                const multiplier = crdInfo.natnCd === "KR" ? 100 : crdInfo.natnCd === "JP" ? 10 : 1;
                                const amount = searchData.flngCrdCnt && searchData.flngCrdCnt !== undefined ? (searchData.flngCrdCnt * multiplier).toLocaleString() : 0;
                                
                                return `(${currencySymbol}${amount})`;
                            })()}
                            </h5>
                        </div>
                        
                    </div>
                </ModalContent>
                <ModalBtnWrap>
                    <ButtonUtil className="btn btn-secondary round-filled-gray-btn bottom-modal-close-btn"
                        value={t("m_gnb.cancel")}
                        onClick={() => setIsCrdModal(false)}
                    />
                    <PayPalButtonWrapper 
                        payAmount={searchData.flngCrdCnt*10}
                        handlePaymentSuccess={handlePaymentSuccess}
                        paypalBtnheight={55}
                        handlePaymentFail={handlePaymentFail}
                    />
                    {/* <ButtonUtil className="btn btn-danger round-filled-btn bottom-modal-close-btn"
                        value={t("m_saleHistPage.payment")}
                        onClick={() => { handleCmmnBtnClick("cmmnInsertBtn") }}
                    /> */}
                </ModalBtnWrap>
            </ModalWrap>
            <DropBackground />
            {/* {isVisible && <DropBackground />} */}
        </>
    )

}