import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { useTranslation } from "react-i18next";

import monetizationOnB from "asset/img/icon/filled/monetization-on_b.svg";
import dynamicFeedB from "asset/img/icon/filled/dynamic-feed_b.svg";
import photoLibraryB from "asset/img/icon/filled/photo-library_b.svg";
import stars from "asset/photomag_web/img/icon/filled/stars.svg";


const StatBox = styled.div`
  .dp-flex {
    display: flex;
  }
  .space-between {
    justify-content: space-between;
  }
  .align-cen {
    align-items: center;
  }
  .flex-end {
    justify-content: flex-end;
  }
  .sub-title-wrap h3 {
    font-size: 16px;
    color: #3b3b3b;
    font-weight: 500;
    margin-left: 5px;
  }
  .sub-title-wrap h4 {
    font-size: 14px;
    color: #3b3b3b;
    font-weight: 500;
    margin-left: 5px;
  }
  .sub-box h2 {
    font-size: 28px;
    color: #ef2929;
    font-weight: bold;
  }
  .box-wrap li {
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 16px;
    width: 32%;
  }
  .box-wrap li h2 {
    font-size: 36px;
    color: #ef2929;
    font-weight: bold;
    margin-top: 15px;
  }
`;

const AnimatedNumber = ({ value }) => {
  const prevValue = useRef(value);

  const props = useSpring({
    from: { number: prevValue.current },
    to: { number: value },
    config: { duration: 1000 },
  });

  useEffect(() => {
    prevValue.current = value;
  }, [value]);

  const formatNumber = (n) => {
    return new Intl.NumberFormat("ko-KR", {}).format(n);
  };

  return (
    <animated.span>
      {props.number.to
        ? props.number.to((n) => formatNumber(n.toFixed(0)))
        : formatNumber(value)}
    </animated.span>
  );
};

export default function SaleStat({ salStat ,source, crdInfo }) {
  const {t} = useTranslation();
  return (
    <StatBox>
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="tab1_content1" role="tabpanel" aria-labelledby="tab1_content1">
          <ul className="dp-flex space-between align-cen box-wrap">
            <li>
              <div className="dp-flex align-cen sub-title-wrap">
                <img src={monetizationOnB} alt="monetizaiont-on_b" />
                <h4>{t("dashboardPage.sale")}</h4>
              </div>
              <h2>{crdInfo && crdInfo.natnCd === "KR" ? "₩" : "¥"} {source === 'click' ? Intl.NumberFormat("ko-KR", {}).format(salStat.totStlmAmt) : <AnimatedNumber value={salStat.totStlmAmt} />}</h2>
            </li>
            <li>
              <div className="dp-flex align-cen sub-title-wrap">
                <img src={dynamicFeedB} alt="dynamic-feed_b" />
                <h4>{t("dashboardPage.numberOfPay")}</h4>
              </div>
              <h2>{source === 'click' ? Intl.NumberFormat("ko-KR", {}).format(salStat.totStlmCnt) : <AnimatedNumber value={salStat.totStlmCnt} />}{t("dashboardPage.occur")}</h2>
            </li>
            <li>
              <div className="dp-flex align-cen sub-title-wrap">
                <img src={photoLibraryB} alt="pt-library_b" />
                <h4>{t("dashboardPage.numberOfPrint")}</h4>
              </div>
              <h2>{source === 'click' ? Intl.NumberFormat("ko-KR", {}).format(salStat.totPhtQty) : <AnimatedNumber value={salStat.totPhtQty} />}{t("dashboardPage.page")}</h2>
            </li>
            {/* <li>
              <div className="dp-flex align-cen sub-title-wrap">
                <img src={stars} width="15px" alt="stars.svg" />
                <h4>보유 크래딧</h4>
              </div>
              <h2>{<AnimatedNumber value={salStat.possCrdCnt} />}</h2>
            </li> */}
          </ul>
        </div>
      </div>
    </StatBox>
  );
}
