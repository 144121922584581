'use client'

import ButtonUtil from "components/mobile/UI/ButtonUtil";
import CustomDatePicker from "components/mobile/UI/DatePicker";
import { useWindowSize } from "context/WindowSizeContext";
import { useEffect, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap"
import styled from "styled-components"
import { getFormattedDate } from "util/DateSearchUtils";
import { callApi } from "util/HttpService";
import { useTranslation } from "react-i18next";
import { formatNumber } from "util/CmmnUtils";

const LargeModal = styled(Modal)`
    .modal-dialog {
        min-width: 580px ;
        width: 580px !important;    
        .modal-header {
            border-bottom: 1px solid rgba(0,0,0,.1);
            h2 {
                font-size: 18px;
                color: #3b3b3b;
                font-weight: 500;
            }
        }
        .modal-body {
            padding: 30px 0;
            h3 {
                font-size: 16px;
                color: #3b3b3b;
                text-align: center;
                line-height: 150%;
            }
            .pr-20 {
                padding-right: 20px;
            }
            .pl-20 {
                padding-left: 20px;
            }
            .input-table {
                margin-bottom: 20px;
                border-top: 2px solid #EF2929;
            }
        }
    }
    .round-filled-small-btn:hover {
        color: var(--bs-btn-hover-color) !important;
        background-color: var(--bs-btn-hover-bg) !important;
        border-color: var(--bs-btn-hover-border-color) !important;
    }
`;
const InputTableWrap = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,.1);
    .input-table-body{
        display: flex;
        align-items: flex-start;
        padding: 20px;
        width: 100%;
        h4 {
            font-size: 16px;
            color: #3b3b3b;
            font-weight: 400;
        }
    }
    .input-table-th {
        background-color: #F3F3F3;
        padding: 20px;
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 78px;
        h3 {
            font-size: 16px;
            color: #3b3b3b;
            font-weight: 500;
            line-height: 150%;
            text-align: center;
        }
    }
    .input-table-th-small{
        max-width: 138px;
        min-width: 138px;
    }
`;
const Table = styled.div`
    overflow-y: scroll;
    height: 500px;
    .tbody {
        display: flex;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid rgba(0,0,0,.1);
        .tb {
            background-color: #fff;
            font-size: 16px;
            text-align: center;
            font-weight: 400;
            color: #3b3b3b;
            padding: 15px 20px;
            height: 50px;
        }
        .tb-small {
            padding: 15px 20px;
            font-size: 14px;
        }
        .w-3{
            width: 30%;
        }
        .w-4{
            width: 40%;
        }
        .w-5{
            width: 50%;
        }
    }
    .thead {
        display: flex;
        align-items: center;
        width: 100%;
        position: sticky;
        top: 0;
        .th {
            background-color: #F3F3F3;
            font-size: 16px;
            text-align: center;
            font-weight: 500;
            color: #3b3b3b;
            padding: 15px 20px;
            height: 50px;
        }
        .th-small {
            padding: 15px 20px;
            font-size: 14px;
        }
        .w-3{
            width: 30%;
        }
        .w-4{
            width: 40%;
        }
        .w-5{
            width: 50%;
        }
    }
`;
const ModalBtn = styled.div`
    padding: 0 20px 20px 20px;
    padding-bottom: 0 !important;
`;
export default function CmmnCrdHistModal({ show, onHide, data, title, frcgPtgyClsfCd }) {

    const { t } = useTranslation();
    const isMobile = useWindowSize();
    const defaultSearchData = {
        brchId: null,
        eqmtId: null,
        frcgPtgyClsfCd: frcgPtgyClsfCd || null,
        startDate: null,
        endDate: null,
    };
    const [searchData, setSearchData] = useState(defaultSearchData);
    const [dataList, setDataList] = useState([]);

    // useEffect(() => {
    //     console.log(searchData);
    // }, [searchData]);

    useEffect(() => {
        if (data) {
            setSearchData((prevSearchData) => ({
                ...prevSearchData,
                brchId: data.brchId,
                eqmtId: data.eqmtId,
            }));
        }
    }, [data, frcgPtgyClsfCd]);

    const apiData = (params, endpoint) => {
        return callApi("POST", params, endpoint)
            .then(res => {
                // console.log(res);
                setDataList(res);
            })
            .catch(error => {
                console.log(error);
            });
    };
    const getPntUseList = () => {
        apiData(searchData, "/web/api/dvc-mgt/edm/pnt-use");
    };
    const getQtyUseList = () => {
        apiData(searchData, "/web/api/dvc-mgt/edm/qty-use");
    };
    const getPntIssuList = () => {
        const params = { ...searchData, frcgPtgyClsfCd };
        apiData(params, "/web/api/dvc-mgt/edm/pnt-issu");
    };
    const getQtyIssuList = () => {
        const params = { ...searchData, frcgPtgyClsfCd };
        apiData(params, "/web/api/dvc-mgt/edm/qty-issu");
    };
    const getBrchAllList = (params) => {
        apiData(params, "/web/api/svc-mgt/brch/crd-hist");
    };

    useEffect(() => {
        if (data) {
            switch (title) {
                case t("brchMgtPage.pntUseHst"):
                    getPntUseList(); break;
                case t("brchMgtPage.qtyUseHst"):
                    getQtyUseList(); break;
                case t("brchMgtPage.pntIssuHst"):
                    getPntIssuList(); break;
                case t("brchMgtPage.qtyIssuHst"):
                    getQtyIssuList(); break;
                case t("brchMgtPage.crdCrgrHst"):
                case t("dashboardPage.chargeHistory"):
                    const params = {...searchData, brchId: data.brchId}
                    getBrchAllList(params); break;
                default: break;
            }
        }
    }, [data, title]);

    const handleCmmnBtnClick = () => {
        if (data) {
            if (title === t("brchMgtPage.pntUseHst")) {
                getPntUseList();
            } else if (title === t("brchMgtPage.qtyUseHst")) {
                getQtyUseList();
            } else if (title === t("brchMgtPage.pntIssuHst")) {
                getPntIssuList();
            } else if (title === t("brchMgtPage.qtyIssuHst")) {
                getQtyIssuList();
            } else {
                const params = {...searchData, brchId: data.brchId}
                getBrchAllList(params);
            }
        }
    };

    const handelDatePickerChange = (date, name) => {
        setSearchData((prevData) => ({
            ...prevData,
            [name]: getFormattedDate(date, "yyyy-MM-dd"),
        }))
    };

    const pntUseHistoryKey = t("brchMgtPage.pntUseHst");
    const qtyUseHistoryKey = t("brchMgtPage.qtyUseHst");
    const pntIssuHistoryKey = t("brchMgtPage.pntIssuHst");
    const qtyIssuHistoryKey = t("brchMgtPage.qtyIssuHst");
    const chargeHistoryKey = t("brchMgtPage.crdCrgrHst");
    const dahBrdChargeHistoryKey = t("dashboardPage.chargeHistory");
    const tableConfig = {
        [pntUseHistoryKey]: {
            headers: [t("deviceMgtPage.usePnt"), t("deviceMgtPage.useDttm")],
            fields: ["usePnt", "convFrcgPtgyUseDttm"]
        },
        [qtyUseHistoryKey]: {
            headers: [t("deviceMgtPage.frchUseCntPagCnt"), t("deviceMgtPage.useDttm")],
            fields: ["useQty", "convFrcgPtgyUseDttm"]
        },
        [pntIssuHistoryKey]: {
            headers: [t("deviceMgtPage.issuer"), t("deviceMgtPage.issuPnt"), t("deviceMgtPage.issuDttm")],
            fields: ["userNm", "issuPnt", "convRegDttm"]
        },
        [qtyIssuHistoryKey]: {
            headers: [t("deviceMgtPage.issuer"), t("deviceMgtPage.frcgIssuPageCnt"), t("deviceMgtPage.issuDttm")],
            fields: ["userNm", "issuQty", "convRegDttm"]
        },
        [chargeHistoryKey] : {
            headers: [`${t("brchMgtPage.charge")} ${t("brchMgtPage.credit")}`, `${t("brchMgtPage.charge")}${t("brchMgtPage.dttm")}`],
            fields: ["flngCrdCnt", "convFlngDttm"]
        },
        [dahBrdChargeHistoryKey] : {
            headers: [`${t("brchMgtPage.charge")} ${t("brchMgtPage.credit")}`, `${t("brchMgtPage.charge")}${t("brchMgtPage.dttm")}`],
            fields: ["flngCrdCnt", "convFlngDttm"]
        }
    };

    const renderTable = () => {
        const config = tableConfig[title];
        if (!config) return null;

        return (
            <Table className="table">
                <div className="thead">
                    {config.headers.map((header, idx) => (
                        <div className={`${config.fields.length < 3 ? `th th-small w-5` : `th th-small w-${Math.floor(12 / config.fields.length)}`}`} key={idx}>
                            {header}
                        </div>
                    ))}
                </div>
                <div className="tbody-wrap">
                    {dataList.map((data, idx) => (
                        <div className="tbody" key={idx}>
                            {config.fields.map((field, fieldIdx) => (
                                <div className={`${config.fields.length < 3 ? `tb tb-small w-5` : `tb tb-small w-${Math.floor(12 / config.fields.length)}`}`} key={fieldIdx}>
                                    {typeof data[field] === 'number'
                                        ? formatNumber(data[field])
                                        : data[field]}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </Table>
        );
    };

    return (
        <LargeModal show={show} onHide={onHide} centered>
            <Modal.Header>
                <h2>{title}</h2>
                <CloseButton onClick={onHide} />
            </Modal.Header>
            <Modal.Body>
                <div className="pr-20 pl-20">
                    <div className="input-table">
                        <InputTableWrap>
                            <div className="input-table-th input-table-th-small">
                                <h3>
                                    {t("deviceMgtPage.brch")}<br />
                                    {(data && data.eqmtNm) ? t("deviceMgtPage.eqmtNm") : ""}
                                </h3>
                            </div>
                            <div className="input-table-body">
                                <h4>
                                    {data?.brchNm || ""}
                                    {data?.eqmtNm || ""}
                                </h4>
                            </div>
                        </InputTableWrap>
                        <InputTableWrap>
                            <div className="input-table-th input-table-th-small">
                                <h3>{t("common.periodLable")}</h3>
                            </div>
                            <div className="input-table-body">
                                <CustomDatePicker
                                    className="small-date-wrap"
                                    defaultStartDate={searchData.startDate}
                                    defaultEndDate={searchData.endDate}
                                    onSelectChange={handelDatePickerChange}
                                    isMobile={isMobile}
                                >
                                    <ButtonUtil className="btn btn-danger round-filled-small-btn small-btn"
                                        value={t("common.searchLable")}
                                        onClick={handleCmmnBtnClick}
                                    />
                                </CustomDatePicker>
                            </div>
                        </InputTableWrap>
                    </div>

                    {renderTable()}

                </div>
                <ModalBtn>
                    <ButtonUtil className="btn btn-danger round-filled-small-btn"
                        value={t("common.closeLable")}
                        onClick={onHide}
                    />
                </ModalBtn>
            </Modal.Body>
        </LargeModal>
    )

}