// 전화번호 포맷팅
export const getFormattedValue = (value) => {
    if (!value) return "";

    const cleanValue = value.replace(/[^0-9]/g, "");

    if (cleanValue.length === 11) {
        return cleanValue.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    } else if (cleanValue.length === 10) {
        return cleanValue.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
    } else if (cleanValue.length === 9) {
        return cleanValue.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
    } else {
        return value;
    }
};

export const formatNumber = (number) => {
    return number.toLocaleString();
}