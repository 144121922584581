import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import TitleLogo from "asset/photomag_web/img/icon/filled/insert-emoticon.svg"
import * as Yup from 'yup';
import { callApi } from "util/HttpService";
import { confirm, alert } from "components/web/cmmn/CmmnConfirm";
import TextFields from "components/web/UI/TextFields";
import ClassicEditor from "ckeditor5/src/App.jsx";
import ImgUploadForm from "components/web/cmmn/ImgUploadForm";
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import GoogleMaps from "components/web/cmmn/GoogleMaps";

const DashBoardWrap = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 0 0;
    .dashboard-box {
        background-color: #fff;
        border-radius: 16px;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
        .p-30 {
            padding: 30px;
        }
        .table-wrap {
            h2 {
                font-size: 18px;
                color: #3b3b3b;
                font-weight: 500;
                margin-bottom: 15px;
            }
        }
    }
    @media (max-width: 1200px) {
        .table-wrap{
            overflow: scroll;
        }
    }
`;
const InputTable = styled.div`
    margin-bottom: 20px;
    border-top: 2px solid #EF2929;
    .input-table-th-small{
        max-width: 138px;
        min-width: 138px;
    }
    .input-table-wrap{
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
    .h-2row {
        min-height: 145px !important;
    }
    .h-15row{
        min-height: 110px !important;
    }
`;
const InputTableHead = styled.div`
    background-color: #F3F3F3;
    padding: 20px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 78px;
    max-width: 208px;
    min-width: 208px;
    h3 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
    }
    &.editor {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 78px;
        flex: 0 1 auto;
    }
`;
const InputTableBody = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;
    h4 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 400;
    }
    .form-select {
        max-width: 250px;
    }
    input[type="text"] {
        max-width: 250px;
        width: 250px;
    }
    &:nth-child(2) .btn {
        margin-left: 10px;
    }
    @media (max-width: 1600px) {
        &.editorBody {
            width: 879px !important;
        }
    }
    @media (min-width: 1601px) {
        &.editorBody {
            width: 1193px !important;
        }
    }
    .round-filled-small-btn {
        width: 0%;
        height: 35px;
        &:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
    &.big-input {
        & > div {
            width: 50%;
            input {
                width: 100%;
                max-width: none;
            }
        }
    }
`;
const BtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
        width: 150px;
        max-width: 150px;
        &:first-child {
            margin-right: 10px;
            &:hover {
                color: var(--bs-btn-hover-color);
                background-color: var(--bs-btn-hover-bg);
                border-color: var(--bs-btn-hover-border-color);
            }
        }
        &:last-child:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
    .round-filled-small-btn {
        background-color: ${({ theme }) => theme.colors.red} !important;
    }
`;
const ErrorMsg = styled.p`
    margin-left: 1rem;
    font-size: 0.9rem;
    color: #ef2929;
`;

export default function StreMgtDtl() {

    const defaultStreInfo = {
        blbdCd: "",
        pstsTitl: "",
        streAddr: "",
        streLocXcvl: "",
        streLocYcvl: "",
        pstsCn: "",
        imgFileNm: "",
        mainStreImgFileNm: "",
    };
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pstsSqno } = useParams();

    const [file, setFile] = useState(null);
    const [mainFile, setMainFile] = useState(null);
    const [streInfo, setStreInfo] = useState(defaultStreInfo);
    const [errors, setErrors] = useState({});
    const [addrSearchTrigger, setAddrSearchTrigger] = useState(false);

    const validationSchema = Yup.object().shape({
        pstsTitl: Yup.string().required("매장명을 입력해주세요."),
        imgFileNm: Yup.mixed().test('imgFileNm', '파일을 선택해주세요.', function (value) {
            // 기존 파일이 있을 때는 유효성 검사를 통과시키고, 없으면 파일 필수
            if (this.parent.imgFileNm || value) {
                return true;
            }
            return false;
        }),
        mainStreImgFileNm: Yup.mixed().test('mainStreImgFileNm', '파일을 선택해주세요.', function (value) {
            // 기존 파일이 있을 때는 유효성 검사를 통과시키고, 없으면 파일 필수
            if (this.parent.mainStreImgFileNm || value) {
                return true;
            }
            return false;
        }),
    });
    const searchValidationSchema = Yup.object().shape({
        streAddr: Yup.string().required("주소를 입력해주세요."),
    })

    useEffect(() => {
        if (pstsSqno > 0) {
            callApi("GET", null, `/web/api/svc-mgt/psts/detail?pstsSqno=${pstsSqno}`)
                .then(res => {
                    // console.log(res);
                    setStreInfo(res);
                    setStreInfo((prevData) => ({
                        ...prevData
                    }))
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }, []);

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        // console.log("name : ", name, "|| value : ", value);
        setStreInfo((prevData) => ({
            ...prevData,
            delYn: 'N',
            [name]: value,
        }));
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (value !== '') {
                delete newErrors[name];
            }
            return newErrors;
        });
        setAddrSearchTrigger(false);
    };

    const handleEditorDataChange = (data) => {
        setStreInfo((prevData) => ({
            ...prevData,
            pstsCn: data,
        }));
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (data !== '') {
                delete newErrors['pstsCn'];
            }
            return newErrors;
        });
    };

    const handleFileSelect = (selectedFile) => {
        setFile(selectedFile);

        setStreInfo((prevData) => ({
            ...prevData,
            imgFileNm: selectedFile ? selectedFile.name : '',
        }));

        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (selectedFile) {
                delete newErrors['imgFileNm'];
            } else {
                newErrors['imgFileNm'] = '파일을 선택해주세요.';
            }
            return newErrors;
        });
    };

    const handleMainFileSelect = (selectedFile) => {
        setMainFile(selectedFile);

        setStreInfo((prevData) => ({
            ...prevData,
            mainStreImgFileNm: selectedFile ? selectedFile.name : '',
        }));

        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (selectedFile) {
                delete newErrors['mainStreImgFileNm'];
            } else {
                newErrors['mainStreImgFileNm'] = '파일을 선택해주세요.';
            }
            return newErrors;
        });
    };

    const handleLatLngChange = (lat, lng) => {
        setStreInfo((prevData) => ({
            ...prevData,
            streLocXcvl: lat,
            streLocYcvl: lng,
        }))
    };

    const handleAddrSearchError = (name, message) => {
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (message !== '') {
                newErrors[name] = message;
            } else {
                delete newErrors[name];
            }
            return newErrors;
        });
    };

    const handleAddrSearchClick = () => {
        searchValidationSchema.validate(streInfo, { abortEarly: false })
            .then(() => {
                setAddrSearchTrigger(true);
            })
            .catch((err) => {
                const errors = {};
                err.inner.forEach((error) => {
                    errors[error.path] = error.message;
                });
                setErrors(errors);
            });
    }

    const handleCmmnBtnClick = (btnNm) => {
        switch (btnNm) {
            case "cmmnSearchBtn":
                handleAddrSearchClick();
                break;
            case "cmmnUpdtBtn":
            case "cmmnInsertBtn":
                pstsInfoSave();
                break;
            case "cmmnListBtn":
                navigate("/svc-mgt/stre-mgt");
                break;
            default: break;
        }
    };

    const pstsInfoSave = async () => {

        const formData = new FormData();
        // 메인 이미지 파일 추가
        if (mainFile) {
            formData.append('mainFile', mainFile);
        }
        // 일반 이미지 파일 추가
        if (file) {
            formData.append('file', file);
        }
        formData.append('dirName', 'image');
        formData.append('pstsSqno', pstsSqno);
        formData.append('blbdCd', 'STGD');
        formData.append('pstsTitl', streInfo.pstsTitl);
        formData.append('pstsCn', streInfo.pstsCn ? streInfo.pstsCn : null);
        formData.append('iqryCnt', streInfo.iqryCnt === undefined ? 0 : streInfo.iqryCnt);
        formData.append('streLocXcvl', streInfo.streLocXcvl ? streInfo.streLocXcvl : 0);
        formData.append('streLocYcvl', streInfo.streLocYcvl ? streInfo.streLocYcvl : 0);
        formData.append('streAddr', streInfo.streAddr ? streInfo.streAddr : null);

        try {
            validationSchema.validate(streInfo, { abortEarly: false })
                .then(() => {
                    callApi("POST", formData, `/web/api/svc-mgt/psts/save`, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    }).then(res => {
                        // console.log(res);
                        setErrors({});
                        alert("", t("alert.save"), () => { navigate("/svc-mgt/stre-mgt") });
                    }).catch(error => {
                        console.error(error);
                        alert("", t("alert.saveError"), () => { });
                    })
                })
                .catch((err) => {
                    const newErrors = {};
                    err.inner.forEach((error) => {
                        newErrors[error.path] = error.message;
                    });
                    setErrors(newErrors);
                })

        } catch (error) {
            console.log("이미지 업로드 처리 시 오류 : ", error);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleAddrSearchClick();
        }
    };

    // useEffect(() => {
    //     console.log(streInfo.streLocXcvl);
    //     console.log(streInfo.streLocYcvl);
    // }, [streInfo]);

    return (
        <div className="padding-box">
            <div className="title-wrap">
                <div className="title space-between">
                    <div className="dp-flex align-cen">
                        <img src={TitleLogo} alt="매장안내" />
                        <h2>매장안내</h2>
                    </div>
                    <p>Home {">"} {t("leftMenu.svcMng")} {">"} 매장안내 관리</p>
                </div>
            </div>
            <DashBoardWrap>
                <div className="dashboard-box">
                    <div className="p-30">
                        <div className="table-wrap">
                            <h2>
                                {pstsSqno === "0" ?
                                    `매장안내 ${t("common.regLable")}`
                                    :
                                    `매장안내 ${t("common.updtLable")}`
                                }
                            </h2>
                            <InputTable>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>매장명</h3>
                                    </InputTableHead>
                                    <InputTableBody className="big-input">
                                        <TextFields
                                            type="text"
                                            value={streInfo?.pstsTitl || ""}
                                            name="pstsTitl"
                                            onChangeValue={handleFormDataChange}
                                            errorTxt={errors.pstsTitl}
                                        />
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>주소</h3>
                                    </InputTableHead>
                                    <InputTableBody className="big-input">
                                        <TextFields
                                            type="text"
                                            value={streInfo?.streAddr || ""}
                                            name="streAddr"
                                            onChangeValue={handleFormDataChange}
                                            onKeyDown={handleKeyDown}
                                        />
                                        <ButtonUtil className="btn btn-danger round-filled-small-btn"
                                            value={t("common.searchLable")}
                                            onClick={() => { handleCmmnBtnClick("cmmnSearchBtn") }}
                                        />
                                        <ErrorMsg>{errors.streAddr}</ErrorMsg>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap" style={{ background: "#f3f3f3" }}>
                                    <InputTableHead>
                                        <h3>지도</h3>
                                    </InputTableHead>
                                    <InputTableBody style={{ background: "#fff" }}>
                                        <GoogleMaps
                                            data={streInfo}
                                            addrSearchTrigger={addrSearchTrigger}
                                            onChange={handleLatLngChange}
                                            onSearchError={handleAddrSearchError}
                                        />
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap" style={{ background: "#f3f3f3" }}>
                                    <InputTableHead className="editor">
                                        <h3>상세 내용</h3>
                                    </InputTableHead>
                                    <InputTableBody className="editorBody" style={{ background: "#fff" }}>
                                        <div style={{ width: "100%" }}>
                                            <ClassicEditor
                                                name="pstsCn"
                                                value={streInfo?.pstsCn || ""}
                                                onChangeValue={handleEditorDataChange}
                                            />
                                        </div>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>메인 이미지 업로드</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <ImgUploadForm
                                            id="stre-main-img"
                                            name="mainStreImgFileNm"
                                            onFileInfoUpdate={handleMainFileSelect}
                                            data={streInfo}
                                            t={t}
                                            errorTxt={errors.mainStreImgFileNm}
                                        />
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>이미지 업로드</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <ImgUploadForm
                                            id="stre-img"
                                            name="imgFileNm"
                                            onFileInfoUpdate={handleFileSelect}
                                            data={streInfo}
                                            t={t}
                                            errorTxt={errors.imgFileNm}
                                        />
                                    </InputTableBody>
                                </div>
                                {pstsSqno === "0" ?
                                    <></>
                                    :
                                    <>
                                        <div className="col-2">
                                            <div className="input-table-wrap w-50">
                                                <InputTableHead>
                                                    <h3>{t("userMgtPage.regUserNm")}</h3>
                                                </InputTableHead>
                                                <InputTableBody>
                                                    <h4>{streInfo?.regUserNm || ""}</h4>
                                                </InputTableBody>
                                            </div>
                                            <div className="input-table-wrap w-50">
                                                <InputTableHead>
                                                    <h3>{t("userMgtPage.chngUserNm")}</h3>
                                                </InputTableHead>
                                                <InputTableBody>
                                                    <h4>{streInfo?.chngUserNm || ""}</h4>
                                                </InputTableBody>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="input-table-wrap w-50">
                                                <InputTableHead>
                                                    <h3>{t("userMgtPage.regDttm")}</h3>
                                                </InputTableHead>
                                                <InputTableBody>
                                                    <h4>{streInfo?.convRegDttm || ""}</h4>
                                                </InputTableBody>
                                            </div>
                                            <div className="input-table-wrap w-50">
                                                <InputTableHead>
                                                    <h3>{t("userMgtPage.chngDttm")}</h3>
                                                </InputTableHead>
                                                <InputTableBody>
                                                    <h4>{streInfo?.convChngDttm || ""}</h4>
                                                </InputTableBody>
                                            </div>
                                        </div>
                                    </>
                                }
                            </InputTable>
                        </div>
                        <BtnWrap>
                            <ButtonUtil className="btn btn-outline-light round-solid-medium-btn"
                                value={t("common.listLable")}
                                onClick={() => { handleCmmnBtnClick("cmmnListBtn") }}
                            />
                            <ButtonUtil className="btn btn-danger round-filled-small-btn"
                                value={t("common.saveLable")}
                                onClick={() => { handleCmmnBtnClick("cmmnInsertBtn") }}
                            />
                        </BtnWrap>
                    </div>
                </div>
            </DashBoardWrap>
        </div>
    )
}