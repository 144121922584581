import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import * as Yup from 'yup';
import TitleLogo from "asset/photomag_web/img/icon/filled/insert-emoticon.svg"
import TextFields from "components/web/UI/TextFields";
import CustomDatePicker from "components/mobile/UI/DatePicker";
import { getFormattedDate } from "util/DateSearchUtils";
import { useWindowSize } from "context/WindowSizeContext";
import CmmnRadio from "components/web/cmmn/CmmnRadio";
import ClassicEditor from "ckeditor5/src/App.jsx";
import ImgUploadForm from "components/web/cmmn/ImgUploadForm";
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import { callApi } from "util/HttpService";
import { alert } from "components/web/cmmn/CmmnConfirm";

const DashBoardWrap = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 0 0;
    .dashboard-box {
        background-color: #fff;
        border-radius: 16px;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
        .p-30 {
            padding: 30px;
        }
        .table-wrap {
            h2 {
                font-size: 18px;
                color: #3b3b3b;
                font-weight: 500;
                margin-bottom: 15px;
            }
        }
    }
    @media (max-width: 1200px) {
        .table-wrap{
            overflow: scroll;
        }
    }
`;
const InputTable = styled.div`
    margin-bottom: 20px;
    border-top: 2px solid #EF2929;
    .input-table-th-small{
        max-width: 138px;
        min-width: 138px;
    }
    .input-table-wrap{
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
    .h-2row {
        min-height: 145px !important;
    }
    .h-15row{
        min-height: 110px !important;
    }
`;
const InputTableHead = styled.div`
    background-color: #F3F3F3;
    padding: 20px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 78px;
    max-width: 208px;
    min-width: 208px;
    h3 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
    }
    &.editor {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 78px;
        flex: 0 1 auto;
    }
`;
const InputTableBody = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;
    h4 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 400;
    }
    .form-select {
        max-width: 250px;
    }
    input[type="text"] {
        max-width: 250px;
        width: 250px;
    }
    &:nth-child(2) .btn {
        margin-left: 10px;
    }
    @media (max-width: 1600px) {
        &.editorBody {
            width: 879px !important;
        }
    }
    @media (min-width: 1601px) {
        &.editorBody {
            width: 1193px !important;
        }
    }
`;
const BtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
        width: 150px;
        max-width: 150px;
        &:first-child {
            margin-right: 10px;
            &:hover {
                color: var(--bs-btn-hover-color);
                background-color: var(--bs-btn-hover-bg);
                border-color: var(--bs-btn-hover-border-color);
            }
        }
        &:last-child:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
    .round-filled-small-btn {
        background-color: ${({ theme }) => theme.colors.red} !important;
    }
`;

export default function EvntMgtDtl() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pstsSqno } = useParams();
    const isMobile = useWindowSize();

    const defaultEvntInfo = {
        pstsTitl: "",
        evntSrtDt: "",
        evntEndDt: "",
        evntStatCd: "",
        pstsCn: "",
        imgFileNm: "",
    };
    const [file, setFile] = useState(null);
    const [evntInfo, setEvntInfo] = useState(defaultEvntInfo);
    const [errors, setErrors] = useState({});

    const validationSchema = Yup.object().shape({
        pstsTitl: Yup.string().required("이벤트 명을 입력해주세요."),
        evntStatCd: Yup.string().required("이벤트 상태를 선택해주세요."),
        imgFileNm: Yup.mixed().test('imgFileNm', '파일을 선택해주세요.', function (value) {
            // 기존 파일이 있을 때는 유효성 검사를 통과시키고, 없으면 파일 필수
            if (this.parent.imgFileNm || value) {
                return true;
            }
            return false;
        }),
    });

    useEffect(() => {
        if (pstsSqno > 0) {
            callApi("GET", null, `/web/api/svc-mgt/psts/detail?pstsSqno=${pstsSqno}`)
                .then(res => {
                    setEvntInfo({
                        ...res,
                        evntSrtDt: parseDate(res.evntSrtDt),
                        evntEndDt: parseDate(res.evntEndDt),
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [pstsSqno]);

    const parseDate = (date) => {
        if (!date || date.length !== 8) return "";
        const year = date.substring(0, 4);
        const month = date.substring(4, 6) - 1;
        const day = date.substring(6, 8);
    
        // Date 객체를 yyyy-MM-dd 형식으로 변환
        const formattedDate = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        return formattedDate;
    };

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        // console.log("name : ", name, "|| value : ", value);
        setEvntInfo((prevData) => ({
            ...prevData,
            delYn: 'N',
            [name]: value,
        }));
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (value !== '') {
                delete newErrors[name];
            }
            return newErrors;
        });
    };

    const handleEditorDataChange = (data) => {
        setEvntInfo((prevData) => ({
            ...prevData,
            pstsCn: data,
        }));
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (data !== '') {
                delete newErrors['pstsCn'];
            }
            return newErrors;
        });
    };

    const handelDatePickerChange = (date, name) => {
        const formattedDate = getFormattedDate(date, "yyyy-MM-dd");
        setEvntInfo((prevData) => {
            if (name === 'startDate') {
                return { ...prevData, evntSrtDt: formattedDate };
            } else if (name === 'endDate') {
                return { ...prevData, evntEndDt: formattedDate };
            }
        });
    };
    const handleFileSelect = (selectedFile) => {
        setFile(selectedFile);

        setEvntInfo((prevData) => ({
            ...prevData,
            imgFileNm: selectedFile ? selectedFile.name : '',
        }));

        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (selectedFile) {
                delete newErrors['imgFileNm'];
            } else {
                newErrors['imgFileNm'] = '파일을 선택해주세요.';
            }
            return newErrors;
        });
    };

    const handleCmmnBtnClick = (btnNm) => {
        switch (btnNm) {
            case "cmmnUpdtBtn":
            case "cmmnInsertBtn":
                evntInfoSave();
                break;
            case "cmmnListBtn":
                navigate("/svc-mgt/evnt-mgt");
                break;
            default: break;
        }
    };

    // useEffect(() => {
    //     console.log(evntInfo);
    // }, [evntInfo]);

    const evntInfoSave = async () => {

        const formData = new FormData();
        formData.append('file', file);
        formData.append('dirName', 'image');
        formData.append('pstsSqno', pstsSqno);
        formData.append('blbdCd', 'EVNT');
        formData.append('pstsTitl',evntInfo.pstsTitl);
        formData.append('pstsCn', evntInfo.pstsCn ? evntInfo.pstsCn : null);
        formData.append('iqryCnt', evntInfo.iqryCnt === undefined ? 0 : evntInfo.iqryCnt);
        formData.append('evntStatCd',evntInfo.evntStatCd);
        formData.append('evntSrtDt',evntInfo.evntSrtDt);
        formData.append('evntEndDt',evntInfo.evntEndDt);
        // formData.append('jsonData', JSON.stringify(params));

        try {
            validationSchema.validate(evntInfo, { abortEarly: false })
                .then(() => {
                    callApi("POST", formData, `/web/api/svc-mgt/psts/save`, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    }).then(res => {
                        console.log(res);
                        setErrors({});
                        alert("", t("alert.save"), () => { navigate("/svc-mgt/evnt-mgt") });
                    }).catch(error => {
                        console.error(error);
                        alert("", t("alert.saveError"), () => { });
                    })
                })
                .catch((err) => {
                    const newErrors = {};
                    err.inner.forEach((error) => {
                        newErrors[error.path] = error.message;
                    });
                    setErrors(newErrors);
                })

        } catch (error) {
            console.log("이미지 업로드 처리 시 오류 : ", error);
        }
    };

    return (
        <div className="padding-box">
            <div className="title-wrap">
                <div className="title space-between">
                    <div className="dp-flex align-cen">
                        <img src={TitleLogo} alt="이벤트관리" />
                        <h2>이벤트 관리</h2>
                    </div>
                    <p>Home {">"} {t("leftMenu.svcMng")} {">"} 이벤트 관리</p>
                </div>
            </div>
            <DashBoardWrap>
                <div className="dashboard-box">
                    <div className="p-30">
                        <div className="table-wrap">
                            <h2>
                                {pstsSqno === "0" ?
                                    `이벤트 ${t("common.regLable")}`
                                    :
                                    `이벤트 ${t("common.updtLable")}`
                                }
                            </h2>
                            <InputTable>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>이벤트 명</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <TextFields
                                            type="text"
                                            value={evntInfo?.pstsTitl || ""}
                                            name="pstsTitl"
                                            onChangeValue={handleFormDataChange}
                                            errorTxt={errors.pstsTitl}
                                        />
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>이벤트 기간</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <CustomDatePicker
                                            defaultStartDate={evntInfo.evntSrtDt}
                                            defaultEndDate={evntInfo.evntEndDt}
                                            onSelectChange={handelDatePickerChange}
                                            isMobile={isMobile}
                                        />
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>이벤트 상태</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <CmmnRadio
                                            cmmnCdId={17}
                                            defaultVal={evntInfo.evntStatCd}
                                            tagName="evntStatCd"
                                            onSelectChange={handleFormDataChange}
                                            errorTxt={errors.evntStatCd}
                                        />
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap" style={{ background: "#f3f3f3" }}>
                                    <InputTableHead className="editor">
                                        <h3>이벤트 내용</h3>
                                    </InputTableHead>
                                    <InputTableBody className="editorBody" style={{ background: "#fff" }}>
                                        <div style={{ width: "100%" }}>
                                            <ClassicEditor
                                                name="pstsCn"
                                                value={evntInfo?.pstsCn || ""}
                                                onChangeValue={handleEditorDataChange}
                                            />
                                        </div>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>이미지 업로드</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <ImgUploadForm
                                            id="evnt"
                                            name="imgFileNm"
                                            onFileInfoUpdate={handleFileSelect}
                                            data={evntInfo}
                                            t={t}
                                            errorTxt={errors.imgFileNm}
                                        />
                                    </InputTableBody>
                                </div>
                                {pstsSqno === "0" ?
                                    <></>
                                    :
                                    <>
                                        <div className="col-2">
                                            <div className="input-table-wrap w-50">
                                                <InputTableHead>
                                                    <h3>{t("userMgtPage.regUserNm")}</h3>
                                                </InputTableHead>
                                                <InputTableBody>
                                                    <h4>{evntInfo?.regUserNm || ""}</h4>
                                                </InputTableBody>
                                            </div>
                                            <div className="input-table-wrap w-50">
                                                <InputTableHead>
                                                    <h3>{t("userMgtPage.chngUserNm")}</h3>
                                                </InputTableHead>
                                                <InputTableBody>
                                                    <h4>{evntInfo?.chngUserNm || ""}</h4>
                                                </InputTableBody>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="input-table-wrap w-50">
                                                <InputTableHead>
                                                    <h3>{t("userMgtPage.regDttm")}</h3>
                                                </InputTableHead>
                                                <InputTableBody>
                                                    <h4>{evntInfo?.convRegDttm || ""}</h4>
                                                </InputTableBody>
                                            </div>
                                            <div className="input-table-wrap w-50">
                                                <InputTableHead>
                                                    <h3>{t("userMgtPage.chngDttm")}</h3>
                                                </InputTableHead>
                                                <InputTableBody>
                                                    <h4>{evntInfo?.convChngDttm || ""}</h4>
                                                </InputTableBody>
                                            </div>
                                        </div>
                                    </>
                                }
                            </InputTable>
                        </div>
                        <BtnWrap>
                            <ButtonUtil className="btn btn-outline-light round-solid-medium-btn"
                                value={t("common.listLable")}
                                onClick={() => { handleCmmnBtnClick("cmmnListBtn") }}
                            />
                            <ButtonUtil className="btn btn-danger round-filled-small-btn"
                                value={t("common.saveLable")}
                                onClick={() => { handleCmmnBtnClick("cmmnInsertBtn") }}
                            />
                        </BtnWrap>
                    </div>
                </div>
            </DashBoardWrap>
        </div>
    )

}