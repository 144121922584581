
import React,{useEffect,useRef} from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';

export default function PayPalButtonWrapper ({payAmount, handlePaymentSuccess,handlePaymentFail,paypalBtnheight = 40}) {    
  const payAmountRef = useRef(payAmount); // 최신 값을 저장하는 ref

  // payAmount가 변경될 때마다 ref 업데이트
  useEffect(() => {
      payAmountRef.current = payAmount;
      console.log('Updated payAmount : ', payAmountRef.current);
  }, [payAmount]); // payAmount가 변경될 때마다 실행됨
  
    return (
      <PayPalButtons
       
        style={{
            layout: 'horizontal', // 또는 'horizontal'로 변경할 수 있습니다.
            height: paypalBtnheight, // 버튼 높이 조정
            color: 'blue',
            shape:'pill',            
            //label: 'pay'
        }}
        disabled={payAmount === 0}        
        createOrder={(data, actions) => {
          console.log('createOrder payAmount : ', payAmountRef.current); // 최신 payAmount를 참조
          return actions.order.create({
              purchase_units: [
                  {
                      
                      amount: {
                          currency_code: "JPY",
                          value: payAmountRef.current, // 결제 금액 설정
                      },
                  },
              ],
          });
      }}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            console.log('payment result details : ',details);
            setTimeout(() => {
              handlePaymentSuccess(details.id); // 서버에 결과 저장 요청
            }, 500); //페이팔 결제 창으 닫히기전 서버 처리에 따른 오류 방지를 위해 대기 시간 설정
            
          });
        }}
        onError={(err) => {
          console.error(err);
          // 에러 메시지 길이를 1000자로 제한
          const errorMessage = err.message && err.message.length > 1000 
          ? err.message.substring(0, 1000) + '...' // 1000자 이하로 자르고 '...' 추가
          : err.message;
          setTimeout(() => {
            handlePaymentFail(`${errorMessage || 'Unknown error'}`);        
          }, 500); //페이팔 결제 창으 닫히기전 서버 처리에 따른 오류 방지를 위해 대기 시간 설정
            
        }}
      />
    );
}