'use client'

import styled, { css } from "styled-components"
import RootLayoutComponents from "./RootLayoutComponents";
import Gnb from "./Gnb";
import { useState } from "react";
import Header from "./Header";
import BtmNav from "./BtmNav";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const BodyWrap = styled.div`
    line-height: 1;
    font-size: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 10px;
    ${(props) => !props.isLoginPage && css`
        background-color: #f7f7f7;
    `}
    section {
        padding: 100px 20px 100px 20px;
        .title-wrap {
            .title {
                display: flex;
                align-items: center;
                padding-bottom: 15px;
                border-bottom: 1px solid ${({ theme }) => theme.colors.gray7};
                width: 100%;
                img {
                    width: 15px;
                    margin-right: 10px;
                }
                h2 {
                    font-size: 18px;
                    color: ${({ theme }) => theme.colors.dark2};
                    font-weight: 500;
                }
            }
        }
    }
`;
const SideNavWrap = styled.div`
    background-color: rgba(0,0,0,.4);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 98;
    display: ${(props) => props.$visible ? 'block' : 'none'};
`;


export default function MainLayoutComponents({ children }) {

    const isLoggedIn = !!localStorage.getItem('accessToken');
    const [isGnbOpen, setIsGnbOpen] = useState(false);
    const [sideMenu, setsideMenu] = useState(false);

    const showSideMenu = () => {
        setsideMenu(!sideMenu);
        setIsGnbOpen(!isGnbOpen);
        document.body.style.overflow = "hidden";
    };

    const hideSideMenu = () => {
        setsideMenu(false);
        setIsGnbOpen(false);
        document.body.style.overflow = "unset";
    };
    const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
    return (
        <>
            <RootLayoutComponents>
                <BodyWrap id="bodyWrap">
                <PayPalScriptProvider options={{ "client-id": paypalClientId, currency: "JPY" }}>

                    <SideNavWrap $visible={isGnbOpen} $isLoggedIn={isLoggedIn}></SideNavWrap>
                    {isLoggedIn && <Header showSideMenu={showSideMenu} />}

                    {isLoggedIn && <Gnb sideMenu={sideMenu} hideSideMenu={hideSideMenu} />}

                    {children}

                    {isLoggedIn && <BtmNav />}
                    </PayPalScriptProvider>
                </BodyWrap>

            </RootLayoutComponents>
        </>
    )
}