import styled, { css } from "styled-components";
import Headerlogo from "asset/img/logo_white.svg";
import profileLogo from "asset/mobile_web/img/icon/profile.svg"
import stars from "asset/mobile_web/img/icon/stars.svg";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import { useEffect, useRef, useState } from "react";
import CmmnPwChangeModal from "components/mobile/cmmn/CmmnPwChangeModal";
import DeleteToken from "components/web/auth/DeleteToken";
import LeftMenu from "./LeftMenu";
import { callApi } from "util/HttpService";
import { useBrch } from "components/web/auth/BrchProvider";
import { useWindowSize } from "context/WindowSizeContext";
import CmmnCreditModal from "components/mobile/cmmn/CmmnCreditModal";
import { useTranslation } from "react-i18next";
import HeaderBrchNm from "components/web/cmmn/HeaderBrchNm";

const SideNavContainer = styled.nav`
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: auto;
    background-color: #161611;
    padding: 0 20px;
    width: 20%;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0 4px 10px rgba(0,0,0,.2);
    z-index: 98;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-100%);
    transition: opacity 0.6s, transform 0.6s, visibility 0.6s;
    ${(props) =>
        props.$sideMenu &&
        css`
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        `
    }
    @media (max-width: 900px) {
        width: 80%;
    }
    .form-select {
        margin-bottom: 20px;
    }
`;
const SideNavHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    button {
        background-color: transparent;
        .close-i {
            font-size: 18px;
            color: #fff;
        }
    }
    img {
        width: 130px;
    }
`;
const ProfileBox = styled.div`
    background-color: ${({ theme }) => theme.colors.dark};
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    .name-box {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        img {
            width: 30px;
        }
        h3 {
            margin-left: 15px;
            color: #fff;
            font-size: 20px;
            font-weight: bold;
            span {
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
    @keyframes blink-effect {
        50% {
            opacity: 0;
        }
    }
    .blink-effect {
        animation: blink-effect 1s step-end infinite;
    }
    button {
        &:first-of-type {
            margin-bottom: 10px;
        }
    }
    .round-solid-medium-btn {
        color: #fff;
    }
    .btn {
        &:last-child:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
`;
const SideBottomBtnWrap = styled.div`
    padding-bottom: 20px;
    button:first-child {
        margin-bottom: 10px;
    }
    .round-solid-small-red-btn {
        width: 100% !important;
    }
`;

function Gnb({ sideMenu, hideSideMenu }) {
    library.add(faXmark);
    const { t } = useTranslation();
    const isMobile = useWindowSize();
    const { brchId, changeBrchId, crdInfo, getBrchCrdInfo, crdStat, setStat, stat } = useBrch();
    const brchClsfCd = localStorage.getItem("brchClsfCd");
    const locBrchId = localStorage.getItem("brchId");
    const userSqno = localStorage.getItem("userSqno");
    const gnbRef = useRef(null);

    const [isPwdModal, setIsPwdModal] = useState(false);
    const [isCrdModal, setIsCrdModal] = useState(false);
    const [userInfo, setUserInfo] = useState({});

    const setScreenSize = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    useEffect(() => {
        setScreenSize();
        window.addEventListener('resize', setScreenSize);
        return () => {
            window.removeEventListener('resize', setScreenSize);
        };
    }, []);

    const openPwdModal = () => {
        setIsPwdModal(true);
        hideSideMenu();
    }
    const openCrdModal = () => {
        setIsCrdModal(true);
        hideSideMenu();
    }
    const handleLogout = () => {
        DeleteToken(userSqno, isMobile);
    }
    const getUserInfo = () => {
        callApi("GET", { userSqno: userSqno }, "/web/api/svc-mgt/user/detail")
            .then(res => {
                setUserInfo(res);
            })
            .catch(error => {
                console.log(error);
            })
    };
    useEffect(() => {
        getUserInfo();
        getBrchCrdInfo();
    }, []);

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;

        if (name === "brchId") {
            changeBrchId(value);
        }
    };

    const handleClickOutside = (event) => {
        if (gnbRef.current && !gnbRef.current.contains(event.target)) {
            hideSideMenu();
        }
    };

    useEffect(() => {
        if (sideMenu) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [sideMenu]);

    const blinkEffect = (crdStat[0] === null || crdStat[0] === undefined || crdStat[0] <= 150) ? "blink-effect" : "";

    return (
        <>
            <SideNavContainer $sideMenu={sideMenu} ref={gnbRef}>
                <div>
                    <SideNavHeader>
                        <img src={Headerlogo} alt="side_nav_logo" />
                        <ButtonUtil onClick={() => { hideSideMenu() }}>
                            <FontAwesomeIcon icon={faXmark} className="close-i" />
                        </ButtonUtil>
                    </SideNavHeader>
                    {brchClsfCd === "KSC" &&
                        <HeaderBrchNm
                            defaultVal={brchId}
                            tagName={"brchId"}
                            defaultOptTxt={t("header.brchSelect")}
                            onSelectChange={handleFormDataChange}
                            brchClsfCd={"KSC"}
                        />
                    }
                    {brchClsfCd === "FCC" &&
                        <HeaderBrchNm
                            defaultVal={brchId}
                            tagName={"brchId"}
                            defaultOptTxt={t("header.brchSelect")}
                            onSelectChange={handleFormDataChange}
                            brchClsfCd={"FCC"}
                            brchId={locBrchId}
                        />
                    }
                    {brchClsfCd === "JMC" &&
                        <HeaderBrchNm
                            defaultVal={brchId}
                            tagName={"brchId"}
                            defaultOptTxt={t("header.brchSelect")}
                            onSelectChange={handleFormDataChange}
                            brchClsfCd={"JMC"}
                            brchId={locBrchId}
                            disabled={brchClsfCd === "JMC"}
                            className={"readonly"}
                        />
                    }
                    <ProfileBox>
                        <div className="name-box">
                            <img src={profileLogo} alt="profile_logo" />
                            <h3>{userInfo.userNm} <span>{t("m_gnb.Mr")}</span></h3>
                        </div>
                        <ButtonUtil className="btn btn-danger round-filled-small-btn"
                            onClick={() => { }}
                        >
                            <img src={stars} alt="stars_icon" className={blinkEffect} />
                            {crdStat && crdStat[0] != null
                                ? `${t("m_gnb.creditHeld")}: ${crdStat[0].toLocaleString()}`
                                : `${t("m_gnb.creditHeld")}: 0`}
                        </ButtonUtil>
                        <ButtonUtil className="btn round-solid-medium-btn btn-outline-secondary"
                            value={t("m_gnb.chargeIt")}
                            onClick={() => { openCrdModal() }}
                            disabled={false}
                        />
                    </ProfileBox>
                    <LeftMenu hideSideMenu={hideSideMenu} />
                </div>
                <SideBottomBtnWrap>
                    <ButtonUtil className="btn round-solid-small-mb-btn btn-outline-secondary"
                        id="pw-change-btn"
                        value={t("m_gnb.changePwd")}
                        onClick={() => { openPwdModal() }}
                    />
                    <ButtonUtil className="btn round-solid-small-red-btn btn-outline-danger"
                        value={t("m_gnb.logout")}
                        onClick={() => { handleLogout() }}
                    />
                </SideBottomBtnWrap>
            </SideNavContainer>
            {/* {!alertModal && isPwdModal && */}
            <CmmnPwChangeModal
                isPwdModal={isPwdModal}
                setIsPwdModal={setIsPwdModal}
                onHide={() => setIsPwdModal(false)}
                t={t}
            />
            {isCrdModal &&
                <CmmnCreditModal
                    setIsCrdModal={setIsCrdModal}
                    t={t}
                    crdInfo={crdInfo}
                    crdStat={crdStat}
                    getBrchCrdInfo={getBrchCrdInfo}
                    stat={stat}
                    setStat={setStat}
                />
            }
            {/* <CmmnPwAlert show={alertModal} onHide={() => setAlertModal(false)} msg={msg} /> */}
        </>
    )


}
export default Gnb;