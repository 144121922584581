import { useState, useEffect, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import {
	ClassicEditor,
	AccessibilityHelp,
	Alignment,
	Autoformat,
	AutoImage,
	Autosave,
	Base64UploadAdapter,
	BlockQuote,
	Bold,
	CloudServices,
	Code,
	CodeBlock,
	Essentials,
	FindAndReplace,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	FullPage,
	GeneralHtmlSupport,
	// Heading,
	Highlight,
	HorizontalLine,
	HtmlComment,
	HtmlEmbed,
	ImageBlock,
	ImageCaption,
	ImageInline,
	ImageInsert,
	ImageInsertViaUrl,
	ImageResize,
	ImageStyle,
	ImageTextAlternative,
	ImageToolbar,
	ImageUpload,
	Indent,
	IndentBlock,
	Italic,
	Link,
	LinkImage,
	List,
	ListProperties,
	Paragraph,
	SelectAll,
	// ShowBlocks,
	// SourceEditing,
	SpecialCharacters,
	SpecialCharactersArrows,
	SpecialCharactersCurrency,
	SpecialCharactersEssentials,
	SpecialCharactersLatin,
	SpecialCharactersMathematical,
	SpecialCharactersText,
	Strikethrough,
	Style,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	// TextPartLanguage,
	TextTransformation,
	// Title,
	TodoList,
	Underline,
	Undo,
	// MediaEmbed,
	FileRepository,
	DragDrop,
	ButtonView,
	Plugin,
} from 'ckeditor5';

import translations from 'ckeditor5/translations/ko.js';

import 'ckeditor5/ckeditor5.css';

import './App.css';
import styled from 'styled-components';
import { callApi } from 'util/HttpService';
// import { alert } from 'components/web/cmmn/CmmnConfirm';

const EditorWrap = styled.div`
	width: 100%;
	box-sizing: border-box;
	z-index: 1;
	.ck-reset_all {
		position: relative;
		z-index: 4 !important;
	}
  	.editor-container__editor {
		width: 100%;
		max-width: 1152px;
		min-width: 695px;
  	}
  	.ck-editor__editable {
		min-height: 600px;
		font-size: 14px;
  	}
  	@media (max-width: 1600px) {
		.ck-editor__editable {
			min-height: 400px;
		}
  	}
	.ck-editor__editable em, .ck-editor__editable i {
 	   font-style: italic !important;
	}
	strong u {
    	font-family: 'Pretendard-Bold';
	}
	.ck-content p {
    	font-size: 14px;
	}
	.instagram-btn {
		margin: 0 3px 0 0 !important;
		padding: 0 !important;
		display: flex !important;
		justify-content: center !important;
		& > .ck-icon {
			margin: 0 !important;
			width: 24px;
			height: 24px;
		}
	}
	.hashtag-btn {
		margin: 0 !important;
		padding: 0 !important;
		display: flex !important;
		justify-content: center !important;
		& > .ck-icon {
			margin: 0 !important;
			width: 19px;
			height: 19px;
		}
	}
`;
const debounce = (func, delay) => {
	let timeout;
	return (...args) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => func(...args), delay);
	};
};

class VideoUpload extends Plugin {
	init() {
		const editor = this.editor;

		// 동영상 전용 블록을 정의
		editor.model.schema.register('videoBlock', {
			allowWhere: '$block',
			isObject: true,
			allowAttributes: ['src']
		});

		// HTML -> 모델로 변환 (업캐스트, 로딩 시)
		editor.conversion.for('upcast').elementToElement({
			view: {
				name: 'video',
				attributes: {
					src: true
				}
			},
			model: (viewElement, { writer: modelWriter }) => {
				const src = viewElement.getAttribute('src');
				return modelWriter.createElement('videoBlock', { src });
			}
		});

		// 모델 -> 뷰로 변환 (다운캐스트, 저장 시)
		editor.conversion.for('dataDowncast').elementToElement({
			model: 'videoBlock',
			view: (modelElement, { writer: viewWriter }) => {
				const src = modelElement.getAttribute('src');
				const videoElement = viewWriter.createContainerElement('video', {
					controls: 'controls',
					width: '500',
					src: src,
					style: 'display: block; margin: 0 auto;'
				});

				const sourceElement = viewWriter.createEmptyElement('source', {
					src: src,
					type: 'video/mp4'
				});

				viewWriter.insert(viewWriter.createPositionAt(videoElement, 0), sourceElement);

				return videoElement;
			}
		});

		// 편집 중일 때 모델 -> 뷰로 변환 (다운캐스트)
		editor.conversion.for('editingDowncast').elementToElement({
			model: 'videoBlock',
			view: (modelElement, { writer: viewWriter }) => {
				const src = modelElement.getAttribute('src');
				const videoElement = viewWriter.createContainerElement('video', {
					controls: 'controls',
					width: '500',
					src: src,
					style: 'display: block; margin: 0 auto;'
				});

				const sourceElement = viewWriter.createEmptyElement('source', {
					src: src,
					type: 'video/mp4'
				});

				viewWriter.insert(viewWriter.createPositionAt(videoElement, 0), sourceElement);

				return videoElement;
			}
		});

		// 비디오 업로드 버튼
		editor.ui.componentFactory.add('videoUpload', locale => {
			const view = new ButtonView(locale);
			view.set({
				icon: `<?xml version="1.0" encoding="UTF-8"?>
					<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
					<path d="m16.914,1h2.086c.621,0,1.215.114,1.764.322l-5.678,5.678h-4.172l6-6Zm7.086,6v-1c0-1.4-.579-2.668-1.51-3.576l-4.576,4.576h6.086ZM10.522,1l-6.084,6h3.648L14.086,1h-3.564ZM1.59,7L7.674,1h-2.674C2.243,1,0,3.243,0,6v1h1.59Zm22.41,2v9c0,2.757-2.243,5-5,5H5c-2.757,0-5-2.243-5-5v-9h24Zm-8.953,6.2l-4.634-2.48c-.622-.373-1.413.075-1.413.8v4.961c0,.725.791,1.173,1.413.8l4.634-2.48c.604-.362.604-1.238,0-1.6Z"/>
					</svg>`,
				label: '',
				tooltip: '동영상 삽입',
				withText: false
			});

			view.on('execute', () => {
				const input = document.createElement('input');
				input.type = 'file';
				input.accept = 'video/mp4';
				input.click();

				input.onchange = () => {
					const file = input.files[0];
					// const maxLimit = 30 * 1024 * 1024;
					// if (file.size > maxLimit) {
					// 	alert("", "파일 사이즈가 30MB를 초과합니다.", () => {});
					// 	return;
					// }
					if (file) {
						const formData = new FormData();
						formData.append('file', file);
						formData.append('dirName', 'video');

						callApi("POST", formData, "/web/api/svc-mgt/psts/video-upload", {
							headers: {
								'Content-Type': 'multipart/form-data',
							}
						})
							.then(res => {
								console.log("S3 URL: ", res);
								if (res) {
									editor.model.change(writer => {
										// 동영상 블록을 추가
										const videoElement = writer.createElement('videoBlock', {
											src: res  // 업로드된 S3 동영상 URL
										});

										// 동영상 블록을 현재 커서 위치에 삽입
										editor.model.insertContent(videoElement, editor.model.document.selection);

										// 동영상 뒤에 빈 텍스트 블록을 추가해 텍스트를 쓸 수 있게 함
										const paragraphElement = writer.createElement('paragraph');
										editor.model.insertContent(paragraphElement, writer.createPositionAfter(videoElement));

										// 커서를 빈 텍스트 블록으로 이동시킴
										writer.setSelection(paragraphElement, 'in');
									});
								}
							})
							.catch(err => {
								console.error(err);
							});
					}
				};
			});

			return view;
		});
	}
}

class InstagramReels extends Plugin {
	init() {
		const editor = this.editor;

		editor.model.schema.register('instagramReels', {
			allowWhere: '$block',
			isObject: true,
			allowAttributes: ['url'],
		});

		editor.conversion.for('upcast').elementToElement({
			view: {
				name: 'blockquote',
				classes: 'instagram-media',
				attributes: {
					'data-instgrm-permalink': true,
					'data-instgrm-version': true,
				},
			},
			model: (viewElement, { writer }) => {
                const url = viewElement.getAttribute('data-instgrm-permalink');
                return writer.createElement('instagramReels', { url });
            },
		});

		editor.conversion.for('downcast').elementToElement({
			model: 'instagramReels',
			view: (modelElement, { writer }) => {
				const url = modelElement.getAttribute('url');
				const container = writer.createContainerElement('div', {
					class: 'instagram-container',
					style: 'display: flex; justify-content: center;',
				});
				// 인스타 iframe
				const iframe = writer.createEmptyElement('iframe', {
					class: 'instagram-media instagram-media-rendered',
					src: `${url}embed/?cr=1&v=12`,
					allow: 'autoplay; clipboard-write; encrypted-media; picture-in-picture',
					allowtransparency: 'true',
					allowfullscreen: 'true',
					frameborder: '0',
					height: '615',
					scrolling: 'no',
					style: 'max-width: 400px; background-color: white; border-radius: 3px; border: 1px solid rgb(219, 219, 219); box-shadow: none; display: block; margin: 0px 0px 12px; min-width: 326px; padding: 0px;',
				});
		
				writer.insert(writer.createPositionAt(container, 0), iframe);
				return container;
			},
		});

		editor.ui.componentFactory.add('addInstagramReels', locale => {
			const view = new ButtonView(locale);

			view.extendTemplate({
				attributes: {
					class: 'instagram-btn',
				},
			});

			view.set({
				icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#000000" style="width: 24px; height: 24px; margin: 0; padding: 0; margin-right: 3px;">
    					<path d="M349.33,69.33a93.62,93.62,0,0,1,93.34,93.34V349.33a93.62,93.62,0,0,1-93.34,93.34H162.67a93.62,93.62,0,0,1-93.34-93.34V162.67a93.62,93.62,0,0,1,93.34-93.34H349.33m0-37.33H162.67C90.8,32,32,90.8,32,162.67V349.33C32,421.2,90.8,480,162.67,480H349.33C421.2,480,480,421.2,480,349.33V162.67C480,90.8,421.2,32,349.33,32Z"/><path d="M377.33,162.67a28,28,0,1,1,28-28A27.94,27.94,0,0,1,377.33,162.67Z"/><path d="M256,181.33A74.67,74.67,0,1,1,181.33,256,74.75,74.75,0,0,1,256,181.33M256,144A112,112,0,1,0,368,256,112,112,0,0,0,256,144Z"/>
						</svg>`,
				label: '',
				tooltip: '인스타그램 URL 삽입',
				withText: true,
			});

			view.on('execute', () => {
				let url = prompt('인스타그램 URL을 입력해주세요.');
				if (url) {
					url = url.split('?')[0];
					const isValidUrl = /^https:\/\/www\.instagram\.com\/reel\/[A-Za-z0-9_-]+\/?$/.test(url);
					if (!isValidUrl) {
						alert('인스타그램 URL이 올바르지 않습니다.');
						return;
					}

					editor.model.change(writer => {
						const insertPosition = editor.model.document.selection.getFirstPosition();
						const reelsElement = writer.createElement('instagramReels', { url });
						writer.insert(reelsElement, insertPosition);
					});
				}
			});
			return view;
		});
	}
	loadInstagramScript() {
        if (!document.querySelector('script[src="https://www.instagram.com/embed.js"]')) {
            const script = document.createElement('script');
            script.src = 'https://www.instagram.com/embed.js';
            script.async = true;

            script.onload = () => {
                if (window.instgrm) {
                    console.log('Instagram Embed.js 로드 완료');
                    window.instgrm.Embeds.process();
                } else {
                    console.error('Instagram Embed.js 초기화 실패');
                }
            };

            script.onerror = (error) => {
                console.error('Instagram Embed.js 로드 실패:', error);
            };

            document.body.appendChild(script);
        } else if (window.instgrm) {
            console.log('Instagram Embed.js 재처리');
            window.instgrm.Embeds.process();
        }
    }
}

class HashtagPlugin extends Plugin {
    init() {
        const editor = this.editor;

        // 모델에서 해시태그 속성 추가
        editor.model.schema.extend('$text', { allowAttributes: 'hashtag' });

		editor.model.schema.register('span', {
			allowWhere: '$block',
			isInline: true,
			isObject: false,
			allowAttributes: ['class', 'style'],
		});		

        // 업캐스트: HTML -> 모델 변환
        editor.conversion.for('upcast').attributeToAttribute({
            view: {
                key: 'class',
                value: 'hashtag',
            },
            model: 'hashtag',
        });

        // 다운캐스트: 모델 -> HTML 변환
        editor.conversion.for('downcast').attributeToElement({
            model: 'hashtag',
			view: (modelAttributeValue, { writer }) => {
                const element = writer.createAttributeElement('span', {
                    class: 'hashtag',
                    style: 'font-weight: 600; cursor: pointer;',
					'data-copy': 'true',
                });

                // 클릭 이벤트에 복사 기능 추가
                return element;
            },
        });

        // 해시태그 삽입 명령
        editor.commands.add('insertHashtag', {
            execute: ({ value }) => {
                editor.model.change(writer => {
                    const selection = editor.model.document.selection;

					value.forEach(tag => {
                        editor.model.insertContent(
                            writer.createText(`#${tag}`, { hashtag: tag }),
                            selection.getFirstPosition()
                        );
                        editor.model.insertContent(writer.createText(' '), selection.getFirstPosition());
                    });
                });
            },
        });

		editor.ui.componentFactory.add('insertHashtag', locale => {
            const view = new ButtonView(locale);

			view.extendTemplate({
				attributes: {
					class: 'hashtag-btn',
				},
			});

			view.set({
				icon: `<svg fill="#000000" height="24px" width="24px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
							viewBox="0 0 490 490" xml:space="preserve" style="margin: 0; padding: 0;">
						<path d="M64.333,490h58.401l33.878-137.69h122.259L245.39,490h58.401l33.878-137.69h119.92v-48.162h-108.24l29.2-117.324h79.04
							v-48.162H390.23L424.108,0H365.31l-33.878,138.661H208.79L242.668,0h-58.415l-33.864,138.661H32.411v48.162h106.298l-28.818,117.324
							h-77.48v48.162h65.8L64.333,490z M197.11,186.824h122.642l-29.2,117.324H168.292L197.11,186.824z"/>
						</svg>`,
				label: '',
				tooltip: '해시태그 삽입',
				withText: true,
			});

            view.on('execute', () => {
                const hashtags = prompt('해시태그를 입력해주세요. (콤마(",")로 구분 "#" 제외하고 입력)\nex) 포토매그, 포토맥, 사진관');
                if (hashtags) {
					const tags = [...new Set(hashtags.split(',').map(tag => tag.trim()))];
                    editor.execute('insertHashtag', { value: tags });
                }
            });

            return view;
        });
		editor.editing.view.document.on('click', (event, data) => {
			const target = data.domTarget;

			if (target && target.classList.contains('hashtag')) {
				const text = target.innerText.replace(/^#\s*/, '');
				navigator.clipboard
					.writeText(text)
					.then(() => {
						console.log(`${text} 복사 성공`);
					})
					.catch(err => {
						console.error('복사 실패 : ', err);
					});
			}
		});

		editor.data.on('set', () => {
			const hashtags = editor.editing.view.domRoots.get('main').querySelectorAll('.hashtag');
			console.log(hashtags);
			
			hashtags.forEach(span => {
				span.addEventListener('click', () => {
					const text = span.innerText.replace(/^#\s*/, ''); 
					navigator.clipboard
						.writeText(text)
						.then(() => {
							console.log(`"${text}" 복사 성공!`);
						})
						.catch(err => {
							console.error('복사 실패:', err);
						});
				});
			});
		});

		// insertHashtag 정리
		editor.on('destroy', () => {
			if (editor.commands.get('insertHashtag')) {
				editor.commands._commands.delete('insertHashtag');
				console.log('Ckeditor5 명령 정리 성공');
			}
		});
    }
}

export default function App({ name, value, onChangeValue, disabled }) {
	const editorRef = useRef(null);
	const [editorWidth, setEditorWidth] = useState('100%');

	useEffect(() => {
		const handleResize = debounce(() => {
			const windowWidth = window.innerWidth;
			// console.log(windowWidth);

			if (windowWidth >= 1700) {
				setEditorWidth('1152px');
			} else if (windowWidth > 1500) {
				setEditorWidth('838.1px');
			} else {
				setEditorWidth('695px');
			}
		}, 100);

		// 초기 설정 및 이벤트 리스너 등록
		handleResize();
		window.addEventListener('resize', handleResize);

		// Cleanup 함수: 이벤트 리스너 해제
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const editorConfig = {
		plugins: [
			AccessibilityHelp,
			Alignment,
			Autoformat,
			AutoImage,
			Autosave,
			Base64UploadAdapter,
			BlockQuote,
			Bold,
			CloudServices,
			Code,
			CodeBlock,
			Essentials,
			FindAndReplace,
			FontBackgroundColor,
			FontColor,
			FontFamily,
			FontSize,
			FullPage,
			GeneralHtmlSupport,
			// Heading,
			Highlight,
			HorizontalLine,
			HtmlComment,
			HtmlEmbed,
			ImageBlock,
			ImageCaption,
			ImageInline,
			ImageInsert,
			ImageInsertViaUrl,
			ImageResize,
			ImageStyle,
			ImageTextAlternative,
			ImageToolbar,
			ImageUpload,
			Indent,
			IndentBlock,
			Italic,
			Link,
			LinkImage,
			List,
			ListProperties,
			Paragraph,
			SelectAll,
			// ShowBlocks,
			// SourceEditing,
			SpecialCharacters,
			SpecialCharactersArrows,
			SpecialCharactersCurrency,
			SpecialCharactersEssentials,
			SpecialCharactersLatin,
			SpecialCharactersMathematical,
			SpecialCharactersText,
			Strikethrough,
			Style,
			Table,
			TableCaption,
			TableCellProperties,
			TableColumnResize,
			TableProperties,
			TableToolbar,
			// TextPartLanguage, // 언어선택
			TextTransformation,
			// Title, // 제목
			TodoList,
			Underline,
			Undo,
			// MediaEmbed,
			FileRepository,
			DragDrop,
			VideoUpload,
			InstagramReels,
			HashtagPlugin,
		],
		toolbar: {
			items: [
				'undo',
				'redo',
				'|',
				// 'sourceEditing',
				// 'showBlocks',
				// 'findAndReplace',
				// 'textPartLanguage',
				// '|',
				// 'heading',
				// 'style',
				'videoUpload',
				'insertImage',
				'addInstagramReels',
				'insertHashtag',
				'|',
				'fontSize',
				'fontFamily',
				'fontColor',
				'fontBackgroundColor',
				'|',
				'bold',
				'italic',
				'underline',
				'strikethrough',
				'alignment',
				'specialCharacters',
				'horizontalLine',
				'|',
				// 'mediaEmbed',
				'insertTable',
				'highlight',
				'link',
				'|',
				'blockQuote',
				'codeBlock',
				'htmlEmbed',
				'|',
				'bulletedList',
				'numberedList',
				'todoList',
				'outdent',
				'indent',
			],
			shouldNotGroupWhenFull: false
		},
		fontFamily: {
			options: ['default', 'Arial', '궁서체', '바탕', '돋움'],
			supportAllValues: true
		},
		fontSize: {
			options: [10, 12, 'default', 16, 18, 20, 22],
			supportAllValues: true,
			default: '14px',
		},
		htmlSupport: {
			allow: [
				{
					name: /^.*$/,
					styles: true,
					attributes: true,
					classes: true
				},
			]
		},
		image: {
			toolbar: [
				'toggleImageCaption',
				'imageTextAlternative',
				'|',
				'imageStyle:inline',
				'imageStyle:wrapText',
				'imageStyle:breakText',
				'|',
				'resizeImage'
			]
		},
		language: 'ko',
		link: {
			addTargetToExternalLinks: true,
			defaultProtocol: 'https://',
			decorators: {
				toggleDownloadable: {
					mode: 'manual',
					label: 'Downloadable',
					attributes: {
						download: 'file'
					}
				}
			}
		},
		list: {
			properties: {
				styles: true,
				startIndex: true,
				reversed: true
			}
		},
		placeholder: '최대 2048자까지 쓸 수 있습니다.',
		style: {
			definitions: [
				{
					name: 'Article category',
					element: 'h3',
					classes: ['category']
				},
				{
					name: 'Title',
					element: 'h2',
					classes: ['document-title']
				},
				{
					name: 'Subtitle',
					element: 'h3',
					classes: ['document-subtitle']
				},
				{
					name: 'Info box',
					element: 'p',
					classes: ['info-box']
				},
				{
					name: 'Side quote',
					element: 'blockquote',
					classes: ['side-quote']
				},
				{
					name: 'Marker',
					element: 'span',
					classes: ['marker']
				},
				{
					name: 'Spoiler',
					element: 'span',
					classes: ['spoiler']
				},
				{
					name: 'Code (dark)',
					element: 'pre',
					classes: ['fancy-code', 'fancy-code-dark']
				},
				{
					name: 'Code (bright)',
					element: 'pre',
					classes: ['fancy-code', 'fancy-code-bright']
				}
			]
		},
		table: {
			contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
		},
		translations: [translations],
	};

	return (
		<EditorWrap>
			<div
				className="editor-container editor-container_classic-editor editor-container_include-style"
				ref={editorRef}
				style={{ width: editorWidth }}
			>
				<div className="editor-container__editor">
					{true && (
						<CKEditor
							editor={ClassicEditor}
							config={editorConfig}
							data={value}
							onReady={(editor) => {
								const toolbarElement = editor.ui.view.toolbar.element;

								if (disabled) {
									editor.enableReadOnlyMode('readOnly');
									toolbarElement.style.display = 'none';
								} else {
									editor.disableReadOnlyMode('readOnly');
									toolbarElement.style.display = 'flex';
								}

								editor.on('change:isReadOnly', (evt, propertyName, isReadOnly) => {
									if (isReadOnly) {
										toolbarElement.style.display = 'none';
									} else {
										toolbarElement.style.display = 'flex';
									}
								});
							}}
							onChange={(event, editor) => {
								const data = editor.getData();
								onChangeValue(data);
							}}
						/>
					)}
				</div>
			</div>
		</EditorWrap>
	);
}
