import { BarController, CategoryScale, Chart as ChartJS, Legend, LineController, LineElement, LinearScale, PointElement, Title, Tooltip } from "chart.js";
import React, { useMemo } from "react";
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import styled from "styled-components";
import { useTranslation } from "react-i18next";

ChartJS.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, BarController, Title, Tooltip, Legend);

const ChartBox = styled.div`
    width: 100%;
    height: 250px;
    // padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

function EqmtHourlyChart({ salesData, dateArray, searchData, crdInfo }) {

    const { t } = useTranslation();

    const formatData = (dataSet) => {
        let lastNonZeroIndex = -1;
        for (let i = 0; i < dataSet.length; i++) {
            if (dataSet[i] !== 0) {
                lastNonZeroIndex = i;
            }
        }
        return dataSet.map((value, index) => (index > lastNonZeroIndex ? null : value));
    };
    const formattedSalesData = salesData.map(formatData);

    const chartData = useMemo(() => {
        return {
            labels: dateArray.map(data => `${data}${t("dashboardPage.hour")}`),
            datasets: [
                {
                    type: "line",
                    label: `${t("saleHistPage.today")}`,
                    data: formattedSalesData[0],
                    fill: false,
                    borderColor: '#FF7F00',
                    backgroundColor: "#FF7F00",
                    tension: 0.1,
                    pointRadius: 5,
                    pointStyle: formattedSalesData[0].map(value => value === null ? 'line' : 'circle'),
                },
                {
                    type: "line",
                    label: `${searchData.division === "HST" ? t("saleHistPage.yesterday") : t("saleHistPage.lastWeek")}`,
                    data: salesData[1],
                    fill: false,
                    borderColor: '#006DB2',
                    backgroundColor: "#006DB2",
                    tension: 0.1,
                    pointRadius: 5,
                    pointStyle: salesData[1].map(value => value === null ? 'line' : 'circle'),
                }
            ],
        };
    }, [formattedSalesData, dateArray, t, salesData, searchData]);

    const options = {
        responsive: true,
        maintainAspectRatio: false, //부모의 크기에 맞춤
        scales: {
            x: {
                ticks: {
                    font: {
                        size: 10
                    },
                    minRotation: 90,
                    padding: 5,
                }
            },
        },
        plugins: {
            legend: {
                display: true, // 범례
                position: 'bottom',
                labels: {
                    font: {
                        size: 11,

                    },
                    boxWidth: 3,
                    boxHeight: 3
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        label += `${crdInfo && crdInfo.natnCd === "KR" ? "₩" : "¥"} ${context.raw.toLocaleString()}`;
                        return label;
                    }
                }
            }
        },
    };

    return (
        <ChartBox>
            <Line options={options} data={chartData} />
        </ChartBox>
    )
}

export default EqmtHourlyChart;
